import React, { Component } from 'react';
import Login from './components/Login';
import ControlPage from './components/ControlPage';

import 'bootstrap/dist/css/bootstrap.css';
import 'popper.js/dist/popper.js';
import 'jquery/dist/jquery.js';

  
class AdminPage extends Component {
	constructor() {
    super();
    this.state = {
      loggedUser: false,
      moduleControls: {
      	bookingsAdminPage: false,
      	messages: false,
      	priceTable: false,
      	roomOptions: false,
      },
      messages: null,
      userData: null

    }
    this.handleLogin = this.handleLogin.bind(this);
    this.downloadDataFromDB = this.downloadDataFromDB.bind(this);
    this.setMessageAsRead = this.setMessageAsRead.bind(this);
    this.downloadMessagesFromDB = this.downloadMessagesFromDB.bind(this);
    this.handleModuleMenuSelect = this.handleModuleMenuSelect.bind(this);
  }


	handleModuleMenuSelect(event){
    const value = event.target.name;
		let moduleToShow = {[value]: true};
		this.setState({moduleControls: moduleToShow});
		}

	setMessageAsRead(event) {
	  const value = event.target.id;
	  //console.log("Event.target.id: " + event.target.id);
	  const data = {...this.state.loggedUser, messageId: value};
	  //console.log(data);
	  event.preventDefault();
	  fetch('https://164.132.113.224/api/smr', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => console.log(res))
  }

  handleLogin(event) {
	  const data = {login: document.getElementById('login').value, password: document.getElementById('password').value}
	  console.log(data);
	  //event.preventDefault();
	  fetch('https://164.132.113.224/api/login', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => res.json())      
    .then((json) => {
    	if (json.passwordMatch===true){
        this.setState({ loggedUser: {name: json.name, uid: json.uid} })
    	}
    })
  }
  downloadDataFromDB(object) {
    const data = object
    fetch('https://164.132.113.224/api/dd', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => res.json())      
    .then((json) => {
    	console.log(json);
      this.setState({ userData: json })
    })
  }

  downloadMessagesFromDB() {
    const data = this.state.loggedUser;
    fetch('https://164.132.113.224/api/dm', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => res.json())      
    .then((json) => {
    	console.log(json);
      this.setState({ messages: json.messages })
    })
  }


render(){
	return (
		<div>
	{!this.state.loggedUser && 
		<Login handleLogin={this.handleLogin}/>}
	{this.state.loggedUser && <ControlPage
		downloadDataFromDB={this.downloadDataFromDB} 
		downloadMessagesFromDB={this.downloadMessagesFromDB}
		handleModuleMenuSelect={this.handleModuleMenuSelect} 
		setMessageAsRead={this.setMessageAsRead}
		loggedUser={this.state.loggedUser}
	 	moduleControls={this.state.moduleControls} 
	 	messages={this.state.messages}
	 	userData={this.state.userData} />}
	</div>)
}
 	
}

export default AdminPage;
