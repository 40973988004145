import React, { Component } from 'react';
import ContactForm from './ContactForm';
import ContactInfo from './ContactInfo';
import BookingForm from './../booking_page/BookingForm';
import ContactFormConfirmation from './ContactFormConfirmation';
import 'bootstrap/dist/css/bootstrap.css';


class ContactPage extends Component {
	constructor () {
		super();
		this.state = {
			contactView: true,
			contactFormConfirmView: false,
			contactMessageId: null,
			contactMessageTopic: '2',
			}
		this.setContactViewToMain = this.setContactViewToMain.bind(this);
		this.setContactViewToContactFormConfirm = this.setContactViewToContactFormConfirm.bind(this);
		this.handleMessageId = this.handleMessageId.bind(this);
	}

	setContactViewToMain() {
  	this.setState({
  		contactView: true,
  		contactFormConfirmView: false
  	});
  }

	setContactViewToContactFormConfirm() {
  	this.setState({
  		contactView: false,
  		contactFormConfirmView: true
  	})
  }

  handleMessageId(value) {
  	this.setState({
  		contactMessageId: value
  	});
  }


	render() {
		return (
			<div>
				{this.state.contactView &&
				<div className="row">
					<div className="col-sm-6 col-12">
						{this.state.contactMessageTopic === '2' && <ContactForm {...this.props} 
						setContactViewToContactFormConfirm={this.setContactViewToContactFormConfirm} 
						handleMessageId={this.handleMessageId} />}
						{this.state.contactMessageTopic === '1' && <BookingForm {...this.props} />}
					</div>
					<div className="col-sm-6 col-12 order-first">
						 <ContactInfo />
						
					</div>
				</div>
			}
				{this.state.contactFormConfirmView &&
				<div>
					<ContactFormConfirmation setContactViewToMain={this.setContactViewToMain} contactMessageId={this.state.contactMessageId}/>
				</div>}

			</div>
		)
	}
}

export default ContactPage;