import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NavigationCalendar from './NavigationCalendar';
import WeekHeaders from './WeekHeaders';
import MonthCalendar from './MonthCalendar';
import './calendar.css';

class Calendar extends Component {

	render() {
		const bookingTypeHeaders = {
			confirmed: this.props.headers.confirmed,
			unconfirmed: this.props.headers.unconfirmed,			
			canceled: this.props.headers.canceled
		}
		return (
			<div 
			className="card" style={{position: 'sticky', top: 70}}>
				<div className="card-header">
					<NavigationCalendar 
					calendarSkipTo={this.props.calendarSkipTo} 
					currentMonth={this.props.currentCalendar.currentMonth} 
					currentYear={this.props.currentCalendar.currentYear}  
					monthHeaders={this.props.headers.monthHeaders} 
					nextHeader={this.props.headers.nextHeader}
					previousHeader={this.props.headers.previousHeader}
					selectableYears={this.props.currentCalendar.selectableYears}/>
				</div>
				<div 
				className="card-body">
					<table 
					className="table table-sm table-bordered table-responsive-xs" 
					id="calendar">
						<WeekHeaders weekDayHeaders={this.props.headers.weekDayHeaders}/>
						<MonthCalendar 
							addBookingToUnconfirmedCollection={this.props.addBookingToUnconfirmedCollection}
							currentCalendar={this.props.currentCalendar}
							bookingTypeColors={this.props.bookingTypeColors}
							bookingTypeHeaders={bookingTypeHeaders}
							changeShowBookingDetailsValue={this.props.changeShowBookingDetailsValue}
							confirmedHeaders={this.props.headers.confirmed}
							createBookingList={this.props.createBookingList}
							canceledHeaders={this.props.headers.canceled}
							newBooking={this.props.newBooking}
							roomId={this.props.roomId}
							returnSelectedDay={this.props.returnSelectedDay}
							unconfirmedHeaders={this.props.headers.unconfirmed}
						/>
					</table>
				</div>
			</div>
		);
	}
}

Calendar.propTypes ={
	addBookingToUnconfirmedCollection: PropTypes.func.isRequired,
  bookingTypeColors:PropTypes.objectOf(PropTypes.string).isRequired,
	calendarSkipTo: PropTypes.func.isRequired,
  changeShowBookingDetailsValue: PropTypes.func.isRequired,
  currentCalendar: PropTypes.object.isRequired,
  createBookingList: PropTypes.func.isRequired,
  headers: PropTypes.object.isRequired,
  newBookingMode: PropTypes.bool.isRequired,
  newBooking: PropTypes.object.isRequired,
  roomId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool ]),
  "returnSelectedDay": PropTypes.func.isRequired,
}

export default Calendar;




