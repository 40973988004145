import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import BookingsListElement from './BookingsListElement';
import BookingSearchBox from './BookingSearchBox';



class BookingsList extends Component {
	constructor(props) {
    super(props);
  };

	render() {
		return (
			<div>
			{this.props.bookings &&						
			<div className="card" style={{marginTop: "25px"}}>
	    	<a data-toggle="collapse" href={"#"+this.props.bookingListType} role="button" aria-expanded="false" aria-controls={this.props.bookingListType}>
		  	<div className="card-header" id={this.props.bookingListType + "Bookings"}>
		  	<section className="wrappingContainer">
			  		{this.props.bookingListType === "unconfirmed" && <h4>Niepotwierdzone rezerwacje</h4>}
			  		{this.props.bookingListType === "confirmed" && <h4>Potwierdzone rezerwacje</h4>}
			  		{this.props.bookingListType === "canceled" && <h4>Anulowane rezerwacje</h4>}
			  		{this.props.bookingListType === "archived" && <h4>Archiwalne rezerwacje</h4>}
			  		{this.props.bookingListType === "deleted" && <h4>Usunięte rezerwacje</h4>}
			 	</section>
		  	</div>
	  	</a>
	    <div className="collapse" id={this.props.bookingListType}>
		    <div className="card-body">
		    	<div className="card-title">
		    		{(this.props.bookingListType === "confirmed" || this.props.bookingListType === "archived") && <BookingSearchBox bookingListType={this.props.bookingListType} setSearchFilter={this.props.setSearchFilter}/>}
		    	</div>
		    	<div className="card-text">
		    		{this.props.bookings && this.props.bookings.map((booking, index)=>(
					    <BookingsListElement key={index} setRestoreBooking={this.props.setRestoreBooking} setBookingArchived={this.props.setBookingArchived} setBookingDeleted={this.props.setBookingDeleted} setBookingCanceled={this.props.setBookingCanceled} setBookingConfirmed={this.props.setBookingConfirmed} bookingListType={this.props.bookingListType} index={index} booking={booking}/>
				    ))}	
		    	</div>
		    </div>
	    </div>
	  </div>}
	</div>
		)
	}
}

export default BookingsList;



