var createArrayWithOcupatedDays = (bookingsArray, month) => {
  	let days = [];
  	for (let i=0; i<bookingsArray.length; i++)
  	{ 
  		let checkInDate = new Date (bookingsArray[i].startYear, bookingsArray[i].startMonth-1, bookingsArray[i].startDay);
  		let checkOutDate = new Date (bookingsArray[i].endYear, bookingsArray[i].endMonth-1, bookingsArray[i].endDay);
  		let stayLength = (checkOutDate - checkInDate) / (1000*60*60*24);
	  		//console.log("CheckIn : " + checkInDate);
	  		//console.log("CheckOut : " + checkOutDate);

  		for (let j=0; j<=stayLength; j++)
  		{
  			let stayDay = new Date(checkInDate);
  			stayDay.setDate(checkInDate.getDate() + j);
  			let day;
  			//console.log("stayDay " + stayDay.getMonth() + ", month: " + month);
				if (stayDay.getMonth() === month)
				{
					 day = stayDay.getDate();
					 //console.log("wewn: " + day);
					 days = days.concat(day);
				}					
				//console.log("zewn: " + day)
  			
  		}
	  	//console.log("booked for this month: " + days);
	  }
	  return days;
	}

var generateCalendar = (month, year, bookings, newBookingDaysArray, newBookingMonthsArray) =>
{
	let firstDay = (new Date(year, month)).getDay();
	let bookedArray;
	if (bookings && typeof bookings === 'object')
	{
		//console.log("bookings: " + bookings);		
		bookedArray = createArrayWithOcupatedDays(bookings, month);
		//console.log("bookedArray: " + bookedArray);
	}

	firstDay = (firstDay===0)? 7 : firstDay;
	let daysInMonth = 32 - new Date(year, month, 32).getDate();
	let newCalendar = [];
	let date = 1;
	for (let i = 0; i < 6; i++) 
	{
    //create array with week of Month
    let week = [];

    //fill week with days
    for (let j = 1; j <= 7; j++) 
    {
    	//fill first week with 0 until meet firstDay of a Month
      if (i === 0 && j < firstDay) 
      {
        let day = 0;
        week.push(day);
      }
      else if (date > daysInMonth) 
      {
      	//if no more days in Month break
        break;
      }
      else 
      {
        let day;
        //console.log(date);  
	      if (bookedArray && bookedArray.includes(date) && ((newBookingDaysArray && !newBookingDaysArray.includes(date) || !newBookingDaysArray)))
    		{

    			//if this day is booked
    			day = "X";
    			week.push(day);
    		}
    		else if (newBookingDaysArray && newBookingDaysArray.includes(date))
    		{
    			if (newBookingDaysArray.includes(date) && !bookedArray.includes(date))
    			{
    			//new booking mark
    			day = "O";
    			week.push(day);	
    			}
    			else
    			{
    				return generateCalendar(month, year, bookings);
    			}
    		}
    		else
    		{
    			day = date;
    			week.push(day);
        }
	      date++;
      }
    }
    newCalendar.push(week);
  }
  return newCalendar;
}

export {generateCalendar, createArrayWithOcupatedDays};