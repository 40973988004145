import React, { Component } from 'react';
import Menu from './Menu';
import BookingForm from './BookingForm';
import BookingsAdminPage from './BookingsAdminPage';
import Messages from './Messages';
import PriceTable from './PriceTable';
import RoomOptions from './RoomOptions';


class ContolPage extends Component {

	componentDidMount(){
    this.props.downloadDataFromDB(this.props.loggedUser);
  }

render (){
		return (
			<div id="container" className="container-fluid" >
					<div className="row justify-content-center" style={{marginBottom: "50px"}}>
					<div className="col-9">
				 		<img 
				 		src={'logo.png'} 
				 		height="45px" 
				 		width="45px"
				 		alt="Logo"/>				 		
				 			<h2 style={{fontFamily: "'Amatic SC', cursive", display: "inline"}}>Kwatery-Laskowscy.pl - Strona Administracyjna</h2>
				 	</div>
						<div className="col-3" align='center'>
								<h2 style={{fontFamily: "'Amatic SC', cursive", display: "inline"}}>Witaj {this.props.loggedUser.name}!</h2>
						</div>
					</div>
					<div className="row">
						<div className="col-2">
							<Menu handleModuleMenuSelect={this.props.handleModuleMenuSelect} />
						</div>
						<div className="col-8">
							{this.props.moduleControls.priceTable && <PriceTable userData={this.props.userData}/>}
							{this.props.moduleControls.messages && <Messages downloadMessagesFromDB={this.props.downloadMessagesFromDB} messages={this.props.messages} setMessageAsRead={this.props.setMessageAsRead}/>}
							{this.props.moduleControls.bookingsAdminPage && <BookingsAdminPage loggedUser={this.props.loggedUser} userData={this.props.userData}/>}
							{this.props.moduleControls.roomOptions && <RoomOptions userData={this.props.userData}/>}
						</div>


					</div>					
			</div>
		);
	}
}

export default ContolPage;