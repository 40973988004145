import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BookingFormMessagesList from './BookingFormMessagesList';
import BookingMessageNavigation from './../navigation/BookingMessagesNavigation';


class BookingFormMessagesListsWrapper extends Component {
	constructor(){
		super();
		this.state = {
			selected: 1,
			navigateDirection: 'inbox',

		}
		this.selectRoom = this.selectRoom.bind(this);
		this.filterOnlySelectedBookings = this.filterOnlySelectedBookings.bind(this);
		this.filterOnlySelectedMessages = this.filterOnlySelectedMessages.bind(this);
		this.navigationService = this.navigationService.bind(this);
	};

	selectRoom(roomId){
		this.setState({
			selected: roomId,
			navigateDirection: 'inbox',
		})
	}	
	filterOnlySelectedBookings(booking)
	{
		return booking.roomId === this.state.selected;
	}
	filterOnlySelectedMessages(message)
	{		
		if (this.state.navigateDirection === 'inbox')
			return message.contactBooking.roomId === this.state.selected && message.accepted[0] === null;
		else if (this.state.navigateDirection === 'accepted')
			return message.contactBooking.roomId === this.state.selected && message.accepted[0];
		else if (this.state.navigateDirection === 'rejected')
			return message.contactBooking.roomId === this.state.selected && message.accepted[0] === false;
	}
	navigationService(navigateDirection){
		this.setState({navigateDirection});
	}
	
	render() {
		let Rooms = new Array;
		if (this.props.rooms)
		{
			Rooms = Rooms.concat(...this.props.rooms);
		}
		let bookings = this.props.bookings ? this.props.bookings.filter(this.filterOnlySelectedBookings) : [];
		let messages = this.props.messages ? this.props.messages.filter(this.filterOnlySelectedMessages) : [];
		return (	
			<div className="col-12">
				<div className="row">
					<BookingMessageNavigation counters={this.props.counters} navigationService={this.navigationService} navigateDirection={this.state.navigateDirection} rooms={this.props.rooms} selectRoom={this.selectRoom} selected={this.state.selected}/>
					{Rooms[0] && bookings[0]
						? 
							<BookingFormMessagesList
								bookings={bookings}
								messages={messages}
								room={Rooms[this.state.selected-1]}
								setMessageAsRead={this.props.setMessageAsRead}
								user={this.props.user}
							/>
						:	null
					}
				</div>
			</div>
		)
	}
}

export default BookingFormMessagesListsWrapper;