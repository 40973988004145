import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RoomEquipment from './RoomEquipment';
import RoomImages from './RoomImages';
import RoomCalendar from './RoomCalendar';
import BookingPage from './../../booking_page/BookingPage';
import RulesDescription from './../../customer_relation_page/RulesDescription';
import Calendar from './../../../universal/calendar/Calendar';

class RoomDetails extends Component {
	constructor () {
		super();
		this.state = {
			newBookingMode: false,
			newBooking: {
				acceptBookingRules: false,
				checkin: {
					day: false,
					month: false,
					year: false,
				},
				checkout: {
					day: false,
					month: false,
					year: false,					
				}
			},
		};
		this.newBookingModeSwitch = this.newBookingModeSwitch.bind(this);
		this.acceptBookingRulesCheckboxChecked = this.acceptBookingRulesCheckboxChecked.bind(this);
	}
	acceptBookingRulesCheckboxChecked(event) {
		const acceptBookingRules = event.target.checked;
		this.setState({
	    newBooking: {
	    	...this.state.newBooking,
	    	acceptBookingRules,
	    }
	  });   
	}
	newBookingModeSwitch (object){
    this.setState({
			newBookingMode: !this.state.newBookingMode,
			newBooking: {
				...object,
				roomId: this.props.room.roomId
			}
    });
  };


	render() {
		const acceptBookingRulesGroup = 	<div
		className="form-group row" 
		data-toggle='tooltip'
		title='Przeczytaj i zaakceptuj aby przejść dalej.' >
	    <label className="col-6 form-label" htmlFor="acceptBookingRulesCheckbox">Akceptuję</label>
				<input type="checkbox"
					name='acceptBookingRulesCheckbox' 
					className="form-control col-6" 
					id='acceptBookingRulesCheckbox' 
					checked={this.state.newBooking.acceptBookingRules}
					onChange={this.acceptBookingRulesCheckboxChecked}/>

		</div>;
		const caption = this.props.room.title.toUpperCase();
		const roomId = "room"+this.props.room.roomId;
	  const mainBackgroundSettings = roomId+"-main-bg";
	  const contentBackgroundSettings = roomId+"-content-bg";
		const capacity = this.props.room.capacity;
		const capacityElement = generateCapacityRepresentation(capacity);
		function generateCapacityRepresentation(capacity) {
			var capacityRepresentation = [];
			for (let i=capacity; i>0; i--) {
		    capacityRepresentation.push(<img key={i} src="./ico/person.svg" alt="gość" height="40px"/>)
		  }
		  return capacityRepresentation;
		}
		
		return (
				<div id={"room"+roomId}>
					<div className={"row "+mainBackgroundSettings} id={roomId}>
						<div className="col-12" align="center">			
							<h2 style={{fontFamily: "'Amatic SC', cursive", fontWeight: "bold", display: "inline"}}>{caption}{" "}{capacityElement}</h2>
						</div>
					</div>

					<div className={"row "+this.props.room.cssColor} id={"content_"+roomId} >

						<div className={"col-12 col-lg "+contentBackgroundSettings} id={"gallery_"+roomId}>
							<RoomImages 
							photos={this.props.room.photos} 
							title={caption} 
							roomId={this.props.room.roomId}/>
						</div>
						<div className={"col-12 col-lg "+contentBackgroundSettings} id={"equipment_"+roomId}>
							<RoomEquipment equipment={this.props.room.equipment} />
						</div>
						{!this.state.newBookingMode && <div className={"col-12 col-lg "+mainBackgroundSettings} id={"calendar_"+roomId}>
							<RoomCalendar 
							acceptBookingRules = {this.state.acceptBookingRules}
							roomId={this.props.room.roomId}
							newBookingModeSwitch={this.newBookingModeSwitch} 
							roomCalendar color={this.props.room.cssColor} 
							pricesTable={this.props.room.pricesTable} 
							pricesPer={this.props.room.pricesPer} 
							bookings={this.props.bookings}/>
						</div>}
						 {(this.state.newBookingMode && !this.state.newBooking.acceptBookingRules) && <div className={"col-12 col-lg "+mainBackgroundSettings} id={"booking_"+roomId}>
							<RulesDescription
							acceptBookingRulesGroup={acceptBookingRulesGroup}							
							newBookingModeSwitch={this.newBookingModeSwitch}  />
						</div>}
						 {(this.state.newBookingMode && this.state.newBooking.acceptBookingRules) && <div className={"col-12 col-lg "+mainBackgroundSettings} id={"booking_"+roomId}>
							<BookingPage
							bookings={this.props.bookings}
							newBooking={this.state.newBooking}
							newBookingModeSwitch={this.newBookingModeSwitch}
							part 
							roomId={this.props.room.roomId} 
							rooms={new Array(this.props.room)} />
						</div>}
					</div>
				</div>
			);
	}
}

RoomDetails.propTypes ={
	bookings: PropTypes.array,
  room: PropTypes.object
}

export default RoomDetails;