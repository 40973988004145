import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../../../stylesheets/style.css';
import './../../../stylesheets/equipment.css';

class EquipmentCategory extends Component {
	render() {
		const icoFormat = '.svg',
			icoFolder = './ico/',
			equipment = this.props.category.map((equipmentElement, index)=>(
			<div style={{display: 'inline'}}>	
				<div className='EquipmentElement' key={index}>
					<img src={icoFolder+equipmentElement.position+icoFormat} alt={equipmentElement.position} width='70px' height='70px'/>
				</div>
				{equipmentElement.amount > 1 &&
				<div className='EquipmentElement' key={index}>
					<img src={icoFolder+equipmentElement.position+icoFormat} alt={equipmentElement.position} width='70px' height='70px'/>
				</div>}
			</div>
			))
		return (
			<div>
				{equipment}
			</div>
		);
	}
}

EquipmentCategory.propTypes ={
	  category: PropTypes.arrayOf(PropTypes.object)
}

export default EquipmentCategory;