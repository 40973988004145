import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class RoomPreview extends Component {
    render() {
        const gallery = this.props.gallery ? this.props.gallery : [];
        const folderName = this.props.folderName ? this.props.folderName : '';
		return (
			<div style={{position: 'relative'}}>
                {gallery && gallery.map((miniature, imageIndex)=>(
                    <div
                        className="img-thumbnail preview-miniature"
                        id={imageIndex}
                        style={{
                            position: 'absolute',
                            top: imageIndex%2 === 0 ? 30+imageIndex*20+'px' : 30-imageIndex*20+'px',
                            left: imageIndex%2 === 0 ? 80+imageIndex*20+'px' : 80-imageIndex*20+'px',
                            transform: 'scale(0.6)',
                            backgroundImage: 'url(./'+folderName+'/'+miniature.file+')',
                            backgroundSize: miniature.fill +'%',
                            backgroundPositionX: miniature.x +'%',
                            backgroundPositionY: miniature.y +'%',
                            display: imageIndex < 4 ? 'initial' : 'none',
                            zIndex: imageIndex    
                        }}
                        key={imageIndex}
                        alt={this.props.title}/>
                ))}
            </div>
		)
	}
}

export default RoomPreview;