import React, { Component } from 'react';
//import MenuItem from './MenuItem';
import 'bootstrap/dist/css/bootstrap.css';
import './../../../stylesheets/header.css';
class Header extends Component {
	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#FFF", width: "100%", height: "auto", margin: "0", padding: "0", position: "absolute", left: "0"}}>
			 	<div className='phonenumber'>
				 	<a className="navbar-brand" href="/"><img 
			 		src="logo.png" 
			 		height="45px" 
			 		width="45px"
			 		alt="Logo Kwatery-Laskowscy"/></a>
		 			<h2 className='pageurl'>Kwatery-Laskowscy.pl</h2>
					<a className='phonenumber' href='tel:+48913281618'>+48 91 328 16 18</a>
					<a className='phonenumber' href='tel:+48695335936'>+48 695 335 936</a>
		 		</div>
			  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			    <span className="navbar-toggler-icon"></span>
			  </button>

			  <div className="collapse navbar-collapse" id="navbarSupportedContent">
			    <ul className="navbar-nav mr-auto">
			      <li className='nav-item'>
			        <a href='/lokalizacja' className={`nav-link ${this.props.activeView.LocalizationPage ? "active" : ""}`} style={{cursor: 'pointer'}}  name="LocalizationPage"  onClick={this.props.handleViewChange}>Lokalizacja</a>
			      </li>
			      <li className="nav-item">
			        <a href='/kontakt' className={`nav-link ${this.props.activeView.ContactPage ? "active" : ""}`} style={{cursor: 'pointer'}}  name="ContactPage"  onClick={this.props.handleViewChange}>Kontakt</a>
			      </li>
				    	<li className="nav-item">
				        <a href='/oferta' className={`nav-link ${this.props.activeView.OfferPage ? "active" : ""}`}  style={{cursor: 'pointer'}} name="OfferPage" onClick={this.props.handleViewChange}>Oferta</a>
				      </li>
			      {(this.props.activeView.OfferPage || this.props.activeView.room) &&
			      <>
				      <li className="nav-item">
				        <a href='/zielony' className={`nav-link ${this.props.activeView.room === '1' ? "active" : ""}`} style={{cursor: 'pointer'}} id='1' name="room" onClick={this.props.handleViewChange}>Pokój zielony</a>
				      </li>
				      <li className="nav-item">
				        <a href='/niebieski' className={`nav-link ${this.props.activeView.room === '2' ? "active" : ""}`} style={{cursor: 'pointer'}} id='2' name="room" onClick={this.props.handleViewChange}>Pokój niebieski</a>
				      </li>
				      <li className="nav-item">
				        <a href='/zolty' className={`nav-link ${this.props.activeView.room === '3' ? "active" : ""}`} style={{cursor: 'pointer'}} id='3' name="room" onClick={this.props.handleViewChange}>Apartament żółty</a>
				      </li>
				    </>
			    	}
				  </ul>

			  </div>
			</nav>
		) 
/*
		this.props.rooms.map(room=>(
			<MenuItem 
				key={room.id} 
				room={room} 
				selected={this.props.selected}  
				menuHover= {this.props.menuHover} 
				menuHoverOff = {this.props.menuHoverOff} 
				menuHoverOn = {this.props.menuHoverOn}
				promoHover= {this.props.promoHover} 
				promoHoverOff = {this.props.promoHoverOff} 
				promoHoverOn = {this.props.promoHoverOn}  
				showRoomDetails = {this.props.showRoomDetails} />
		));*/
	}
}

export default Header;