//podstawowe opcje pokoju nazwa, pojemność

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class RoomBasicSettings extends Component {
	render() {
		return (
			<div>
                <p>Nazwa pokoju: <span>{this.props.caption}</span></p>
                <p>Max pojemność pokoju: <span>{this.props.capacity + ' .os'}</span></p>
            </div>
		)
	}
}

export default RoomBasicSettings;