import React, { Component } from 'react';
import BookingsCalendar from './BookingsCalendar';


class DemoBookingsCalendarContainer extends Component {
	constructor () {
		super();
		this.state = {
		}
		this.addToUnconfirmedCollection = this.addToUnconfirmedCollection.bind(this);
		this.changeBookingCollection = this.changeBookingCollection.bind(this);
		this.createId = this.createId.bind(this);
		this.generateDemoBookings = this.generateDemoBookings.bind(this);
	}
	addToUnconfirmedCollection(bookingObject, callback){
		let checkin = new Date(bookingObject.checkin.year, bookingObject.checkin.month, bookingObject.checkin.day);
		let checkout = new Date(bookingObject.checkout.year, bookingObject.checkout.month, bookingObject.checkout.day);		
		let bookingId = this.createId(checkin, bookingObject.roomId);

		let booking = {
			bookingId,
			bookingType: 'unconfirmed',
			checkin,
			checkout,
			roomId: bookingObject.roomId,
		}
		fetch('https://laskowskicreative.no/api/newbooking', {
	      method: 'POST',
	      headers: {
	        'Accept': 'application/json',
	        'Content-Type': 'application/json'
	      },
	      body: JSON.stringify(booking)
	    }).then((res) => res.json())     
	    .then((json) => {
	    	booking = {...booking, bookingId: json.bookingId};
	    		let unconfirmed = this.state.bookingCollections.unconfirmed;
	    		unconfirmed = unconfirmed.concat(booking);
	    		this.setState({
	    			bookingCollections: {
	    				...this.state.bookingCollections,
	    				unconfirmed
	    			}
	    		});
	    		callback(booking.bookingId);
	    		//console.log(bookingId);
	    		return bookingId;
				})
	}
	changeBookingCollection(bookingId, moveFromCollection, destinyCollection, callback)
	{//code responsible for move booking between collections
		const data = {...this.props.user, bookingId: bookingId};
		let url = '';
		if (destinyCollection === 'canceled')
		{
			url = 'https://laskowskicreative.no/api/sbcancel';
		}
		else if (destinyCollection === 'confirmed')
		{
			url = 'https://laskowskicreative.no/api/sbc'
		}
		fetch(url, {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => res.json())      
    .then((json) =>  {
    	if (json[destinyCollection]) {
				let oldCollection = this.state.bookingCollections[moveFromCollection];
				let newCollection = this.state.bookingCollections[destinyCollection];
				let booking = oldCollection.filter((booking) => {return booking.bookingId === bookingId})
				booking[0].bookingType = destinyCollection;
				newCollection = newCollection.concat(booking);
				oldCollection = oldCollection.filter((booking)=>{return booking.bookingId !== bookingId})
				this.setState({
					bookingCollections: {
						...this.state.bookingCollections,
						[moveFromCollection] : oldCollection,
						[destinyCollection] : newCollection
					}
				});
				callback();
			}
		})
	}
	createId(date, roomId){
		const dateObject = {
			day: date.getDate(),
			month: date.getMonth(),
			year: date.getFullYear()
		};
		const rand = String(this.randomizeNumber(5,3));
		const outputId = String(roomId) + dateObject.year+dateObject.month+dateObject.day +rand; 
		return outputId;
	}
	randomizeNumber(maxRandomValue, minRandomValue){
			let randomNumberValue = 0;
			let minimum = minRandomValue ? minRandomValue : 0; 
			while (randomNumberValue <= minimum)
			{
				randomNumberValue = Math.floor(Math.random()*(maxRandomValue+1))
			}
			return randomNumberValue 
		};
	generateDemoBookings(){
		/* create some Demo Rooms, 
		unconfirmed: [], confirmed: [] and canceled: [] bookings */
		var setSpecificCalendar = {};
		if (this.props.showBookingDetails)
		{
			let selectedBooking = this.props.bookings ? this.props.bookings.filter((booking)=>{return booking.bookingId === this.props.showBookingDetails ? booking : ''}) : [];
			if (selectedBooking[0])
			{
				const currentCalendar = {
					currentMonth: new Date(selectedBooking[0].checkin).getMonth(),
					currentYear: new Date(selectedBooking[0].checkin).getFullYear()
				};
				const roomId = selectedBooking[0].roomId;
				const bookingType = selectedBooking[0].bookingType;
				//selectedBooking[0].bookingType + ' - ');
				setSpecificCalendar = {currentCalendar, roomId, bookingType};
				console.log(setSpecificCalendar);
				
			}
		}
			
		const bookings = this.props.bookings;
		let bookingCollections = {
			confirmed: [],
			unconfirmed: [],
			canceled: []
		}
		for(let i = bookings.length-1; i >= 0; i--)
		{
			bookingCollections[bookings[i].bookingType][bookingCollections[bookings[i].bookingType].length] = bookings[i];
		}
		for (let [key, value] of Object.entries(bookingCollections)) {
			bookingCollections[key] = value.reverse()
		}
		this.setState(
		{	
			...this.props,
			setSpecificCalendar: setSpecificCalendar.roomId ? setSpecificCalendar : false,
			bookingCollections
		})
	}
	componentWillMount(){
		this.generateDemoBookings();
	}		
	render() {
		return (
			<BookingsCalendar 
			addToUnconfirmedCollection={this.addToUnconfirmedCollection} 	
			setSpecificCalendar={this.state.setSpecificCalendar ? this.state.setSpecificCalendar : false} 
			showBookingDetails={this.props.showBookingDetails} 
			changeBookingCollection={this.changeBookingCollection} 
			bookingCollections={this.state.bookingCollections} 
			rooms={this.props.rooms} 
			/>
		);
	}
}

export default DemoBookingsCalendarContainer;