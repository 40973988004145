import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RoomPriceTag from './RoomPriceTag';
import Calendar from './../../../universal/booking_calendar/DemoBookingsCalendarContainer';
import BookingContainer from './../../booking_page/BookingContainer';

class RoomCalendar extends Component {
	constructor (props) {
		super(props);
		let today = new Date ();
		this.state = {
			currentMonth: today.getMonth(),
			contactBooking: {
				acceptBookingRules: false,
				checkin: {
					day: false,
					month: false,
					year: false,
				},
				checkout: {
					day: false,
					month: false,
					year: false,					
				}
			}
		}		
		this.getCurrentMonth = this.getCurrentMonth.bind(this);
		this.switchToNewBookingMode = this.switchToNewBookingMode.bind(this);
		this.getCalendarSelectedValues = this.getCalendarSelectedValues.bind(this);
	}

	getCalendarSelectedValues(object)	{
		//console.log(object);
		this.setState({
			contactBooking: {
				...object
			}
		})
	}
	switchToNewBookingMode() {
		this.props.newBookingModeSwitch(this.state.contactBooking)  
	}

	getCurrentMonth(month) {
		this.setState({
	    currentMonth : month
	  });   
	}


	render() {
		const bookingButtonClass = "btn btn-outline-" + this.props.color + " btn-lg btn-block";
		const bookButton = 	<div>
						<button type="button" 
						className={bookingButtonClass} 
						disabled={!this.state.contactBooking.checkin.day || !this.state.contactBooking.checkout.day}
						style={{fontSize: "26px"}}
						onClick={this.switchToNewBookingMode}>
							Rezerwuj
						</button>
					</div>

		return(
			//{this.generateCalendar(this.state.currentMonth, this.state.currentYear)}
			<div className="row">
				<div className="col-12">
 					{this.props.bookings && <Calendar acceptBookingRules={this.props.acceptBookingRules} getCurrentMonth={this.getCurrentMonth} getCalendarSelectedValues={this.getCalendarSelectedValues} bookButton={bookButton} bookings={this.props.bookings} rooms={[this.props.roomId]}/>}
 				</div>
				<div className="col-12">
					<RoomPriceTag color={this.props.color} pricesTable={this.props.pricesTable}  pricesPer={this.props.pricesPer}  currentMonth={this.state.currentMonth} />
				</div>
			</div>
		)
	}
}

export default RoomCalendar;