const getClientData = function (type) {
    const clientData = {
        clientName: "Beata",
        clientKey: "",
        uid: "MOM01",
        clientCompanyTaxNumber: "---",
        clientCompanyName: "Kwatery u Beaty i Darka",
        clientAddress: "Gryfa Pomorskiego 14/2, 72-500 Międzyzdroje",
        clientDomain: "kwatery-laskowscy.pl",
        clientEmail: "kwatery-laskowscy@gmail.com",
        clientPhone: "+48695335936",
        clientFancyPhone: '695 33 59 36',
        instagram: "",
        facebook: "",
    }
    return clientData[type];
}

export {getClientData};
