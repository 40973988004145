import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import	Datepicker from './Datepicker';
import	Calendar from './../../components/universal/calendar/Calendar';


class BookingSearchBox extends Component {
	constructor() {
    super();
    this.state = {
      searchBox: {
      	startMonth: null,
				startDay: null,
				startYear: null,
				endMonth: null, 
				endDay: null, 
				endYear: null,
				roomId: null,
			},
			datePickerFor: {
  			start: false,
  			end: false,
  		},
      roomButtonStyle: {
      	select: "btn btn-",
      	unselect: "btn btn-outline-",
      }     
    }
    this.selectRoom = this.selectRoom.bind(this);
    this.openDatepicker = this.openDatepicker.bind(this);
    this.closeDatepicker = this.closeDatepicker.bind(this);
    this.returnValueFromDatepicker = this.returnValueFromDatepicker.bind(this);
  }

  selectRoom (event){
    const value = event.target.id;
		this.setState({
			searchBox: {
				...this.state.searchBox,
				roomId: value}},
				() => {this.props.setSearchFilter(this.state.searchBox, this.props.bookingListType)});
  }

  openDatepicker (event){
  	const value = event.target.id;
  	this.setState({
		  searchBox: {
				...this.state.searchBox,
			},
			datePickerFor: {
				...this.state.datePickerFor,
				[value]: true,
			}
  	});
  }

  closeDatepicker (event){
  	const value = event.target.id;
  	const object = {...this.state.datePickerFor,
				[value]: false,}
		console.log(object);
  	this.setState({
			datePickerFor: {
				...this.state.datePickerFor,
				[value]: false,
			}
  	});
  }


  returnValueFromDatepicker (dateArray, type)
  {
  	console.log(dateArray);
  	
  	let searchBox = {
  		...this.state.searchBox,
  		[type+"Day"] : isNaN(dateArray[0]) ? 0 : ('0' + dateArray[0]).slice(-2),
  		[type+"Month"] : isNaN(dateArray[1]) ? 0 : ('0' + dateArray[1]).slice(-2),
  		[type+"Year"] : isNaN(dateArray[2]) ? 0 : dateArray[2],
  	}
  	this.setState({
  		searchBox,
  		datePickerFor: {
				...this.state.datePickerFor,
				[type]: false,
			}
  	}, () => {this.props.setSearchFilter(this.state.searchBox, this.props.bookingListType)});
  	
  }

	render() {
		const endStyle = {
			position: "absolute",
			zIndex: this.state.datePickerFor.end !== true ? 0 : 2,
			minHeight: "100px",
			minWidth: "100px",
			overflow: "hidden",
			display: this.state.datePickerFor.end !== true ? "none": "inline",
		}
		const startStyle = {
			position: "absolute",
			zIndex: this.state.datePickerFor.start !== true ? 0 : 2,
			minHeight: "100px",
			minWidth: "100px",
			overflow: "hidden",
			display: this.state.datePickerFor.start !== true ? "none": "inline",
		}

		const startInputValue = (this.state.searchBox.startDay !== null ? this.state.searchBox.startDay: "--") + "/" + (this.state.searchBox.startMonth !== null ? this.state.searchBox.startMonth: "--") + "/" + (this.state.searchBox.startYear !== null ? this.state.searchBox.startYear: "----");
		const endInputValue = (this.state.searchBox.endDay !== null ? this.state.searchBox.endDay: "--") + "/" + (this.state.searchBox.endMonth !== null ? this.state.searchBox.endMonth: "--") + "/" + (this.state.searchBox.endYear !== null ? this.state.searchBox.endYear: "----");

		return (
			<div style={{zIndex: 1}}>
				<div className="form-inline">
					<div className="form-group">
						<label htmlFor="start">Check-In:</label>
						<div >						  
						  <input value={startInputValue} onFocus={this.openDatepicker} id="start" className="form-control" name="start" type="text" pattern="\d{2}/\d{2}/\d{4}" required/>
					  	<div style={startStyle}>
					  		{this.state.datePickerFor.start === true && <Calendar datePicker closeDatepicker={this.closeDatepicker} returnValueFromDatepicker={this.returnValueFromDatepicker} type="start"/>}
					  	</div>
					  </div>
						<label htmlFor="end">Check-Out:</label>
						<div>						  
						  <input value={endInputValue} id="end" onFocus={this.openDatepicker} className="form-control" name="end" type="text" pattern="\d{2}/\d{2}/\d{4}" required/>
					  	<div style={endStyle}>
					  		{this.state.datePickerFor.end === true && <Calendar datePicker closeDatepicker={this.closeDatepicker} returnValueFromDatepicker={this.returnValueFromDatepicker} type="end"/>}
					  	</div>
					  </div>					  
				    <div className="btn-group mr-2" role="group" aria-label="Pokój">
					    <button type="button" id="1" onClick={this.selectRoom} className={this.state.searchBox.roomId === "1" || this.state.searchBox.roomId === "all" ? this.state.roomButtonStyle.select+"success" : this.state.roomButtonStyle.unselect+"success"}></button>
					    <button type="button" id="2" onClick={this.selectRoom} className={this.state.searchBox.roomId === "2" || this.state.searchBox.roomId === "all" ? this.state.roomButtonStyle.select+"info" : this.state.roomButtonStyle.unselect+"info"}></button>
					    <button type="button" id="3" onClick={this.selectRoom} className={this.state.searchBox.roomId === "3" || this.state.searchBox.roomId === "all" ? this.state.roomButtonStyle.select+"warning" : this.state.roomButtonStyle.unselect+"warning"}></button>
					    <button type="button" id="all" onClick={this.selectRoom} className={this.state.searchBox.roomId === "all" ? this.state.roomButtonStyle.select+"secondary" : this.state.roomButtonStyle.unselect+"secondary"}>*</button>
					  </div>	
				  </div>
				</div>
			</div>
		)
	}
}

export default BookingSearchBox;