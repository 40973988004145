import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from './../../universal/datepicker/DatePicker';


class DateFields extends Component {
	constructor (props) {
		super(props);
		this.state = {
		};
	};


	render() {
		const months=['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

		const startPlaceholder = (this.props.contactBooking.checkin.day !== false ? this.props.contactBooking.checkin.day: "--") + " " + (this.props.contactBooking.checkin.month !== false ? months[this.props.contactBooking.checkin.month]: "--") + " " + (this.props.contactBooking.checkin.year !== false ? this.props.contactBooking.checkin.year: "----");
		const endPlaceholder = (this.props.contactBooking.checkout.day !== false ? this.props.contactBooking.checkout.day: "--") + " " + (this.props.contactBooking.checkout.month !== false ? months[this.props.contactBooking.checkout.month]: "--") + " " + (this.props.contactBooking.checkin.year !== false ? this.props.contactBooking.checkin.year: "----");
		
		const checkIn = <div className="form-group row">
			<label className="col-sm-4 col-form-label">Check-In</label>
			<label className="col-sm-8 col-form-label">{startPlaceholder}</label>
		</div>;
		const checkOut = <div className="form-group row">
		  <label className="col-sm-4 col-form-label">Check-Out</label>
			<label className="col-sm-8 col-form-label">{endPlaceholder}</label>				  
	  </div>;
	  const dateFields = <div>
	  {checkIn}
	  {checkOut}
	  </div>;
		return (
			<div>
				{dateFields}
			</div>
		);
	}
}

DateFields.propTypes ={
  saveValueToState: PropTypes.func,
  bookings: PropTypes.array,
  roomId: PropTypes.number

}

export default DateFields;