import React, { Component } from 'react';
import './../../../stylesheets/contact_page.css';
import ContactFields from './ContactFields';

class ContactForm extends Component {
	constructor () {
		super();
		this.state = {
			contactFirstName: null,
			contactSurname: null,
			contactEmail: null,
			contactTopic: 2,
			contactMessageContent: null,
			contactRodoLicense: null,
			FormIsFilledCorrect: false,

		}
		this.sendContactForm = this.sendContactForm.bind(this);
		this.saveContactValueToState = this.saveContactValueToState.bind(this);
	}

/*	handleInputChange(event) {
    const target = event.target;
    const value = target.id === "contactRodoLicense" ? target.checked : target.value;
    const name = target.id;
    if (name === 'contactTopic' && value === '1')
    {
    	this.props.handleMessageTopic(value);
    }
    else
    {
			this.props.handleMessageTopic(null);
    }

    this.setState({
      [name]: value
    });


    if (this.state.contactFirstName !== null && this.state.contactEmail !== null && this.state.contactTopic !== '0' && this.state.contactTopic !== null && this.state.contactMessageContent !== null && this.state.contactRodoLicense === true)
    {
    	this.setState({
      FormIsFilledCorrect: true
    	});
    }
    else
    {
    	this.setState({
      FormIsFilledCorrect: false
    	});
    }
  }*/
  saveContactValueToState(object) {
    this.setState({...object});
  }
	sendContactForm() {
   //const data = {login: this.state.formControls.login.value, password: this.state.formControls.password.value, booking: {days: this.state.contactBooking.days}}
    //event.preventDefault();
    const { FormIsFilledCorrect, ...data } = this.state
    fetch('https://164.132.113.224/api/scf', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())      
    .then((json) => this.props.handleMessageId(json.messageId))
    .then(this.props.setContactViewToContactFormConfirm())    
  }

  handleMessageConfirmation = (value) => {
  	this.props.setContactViewToContactFormConfirm();
    this.props.handleMessageId(value);
  }


	render() {
	const confirmButton = <input type="submit" disabled={!this.state.contactRodoLicense} className={'btn col-6 btn-secondary'} id="confirmButton" value="Wyślij"/>;
		return (
			<div className='ContactForm'>
			<form onSubmit={this.sendContactForm}>
				<fieldset className="border p-2">
					<legend className="w-auto">Formularz kontaktowy</legend>
					<ContactFields saveContactValueToState={this.saveContactValueToState} contactTopic={this.state.contactTopic}/>
					{confirmButton}
				</fieldset>
			</form>					
			</div>
		);
	}
}

export default ContactForm;