import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import MessagesNavigation from './../navigation/MessagesNavigation';
import e from 'cors';

class ContactFormMessagesPage extends Component {
	constructor() {
	super();
	this.state = {
		answerWindow: false,
		messageContent: false,
		navigateDirection: 'inbox',
		navigationBar: {
			inbox: {caption: 'Odebrane'},
			archived: {caption: 'Archiwalne'},
			'trash': {caption: 'Usunięte'},
		}
	}
	this.openAnswerWindow = this.openAnswerWindow.bind(this);
	this.closeAnswerWindow = this.closeAnswerWindow.bind(this);
	this.deleteMessage = this.deleteMessage.bind(this);
	this.archiveMessage = this.archiveMessage.bind(this);
	this.sendFormAnswer = this.sendFormAnswer.bind(this);
	this.onUserInput = this.onUserInput.bind(this);
	this.navigationService = this.navigationService.bind(this);
	};

	navigationService(navigateDirection){
		this.setState({navigateDirection});
	}

	openAnswerWindow(event){
		const answerWindow = event.target.name;
		this.setState({
			answerWindow
	})
	}
	closeAnswerWindow(event){
		const answerWindow = false;
		const messageContent = false;
		this.setState({
			answerWindow,
			messageContent
		})
	}

	deleteMessage(event){
		const messageId = event.target.name;
		this.props.deleteMessage(messageId);
	}

	archiveMessage(event){
		const messageId = event.target.name;
		this.props.archiveMessage(messageId);
	}

	sendFormAnswer(event){
		const messageId = event.target.name;
		this.props.sendFormAnswer(messageId, this.state.messageContent);
	}

	onUserInput(event){
		const messageContent = event.target.value;
		this.setState({
			messageContent
		});
	}

	localizeDateStr(date_to_convert_str) 
	{
		var date_to_convert = new Date(date_to_convert_str);
		var dateArray = [''+date_to_convert.getDate(), date_to_convert.getMonth()+1+'', ''+date_to_convert.getFullYear(), ''+date_to_convert.getHours(), ''+date_to_convert.getMinutes(), ''+date_to_convert.getSeconds()];
		dateArray = dateArray.map((dateElement)=>{
			if(dateElement.length<2)
			{
				return '0'+dateElement;
			}
			else
			{
				return dateElement;
			}

		});
		var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2] + " " + dateArray[3] + ":" + dateArray[4] + ":" +dateArray[5];
		return dateString; 	
	}
	

	componentDidMount(){
		this.props.downloadMessagesFromDB();
	};
	  
	render() {
		function filterOnlyFromContactForm(message)
		{
			return message.contactTopic === 2 && !message.archived && !message.deleted;
		}
		function filterArchived(message)
		{
			return message.archived === true && message.contactTopic === 2;
		}
		function filterDeleted(message)
		{
			return message.deleted === true && message.contactTopic === 2;
		}
		const messages = (this.props.messages && !this.state.navigateDirection) || (this.props.messages && this.state.navigateDirection==='inbox') ? this.props.messages.filter(filterOnlyFromContactForm) : this.props.messages && this.state.navigateDirection === 'archived' ? this.props.messages.filter(filterArchived) :  this.props.messages && this.state.navigateDirection === 'trash' ? this.props.messages.filter(filterDeleted) : [];
		return (
			<div className="row">
				<div className="col-2 offset-1"><MessagesNavigation counters={this.props.counters} navigateDirection={this.state.navigateDirection} navigationService={this.navigationService}/></div>
				<div className="col-8">
					<h3>{this.state.navigationBar[this.state.navigateDirection].caption}</h3>
					{this.props.messages && messages.map((message, index)=>(
					<div className="card" key={index}>
						<a 
						id={message.contactMessageId}
						data-toggle="collapse" 
						href={"#messagesFullMessage"+message.contactMessageId} 
						role="button" 
						onClick={this.props.setMessageAsRead} 
						aria-expanded="false" aria-controls={"messagesFullMessage"+message.contactMessageId}>
							<div 
							className="card-header" id={message.contactMessageId} 
							style={{ fontWeight: message.contactUnread === true ? '700': '400'}}>
								<p>{message.contactFirstName}{message.contactSurname ? " "+message.contactSurname : ''}</p>
								<p>{message.contactMessageContent.slice(0,50)+" ... "}</p>
								<p style={{position: 'absolute', top: 5, right: 5}}>{this.localizeDateStr(message.contactDate)}</p>
							</div>
						</a>
						<div className="collapse" id={"messagesFullMessage"+message.contactMessageId}>
							{message.contactMessageId !== this.state.answerWindow && <div className="card-body">
								<div className="card-title">
								</div>
								<div className="card-text">
									<p className="messageContent">
										{message.contactMessageContent}
										<span>
											{message.contactFirstName}{message.contactSurname ? " "+ message.contactSurname : " "}
										</span>
									</p>
								</div>
								{!message.archived && !message.deleted && <button name={message.contactMessageId} className="btn btn-primary col-3 offset-1" onClick={this.openAnswerWindow}>Odpowiedz</button>}
								{!message.archived && !message.deleted && <button name={message.contactMessageId} className="btn btn-success col-3 offset-1" onClick={this.archiveMessage}>Archiwizuj</button>}
								{!message.deleted && <button name={message.contactMessageId} className="btn btn-danger col-3 offset-1" onClick={this.deleteMessage}>Usuń</button>}
							</div>}
							{message.contactMessageId === this.state.answerWindow && <div className="card-body">
								<div className="card-title">
								</div>
								<div className="card-text">
									<p className='responseReason'>
										{message.contactMessageContent}
										<br/>
										{message.contactFirstName}{message.contactSurname ? " "+ message.contactSurname : " "}
									</p>
									<textarea rows='7' className='responseWindow' placeholder='Odpowiedź...' onChange={this.onUserInput} defaultValue={this.state.messageContent ? this.state.messageContent : ''}>
									</textarea>
								</div>
								<button className="btn btn-success col-6" onClick={this.sendFormAnswer}>Wyślij</button>
								<button className="btn btn-danger col-6" onClick={this.closeAnswerWindow}>Anuluj</button>
							</div>}
						</div>
					</div>
				))}
			</div>
			</div>
		)
	}
}

export default ContactFormMessagesPage