//wyszukiwanie po booking Id, name, nr telefonu, email, kategorii

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class SearchBox extends Component {
    constructor(){
        super();
        this.state={
            searchPositions : [
                {
                    name: 'id',
                    caption: 'id rezerwacji'
                },
                {
                    name: 'surname',
                    caption: 'nazwisko'
                },
                {
                    name: 'email',
                    caption: 'e-mail'
                },
                {
                    name: 'phone',
                    caption: 'nr telefonu'
                },
            ]
        }
    }
	render() {
		return (
			<div className='searchbox'>
                <p>Wyszukaj</p>
                {
                    this.state.searchPositions.map((searchInput, index)=>(
                        <input key={index} class="form-control" type='text' placeholder={searchInput.caption} id={searchInput.name}></input>
                    ))
                }
				
			</div>
		)
	}
}

export default SearchBox;