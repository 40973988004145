import React, { Component } from 'react';
import PropTypes from 'prop-types';


class GuestsFields extends Component {
	constructor (props) {
		super(props);
		this.state = {
			guestsChild: false,
		};
    	this.handleInputValue = this.handleInputValue.bind(this);
    	this.handleGuestsChildCheckbox = this.handleGuestsChildCheckbox.bind(this);
	};

	handleInputValue(event) {
    const name = event.target.id;
    const value = name === 'guestsCar' ? event.target.checked : event.target.value;
    const object = { [name] : value};
    this.props.saveValueToState(object);
  }
  handleGuestsChildCheckbox(event) {
    const value = event.target.checked;
    const name = event.target.id;
    this.setState({
  			[name] : value,
    });
  }

	render() {
	const guestsCar = <div 
		className="form-group row" 
		data-toggle='tooltip'
		title='Zaznacz jeśli odwiedzisz nas samochodem.' >
	    <label className="col-sm-4 col-form-label" htmlFor="guestsCar">Samochód</label>
	    <div className="col-sm-8">
	      <input type="checkbox" className="form-control" id="guestsCar" onChange={this.handleInputValue}/>
	    </div>
	  </div>;
	  const guestsQuantity = <div className="form-group row">
	    <label className="col-sm-4 col-form-label" htmlFor="guestsQuantity">Goście</label>
	    <div className="col-sm-8">
	      <input type="number" 
		      className="form-control" 
		      max={this.props.capacity - this.props.guestsChildQuantity} 
		      min="1" 
		      id="guestsQuantity" 
		      placeholder="liczba gości pełnopłatnych" 
		      onChange={this.handleInputValue}/>
	    </div>
	  </div>;
	  const guestsChildCheckbox =	<div className="col-sm-8">
      <input type="checkbox" className="form-control" id="guestsChild" onChange={this.handleGuestsChildCheckbox}/>
    </div>;
	  const guestsChildNumberInput = <div className="col-sm-8">
	      <input 
	      type="number" 
	      className="form-control"
	      max = {this.props.capacity - this.props.guestsQuantity} 
	      min="0" 
	      id="guestsChildQuantity" 
	      placeholder="liczba gości do 7 roku życia" 
	      onChange={this.handleInputValue}/>
	    </div>;
		const guestsChildQuantity = <div className="form-group row">
	    <label className="col-sm-4 col-form-label" htmlFor="guestsChildQuantity">Goście do 7 roku życia</label>
	    {!this.state.guestsChild && guestsChildCheckbox}
	    {this.state.guestsChild && guestsChildNumberInput}
	  </div>;
	  
	const guestsFields = <div>
	{guestsQuantity}
	{guestsChildQuantity}
	{guestsCar}
	</div>;
		return (
			<div>
				{guestsFields}
			</div>
		);
	}
}

GuestsFields.propTypes ={
  saveValueToState: PropTypes.func,
	capacity: PropTypes.number,


}

export default GuestsFields;