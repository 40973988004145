import React, { Component } from 'react';


class Calendar extends Component {
	constructor (props) {
		super(props);
		let today = new Date ();
		this.state = {
			show: {
				confirmed: true,
				unconfirmed: false,
				archived: false
			},
			calendar: [],
			roomId: 1,
			currentMonth: today.getMonth(),
			currentYear: today.getFullYear(),
			selectYear: document.getElementById("Year"),
			selectMonth: document.getElementById("Month"),
			monthAndYear: document.getElementById("monthAndYear"),
			months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],	
			get selectableYears() {return [this.currentYear, this.currentYear+1]}
		}
		this.jump = this.jump.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);

	}	

	jump(event) {		
		let value = event.target.value;
		let name = event.target.name;
		console.log(value + "    " + name);
		this.setState({
	    currentYear : parseInt(document.getElementById("Year").value),
	    currentMonth : parseInt(document.getElementById("Month").value)
	  }, () => {this.generateCalendar(this.state.currentMonth, this.state.currentYear)} );
	}

	  handleInputChange(event) {
    const target = event.target;
    const name = target.name;
    var id;
    if (name === "roomId")
    {
    	id = parseInt(target.id);
    }
    else
    {
    	if( name === "details")
    	{
    		id = target.id;
    	}
    	else
    	{
    		id = target.checked;
    	}
    }
    

    if (name && name === "roomId")
    {
	    this.setState({
	     roomId: id
	    }, () => {this.generateCalendar(this.state.currentMonth, this.state.currentYear)} );
    }
    else
    {
    	if (name && name === "details")
	    {
		    this.setState({
		     details: id
		    });
	    }
    	else
			{
			 	this.setState({
    		show: {
    			...this.state.show,
    			[name]: id    
    		},
    	}, () => {this.generateCalendar(this.state.currentMonth, this.state.currentYear)} );
    }
    }
  }


	generateCalendar(month, year) {
		let firstDay = (new Date(year, month)).getDay();
		let days = [];
		let bookingIds = [];
		console.log("Przeglądany miesiąc : " + this.state.currentMonth);
		console.log("przeglądany id: " + this.state.roomId);
		let bookedForThisMonth;
		let collectionsScope = [];	
		if (this.state.show.unconfirmed === true)
		{
			collectionsScope = collectionsScope.concat(this.props.unconfirmed);
		}	
		if (this.state.show.confirmed === true)
		{
			collectionsScope = collectionsScope.concat(this.props.confirmed);
		}	
		if (this.state.show.archived === true)
		{
			collectionsScope = collectionsScope.concat(this.props.archived);
		}	
		if (collectionsScope[1])
		{
			bookedForThisMonth =  collectionsScope.filter((book)=>{return book.roomId === this.state.roomId && ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))});
    	
    	console.log(bookedForThisMonth);
		}

    
	  if (bookedForThisMonth !== undefined)
	  {
	  	days = [];
	  	bookingIds = [];
	  	for (let i=0; i<bookedForThisMonth.length; i++)
	  	{ 
	  		let checkInDate = new Date (bookedForThisMonth[i].startYear, bookedForThisMonth[i].startMonth-1, bookedForThisMonth[i].startDay);
	  		let checkOutDate = new Date (bookedForThisMonth[i].endYear, bookedForThisMonth[i].endMonth-1, bookedForThisMonth[i].endDay);
	  		let bookingId = bookedForThisMonth[i].bookingId;
	  		console.log("CheckIn : " + checkInDate);
	  		console.log("CheckOut : " + checkOutDate);
	  		let guestStay = (checkOutDate - checkInDate) / (1000*60*60*24);
	  		console.log("Liczba dni pobytu: " + guestStay);
	  		
	  		for (let j=0; j<=guestStay; j++)
	  		{
	  			let stayDay = new Date (checkInDate);
	  			stayDay.setDate(checkInDate.getDate() + j);
	  			let day;
	  			
	  			console.log("stayDay " + stayDay.getMonth() + ", currentMonth " + this.state.currentMonth);
					if (stayDay.getMonth() === this.state.currentMonth)
					{
						 day = stayDay.getDate();	
						 //console.log("wewn: " + day);
						 days = days.concat(day);
						 bookingIds = bookingIds.concat(bookingId);

					}					
					//console.log("zewn: " + day)
	  			
	  		}
		  	//console.log("booked for this month: " + days);
		  }
		}

		firstDay = (firstDay===0)?firstDay=7:firstDay=firstDay;
    let daysInMonth = 32 - new Date(year, month, 32).getDate();
		let newCalendar = [];
		let newCalendarBookingIds = []
		let date = 1;
    for (let i = 0; i < 6; i++) 
    {
        //create array with row of calendar
        let row = [];
        let rowBookingsIds = []

        //fill row with days
        for (let j = 1; j <= 7; j++) 
        {
          if (i === 0 && j < firstDay) 
          {
            let cell = 0;
            row.push(cell);
            rowBookingsIds.push(0);
          }
          else if (date > daysInMonth) 
          {
            break;
          }
          else 
          {
	          let cell;
	          //console.log(date);  
			      if (days.includes(date))
		      		{
		      			//console.log('znalazl');
		      			cell = "X";
		      			row.push(cell);
		      			rowBookingsIds.push(bookingIds[days.indexOf(date)]);
		      		}
		      		else
		      		{
		      			cell = date;
		      			row.push(cell);
		      			rowBookingsIds.push(0);
		          }
			      date++;
          }
        }
        newCalendar.push(row);
        newCalendarBookingIds.push(rowBookingsIds);
      }
		this.setState({
			calendar: newCalendar,
			calendarBookingIds: newCalendarBookingIds
		})
	}

	
	componentDidMount(){
    this.generateCalendar(this.state.currentMonth, this.state.currentYear);
  }
	
	render() {
		return (
			<div className="row justify-content-center">
				<div>
				  <div 
				  className="card">
				  	<div className="card-header">
					  	<div 
					  	className="form-inline">
		            <label 
		            className="lead mr-2 ml-2" 
		            htmlFor="Month">
		            {this.props.description}
		            </label>
		            <select 
		            className="form-control col-4" 
		            value={this.state.currentMonth} 
		            name={"Month"} 
		            id={"Month"} 
		            onChange={this.jump}>
	            		{this.state.months.map((month, index)=>
	            			<option 
	            			value={index} 
	            			key={index}>
	            				{month}
	            			</option>
	            		)}
		            </select>
			          <select 
			          className="form-control col-4" 
			          name={"Year"} 
			          id={"Year"} 
			          onChange={this.jump}>
			            <option
			            value={this.state.selectableYears[0]}>
			            	{this.state.selectableYears[0]}
			            </option>
			            <option 
			            value={this.state.selectableYears[1]}>
			            	{this.state.selectableYears[1]}
			            </option>
				        </select>
				      </div>				       
				    </div>		     			        
		        <div 
		        className="card-body">
		        	<table 
		        	className="table table-bordered table-responsive-sm" 
		        	id="calendar">
		            <thead>
		            <tr>			                
	                <th>Pn.</th>
	                <th>Wt.</th>
	                <th>Śr.</th>
	                <th>Cz.</th>
	                <th>Pt.</th>
	                <th>Sb.</th>
	                <th>Nd.</th>
		            </tr>
		            </thead>
		            <tbody 
		            id="calendar-body">
			            {this.state.calendar.map((week, index)=>(
		          			<tr key={index}>
		          			{week.map((day, idx)=>
		          			(
		          				(day===0)
		          				?<td className="content" key={idx}>{""}</td>
		          				:<td className="content" key={idx} style={{margin:0, padding: 0, width: "85px", height: "85px"}}>
				          				 <a href="#" data-toggle="tooltip" title={this.state.calendarBookingIds[index][idx] !== 0 ? "Booking Id: " + this.state.calendarBookingIds[index][idx] : ""}>
				          				 <input type="button" 
				          				 id={this.state.calendarBookingIds[index][idx] !== 0 ? this.state.calendarBookingIds[index][idx] : ""} 
				          				 value={day==="X" ? "" : day} 
				          				 name="details"
				          				 onClick={this.handleInputChange}
				          				 style= {{height:"85px", width: "85px", display: "inline-block"}}
				          				 className={(day!=="X" && this.state.roomId) ? "btn btn-outline-secondary" : "btn btn-"+this.props.rooms[this.state.roomId-1].cssColor}/>
				          
				          				 </a></td>
		          			))}
		          			</tr>
			            ))}		            
		            </tbody>
		        	</table>	        	
		      	</div>
		    	</div>
		    </div>
		    <div className="col-2">
		    <h3>Warstwy</h3>
		    <div id="buttonsGroup">
		    {this.props.rooms.map((room, index)=>(
          <button key={index} name="roomId" id={room.roomId} style={{marginTop: "5px", left: "25px", width: "85px", height: "85px"}} className={room.roomId !== this.state.roomId ? "btn btn-outline-" + room.cssColor : "btn btn-" + room.cssColor} onClick={this.handleInputChange}/>
          ))}
        </div>
		    <div id="form-control" style={{position: "absolute", top: "330px"}}>
			    <input type="checkbox" name="confirmed" onChange={this.handleInputChange} className="btn btn-dark" checked={this.state.show.confirmed}/>
					<label htmlFor="confirmed">Potwierdzone</label><br/>
					<input type="checkbox" name="unconfirmed" onChange={this.handleInputChange} className="btn btn-dark" checked={this.state.show.unconfirmed}/>
					<label htmlFor="unconfirmed">Niepotwierdzone</label><br/>
					<input type="checkbox" name="archived" onChange={this.handleInputChange} className="btn btn-dark" checked={this.state.show.archived}/>
					<label htmlFor="archived">Archiwalne</label>
		    </div>
		    </div>
			</div>
		);
	}
}

export default Calendar;