import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class ContactFormConfirmation extends Component {
	render() {
		return (
			<div>					
			  <div className="card">
			  	<div className="card-header">Potwierdzenie</div>
			    <div className="card-body">
			    	<div className="card-title">Formularz wysłano poprawnie.</div>
			    	<div className="card-text">Twój indywidualny identyfikator wiadomości: {this.props.contactMessageId}</div>
			    	<button className="btn btn-secondary" onClick={this.props.setContactViewToMain}>Ok</button>
			    </div>
			  </div>
			</div>
		)
	}
}

export default ContactFormConfirmation;