import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import RoomPreview from './RoomPreview';
import RoomSelector from './RoomSelector';


class BookingMessagesNavigation extends Component {
	constructor() {
		super();
		this.state={
			navigationBar: [
				{name: 'inbox', caption: 'Odebrane'},
				{name: 'accepted', caption: 'Zaakceptowane'},
				{name: 'rejected', caption: 'Odrzucone'},
			]
		}
		this.forwardNavigation = this.forwardNavigation.bind(this);
		this.messagesNavigation = this.messagesNavigation.bind(this);

	}
	forwardNavigation(event){
		const navigateDirection = event.target.name;
		this.props.navigationService(navigateDirection);
	}
	messagesNavigation() {
		return (
			<div className='message-settings-navigation'>
				<p>Skrzynka</p>
				<ul>
					{this.state.navigationBar.map((navigateDirection, idx)=>(
						<li key={idx}>
						<button className={this.props.navigateDirection === navigateDirection.name? 'btn btn-dark btn-block' : 'btn btn-outline-dark btn-block'}  name={navigateDirection.name} onClick={this.forwardNavigation}>{navigateDirection.caption}</button>
						</li>
					))}

				</ul>
			</div>
		)
	}

	render() {
		let rooms = this.props.rooms ? this.props.rooms.map((room) => {return room.roomId}) : [];
		return (
			<div className='col-2 offset-1'>
			<RoomSelector selectRoom={this.props.selectRoom} //function changing room on user select
				counters={this.props.counters}
				rooms={rooms} //[] of available rooms
				selected={this.props.selected} //info about room selected by user
			/>
			{this.messagesNavigation()}
			<RoomPreview gallery={this.props.rooms[this.props.selected-1].photos} folderName={this.props.selected}/>
		</div>
		
		)
	}
}

export default BookingMessagesNavigation;