import React, { Component } from 'react';
import PropTypes from 'prop-types';
import RoomDetails from './RoomDetails'

import './../../../../stylesheets/room_page.css'

class RoomPage extends Component {
container(){
	this.state = {
		animationName: false,
	}
}

componentWillMount(){
	setTimeout(this.setState({
		animationName: 'RoomPage content-bg'
	}), 1000);

}


	render() {
		let Rooms = new Array;
		let cssClass = 'RoomPage1 content-bg';
		if (this.props.rooms)
		{
			if (!this.props.showSingle)
			{
				Rooms = Rooms.concat(...this.props.rooms);
				cssClass = 'RoomPage'+this.props.rooms[0].roomId+' content-bg';
			}
			else
				Rooms = Rooms.concat(this.props.rooms[this.props.showSingle-1]);
		}
		return (	
			<div className={cssClass}>
			{Rooms[0]
				? Rooms.map((room, index)=>(
					<RoomDetails room={room} key={index}
					bookings={this.props.bookings.filter((book)=>{return book.roomId === room.roomId})}
					/>
				)) 
				:	null
			}


			</div>
		)
	}
}

export default RoomPage;