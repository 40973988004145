import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../../../stylesheets/customer_relation_page.css';

class RulesDescription extends Component {
	render() {
		return (
			<div className="bg-white rulesAccept">		
			  <div className="card rulesDescription">
			  	<div className="card-header"><p>Krok 2 - Regulamin rezerwacji online</p><button id={this.props.type} className="btn btn-outline-danger" style={{position: 'absolute', right: '25px', top: '5px'}} onClick={this.props.newBookingModeSwitch}>X</button></div>
			    <div className="card-body">
			    	<div className="card-title"></div>
			    	<div className="card-text">
							<ol>
								<li>Formularz rezerwacji jest usługą umożliwiającą kontakt 24h/7 z Kwatery Laskowscy w celu rezerwacji pokoju na wybrany prez Klienta okres.</li>
								<li>Przesłanie przez Klienta poprawnie wypełnionego formularza rezerwacji stanowi podstawę do rozpoczęcia procesu rezerwacji w Kwatery Laskowscy.</li>
								<li>W ciągu 24h obsługa Kwatery Laskowscy zweryfikuje rezerwację i nada jej status o czym zostaniesz poinformowany w wiadomości na podany adres e-mail.</li>
								<li>Kwatery Laskowscy zastrzega sobie prawo do odmowy realizacji rezerwacji bez podania przyczyny.</li>
								<li>warunkiem realizacji zaakceptowanej przez obsługę rezerwacji jest dokonanie przedpłaty za 2 doby zgodnie z wytycznymi otrzymanymi w wiadomości potwierdzającej rezerwację.<br/>
								Pozostałą część regulujemy w dniu przyjazdu.</li>
								<li>Klient ma prawo do przeniesienia opłaconej rezerwacji na inny wolny termin.</li>
								<li>Klient ma prawo do rezygnacji z rezerwacji, przy czym:
									<ul>
										<li>powyżej 40 dni przed przyjazdem - zwracamy 100% zaliczki</li>
										<li>40-21 dni przed przyjazdem - zwracamy 80% zaliczki</li>
										<li>21-14 dni przed przyjazdem - zwracamy 40% zaliczki</li>
										<li>poniżej 14 dni przed przyjazdem nie zwracamy przedpłaty</li>
									</ul>
								</li>
							</ol>
			    	</div>
			    </div>
			  </div>
							{this.props.acceptBookingRulesGroup}
			
			</div>
		);
	}
}
RulesDescription.propTypes = {
	acceptBookingRulesButton: PropTypes.object
}

export default RulesDescription;
