import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class UserAccount extends Component {
	render() {
        const userAccount = this.props.user;
		return (
			<div className='user-account'>
                <p>Id: <span>{userAccount.uid}</span></p>
                <p>Domena: <span>{userAccount.domain}</span></p>
                <p>Login: <span>{userAccount.login}</span></p>
                <p>Właściciel konta: <span>{userAccount.fullname}</span></p>
                <p>Konto <span>{userAccount.active ? 'aktywne' : 'nieaktywne'}</span></p>
                <p><br/></p>  
                <p>Dane kontaktowe: </p>
                <p>e-mail: <span>{userAccount.email}</span></p>
                <p>tel.: <span>{userAccount.phone}</span></p>  
                <p>kom.: <span>{userAccount.mobile}</span></p>
                <p><br/></p>  
                <p>NR KONTA: <span>{userAccount.account}</span></p>
            </div>
		)
	}
}

export default UserAccount;