import React, { Component } from 'react';
import PropTypes from 'prop-types';


class ContactFields extends Component {
	constructor (props) {
		super(props);
		this.state = {
			guestsChild: false,
		};
    	this.handleInputValue = this.handleInputValue.bind(this);
	};

	handleInputValue(event) {
    const id = event.target.id;
    const value = id === 'contactRodoLicense' ? event.target.checked : event.target.value ;
    const object = { [id] : value};
    this.props.saveContactValueToState(object);
  }

	render() {
		const agreementRODO = <div className="form-group row">
			<div className="col-sm-4"></div>
			<div className="col-sm-8">
			  <div className="form-check">
			    <input className="form-check-input" type="checkbox" id="contactRodoLicense" required onChange={this.handleInputValue}/>
			    <label className="form-check-label" htmlFor="contactRodoLicense">
			      Zgadzam się na przetwarzanie moich danych osobowych zamieszczonych w niniejszym formularzu zapytania o ofertę przez Beata Laskowska, Gryfa Pomorskiego 14/2, 72-500 Międzyzdroje, w celu kontaktu z Tobą oraz udzielenia Ci odpowiedzi na zadane pytanie. 
			      <a target='_blank' className="small" href="/kl_rodo.pdf" role="button" > dalej ...</a>
			    </label>
			    <div className="collapse" id="contactFullLicense">
					  <div>
							<p>Administratorem Państwa danych osobowych przekazywanych na niniejszej stronie jest Beata Laskowska, Gryfa Pomorskiego 14/2, 72-500 Międzyzdroje.
							Jeżeli złożyłeś oświadczenie o zgodzie na przetwarzanie danych osobowych w każdej chwili masz prawo cofnąć tą zgodę. Cofnięcie zgody nie będzie wpływać na zgodność z prawem przetwarzania, którego dokonano na podstawie Twojej zgody przed jej wycofaniem.
							Dane osobowe przetwarzane na poniższej stronie wykorzystywane są w celu realizacji umowy najmu, kontaktu z Tobą jak również odpowiedzi na zadane przez Ciebie pytania, na podstawie prawnie uzasadnionego interesu Administratora, na podstawie Twojej zgody i zainteresowania naszą ofertą (podstawa z art. 6 ust. 1 lit. a i b RODO).
							Twoje dane osobowe możemy udostępniać następującym kategoriom podmiotów:
							podwykonawcom, czyli podmiotom, z których korzystamy przy ich przetwarzaniu;
							partnerom handlowym, których oferta uzupełnia naszą ofertę;</p>
							<p>Twoje Dane osobowe będą przetwarzane i przechowywane od momentu ich uzyskania przez Administratora Danych Osobowych do czasu obowiązywania umowy zawartej Tobą, a także po jej zakończeniu w celach:
							dochodzenia roszczeń w związku z wykonywaniem umowy;
							wykonania obowiązków wynikających z przepisów prawa, w tym w szczególności podatkowych i rachunkowych;
							zgłosisz sprzeciw względem ich przetwarzania w tym celu, cofniesz zgodę, jeśli przetwarzaliśmy je na podstawie tzw. zgody marketingowej, lub sami ustalimy, że się zdezaktualizowały.
							Warto pamiętać, że masz prawo wniesienia skargi do UODO gdy uznasz, iż przetwarzanie danych osobowych narusza przepisy ogólnego rozporządzenia o ochronie danych osobowych z dnia 27 kwietnia 2016 r.
							Przysługuje Ci prawo:</p>
							<ul>
								<li>dostępu do swoich danych osobowych i otrzymania ich kopii;</li>
								<li>do sprostowania (poprawiania swoich danych);</li>
								<li>do usunięcia danych;</li>
								<li>do ograniczenia przetwarzania danych osobowych;</li>
								<li>do wniesienia sprzeciwu wobec przetwarzania danych osobowych;</li>
								<li>do przenoszenia danych;</li>
								<li>prawo do wniesienia skargi do organu nadzorczego;</li>
								<li>prawo do cofnięcia zgody na przetwarzanie danych osobowych.</li>
							</ul>
							Podanie danych jest dobrowolne ale ich nie podanie będzie równoznaczne z brakiem możliwości udzielenia odpowiedzi na pytanie, brakiem możliwości kontaktu, brakiem możliwości realizacji usług lub brakiem możliwości otrzymywania informacji o charakterze marketingowym.
					  </div>
					</div>
			  </div>
			</div>
		</div>;
		const contactFirstName = <div className="form-group row">
	    <label className="col-sm-4 col-form-label" htmlFor="contactFirstName">Imię*</label>
	    <div className="col-sm-8">
	      <input type="text" className="form-control" id="contactFirstName" placeholder="Imię" required onChange={this.handleInputValue}/>
	    </div>
	  </div>;
		const contactEmail = <div className="form-group row">
		  <label htmlFor="contactEmail" className="col-sm-4 col-form-label">Email*</label>
		  <div className="col-sm-8">
		    <input type="email" className="form-control" id="contactEmail" placeholder="Email" required onChange={this.handleInputValue}/>
		  </div>
		</div>;
		const contactPhone = <div className="form-group row">
	    <label htmlFor="contactPhone" className="col-sm-4 col-form-label">Telefon</label>
	    <div className="col-sm-8">
	      <input type="text" className="form-control" id="contactPhone" placeholder="nr telefonu" onChange={this.handleInputValue}/>
	    </div>
	  </div>;
	  const contactSurname = <div className="form-group row">
		  <label className="col-sm-4 col-form-label" htmlFor="contactSurname">Nazwisko*</label>
		  <div className="col-sm-8">
		    <input type="text" className="form-control" id="contactSurname" placeholder="Nazwisko" required onChange={this.handleInputValue}/>
		  </div>
		</div>;
		const contactMessageContent = <div className="form-group">
			<div className="row">
				<label htmlFor="contactMessageContent" className="col-sm-4 col-form-label">Wiadomość: {this.props.contactTopic === '2' ? '*' : ''}</label>
				<div className="col-sm-8">
					<textarea id="contactMessageContent" className="form-control mb-12" required={this.props.contactTopic === '2'} placeholder="Wiadomość do gospodarza..." onChange={this.handleInputValue}></textarea>
				</div>
			</div>
		</div>;
		const contactFields = <div>
			{contactFirstName}
			{contactSurname}
			{contactPhone}
			{contactEmail}
			{contactMessageContent}
			{agreementRODO}
		</div>;
		return (
			<div>
				{contactFields}
			</div>
		);
	}
}

ContactFields.propTypes ={
  saveContactValueToState: PropTypes.func,
  contactTopic: PropTypes.number,
}

export default ContactFields;