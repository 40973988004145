import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {roomButtonClass} from './colorsSettings'

class BookingRoomSelect extends Component {
	constructor(props){
		super(props);
		this.selectRoom = this.selectRoom.bind(this);
	}
	selectRoom(event){
		const selected = parseInt(event.target.id);
		this.props.selectRoom(selected);
	}
	render() {
		const roomColors = ['success', 'primary', 'warning', 'secondary'];
		function buttonColor(select, roomColor)
		{
			const roomColors = ['success', 'primary', 'warning', 'secondary'];
			if(roomColor !== 0)
				return select ? 'btn btn-'+roomColors[roomColor-1]+' btn-block' : 'btn btn-outline-'+roomColors[roomColor-1]+' btn-block';
			else
				return select ? 'btn btn-'+roomColors[3]+' btn-block' : 'btn btn-outline-'+roomColors[3]+' btn-block';
		}
		return (
			<div id="buttonsGroup" style={{textAlign: 'center'}}>
			<p>Pokój</p>
				{this.props.rooms && this.props.rooms.map((room, index)=>(
						<button
							key={index}
							name="roomId"
							id={room}
							value={room}
							className={buttonColor(this.props.roomId===room, room)}
							
							onClick={this.selectRoom}
						>
							<h2 id={room} style={{height: '30px'}}>{this.props.counters && this.props.counters['booking_room_'+room] > 0 && <span id={room} class={"badge badge-pill badge-"+roomColors[room-1]}>{this.props.counters['booking_room_'+room]}{this.props.counters['booking_room_'+room] < 2 ? ' nowe zapytanie' : ' nowe zapytania'}</span>}</h2>
						</button>
				))}
			</div>
		);
	}
}

BookingRoomSelect.propTypes ={
	selectRoom: PropTypes.func,
	roomId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool ]),
	rooms: PropTypes.arrayOf(PropTypes.number)
}

export default BookingRoomSelect;

