import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BookingCalendarLanguageSettings from './booking-calendar-utility/BookingCalendarLanguageSettings';
import BookingsList from './booking-component/BookingsList';
import BookingRoomSelect from './booking-calendar-utility/BookingRoomSelect';
import BookingTypeSelect from './booking-calendar-utility/BookingTypeSelect';
import {bookingTypeColors} from './booking-calendar-utility/colorsSettings';
import Calendar from './calendar-component/Calendar';
import {chooseLanguage} from './booking-calendar-utility/langSettings';
import {defaultValues} from './booking-calendar-utility/defaultValues';
import Footer from './footer/Footer';
import {generateCalendar} from './calendar-component/CalendarUtility';
import RoomPreview from './../navigation/RoomPreview';


class BookingsCalendar extends Component {
	constructor (props) {
		super(props);
		const today = new Date ();
		this.state = {
			bookingTypeColors, //default colors of 3 booking collections
			headers: chooseLanguage('gb'), //prepare headers default for english
			today: {
				day: today.getDate(), 
				month: today.getMonth(), 
				year: today.getFullYear()
			},
			currentCalendar: { //all information used in component calendar
				activeCollections: {...defaultValues.calendarCollections.calendarMode}, //defult settings for collections
				calendar: {}, //this object contain 3 arrays daysCalendar, idsCalendar, typesCalendar all used as main data in DayCalendar
				currentMonth: today.getMonth(), //current viewed month, at begging for this month
				currentYear: today.getFullYear(), //current viewed year, at begging for this month
				selectableYears: [today.getFullYear(), today.getFullYear()+1], //which years are possible to select
			},
			roomId: 1, //selected room
			bookingList: [], //list for component BookingList
			newBooking: {//default new booking definition and properties
				array : [{ //new booking as array used in booking list
					bookingId: 'new',
					roomId: this.roomId ? this.roomId : 1,
					bookingType: 'unconfirmed',
					checkin: false,
					checkout: false,
				}],
				object: defaultValues.newBookingObject,	//new booking as object for calendar usage
				newBookingIsCorrect: false,
				newBookingMode: false,
				selectedDayCount: 0,	//coreleted to newBooking, 1st and 2nd click on calendar day while new booking is added
			},
			langSettings: {select: "gb", available: ['de', 'gb', 'no', 'pl']},	
			showBookingDetails: false, //property for bookingList
		}
		this.addBookingToUnconfirmedCollection = this.addBookingToUnconfirmedCollection.bind(this);
		this.calendarSkipTo = this.calendarSkipTo.bind(this);
		this.changeShowBookingDetailsValue = this.changeShowBookingDetailsValue.bind(this);
		this.changeCalendarCollection = this.changeCalendarCollection.bind(this);
		this.changeCalendarRoom = this.changeCalendarRoom.bind(this);
		this.createCollectionScope = this.createCollectionScope.bind(this);
		this.createBookingList = this.createBookingList.bind(this);
		this.createCalendarView = this.createCalendarView.bind(this);
		this.getSpecificBooking = this.getSpecificBooking.bind(this);
		this.returnSelectedDay = this.returnSelectedDay.bind(this);
		this.setLanguage = this.setLanguage.bind(this);
		this.toggleNewBookingMode = this.toggleNewBookingMode.bind(this);
		this.updateState = this.updateState.bind(this);
	}	

	updateState (bookingId)
	{//correct bookingId inform about process is done reset all values to default and show details about new booking
		let object = defaultValues.newBookingObject;
		let array = defaultValues.newBookingArray;
		array[0].roomId = this.roomId ? this.roomId : 1;
		this.setState({
			currentCalendar:				
			{
				...this.state.currentCalendar,
				activeCollections: {...defaultValues.calendarCollections.calendarMode},
			},
			newBooking: {
				array,
				newBookingIsCorrect: false,
				newBookingMode: false,
				object, 
			},
			showBookingDetails: bookingId
		}, () => this.createCalendarView())
	}

	addBookingToUnconfirmedCollection() {//comunicate with DemoBookingCalendarContainer
		const newBooking = {...this.state.newBooking.object, roomId: this.state.roomId};

		let bookingId = this.state.newBooking.newBookingIsCorrect ? this.props.addToUnconfirmedCollection(newBooking, this.updateState) : undefined; //DemoBookingCalendarContainer after register booking return bookingId
	}
	calendarSkipTo(month, year) {		
		this.setState({
			currentCalendar: {
				...this.state.currentCalendar,
				currentYear : year,
	    	currentMonth : month
			},
	    showBookingDetails : false
	  }, () => this.createCalendarView() );
	}
	changeCalendarCollection(inputObject)
	{//set changes input: {nameOfCollection: Boolean}
		this.setState({
			currentCalendar: {
				...this.state.currentCalendar,
				activeCollections: {
				...this.state.currentCalendar.activeCollections,
				...inputObject
				} 
			}, 
			showBookingDetails: false,
		}, () => this.createCalendarView() );
	}
	changeCalendarRoom(roomId)
	{ //do changes when user select room button
		let array = defaultValues.newBookingArray;
		array[0].roomId = roomId;
		this.setState({
			roomId, 
			showBookingDetails: false, 
			newBooking : {...this.state.newBooking,	
				array,
				newBookingIsCorrect: false,
				object: {...defaultValues.newBookingObject},
				selectedDayCount: 0
			}
		}, () => this.createCalendarView());
	}
	changeShowBookingDetailsValue(showBookingDetails)
	{//change property for showBookingDetails
		if (this.props.setSpecificCalendar)
		{
			console.log('robie')
			const setSpecificCalendar = this.props.setSpecificCalendar;
			let currentCalendar = {...setSpecificCalendar.currentCalendar},
			roomId = setSpecificCalendar.roomId,
			bookingType = setSpecificCalendar.bookingType,
			array = defaultValues.newBookingArray;
			array[0].roomId = roomId;
			this.setState({
				currentCalendar: {
					...this.state.currentCalendar,
					...currentCalendar,
					activeCollections: {...this.state.currentCalendar.activeCollections, [bookingType]: true},
				},
				roomId: roomId,
				newBooking : {...this.state.newBooking,	
					array,
					newBookingIsCorrect: false,
					object: {...defaultValues.newBookingObject},
					selectedDayCount: 0
				},
				showBookingDetails}, () => this.createCalendarView());
			//console.log(currentMonth + ' - ');
		}
		else
		{
			this.setState({
				showBookingDetails, 
				currentCalendar: {
				...this.state.currentCalendar
			}}, () => this.createCalendarView());
		}
	}
	createCollectionScope(activeCollections)
	{//define scope for bookingList
		if (activeCollections && this.props.bookingCollections)
		{
			let collectionScope = [];
			for (let [key, value] of Object.entries(activeCollections)) {
			  if (value)
			  	collectionScope = collectionScope.concat(this.props.bookingCollections[key]);
			}
			return collectionScope;
		}
	}
	getSpecificBooking(bookingId, activeCollections)
	{//return booking with specific bookingId to bookingList
		let collectionScope = this.createCollectionScope(activeCollections);
		let booking = collectionScope.filter((booking)=>{return booking.bookingId === bookingId})
		return booking;
	}
	createBookingList(roomId, month, year, activeCollections){
	//definition of BookingList
		let collectionScope = this.createCollectionScope(activeCollections);
		let bookings;
		if (collectionScope && roomId)
		{//filter all values from collectionScope to restricted values
			bookings = collectionScope.filter((booking)=>{
				return booking.roomId === roomId 
				&& ((new Date(booking.checkin).getMonth() <= month && new Date(booking.checkout).getMonth() >= month && new Date(booking.checkin).getFullYear() === year && new Date(booking.checkout).getFullYear() === year) 
				|| ((new Date(booking.checkin).getMonth() === month && new Date(booking.checkin).getFullYear() === year) || (new Date(booking.checkout).getMonth() === month && new Date(booking.checkout).getFullYear() === year) )
				|| (new Date(booking.checkin).getFullYear() < year && new Date(booking.checkout).getMonth() >= month && new Date(booking.checkout).getFullYear() === year)
				|| (new Date(booking.checkin).getMonth() < month && new Date(booking.checkin).getFullYear() === year && new Date(booking.checkout).getFullYear() > year))
			}); 	
		}
		else
		{//empty bookingList
			bookings = [];
		}
		return bookings;
	}
	createCalendarView(){//prepare all view settins for user
		let newBooking = this.state.newBooking.object.checkin.day !== false ? this.state.newBooking.object : false ;
		let bookingList = !this.state.showBookingDetails
			?	this.createBookingList(this.state.roomId, this.state.currentCalendar.currentMonth, this.state.currentCalendar.currentYear, this.state.currentCalendar.activeCollections)
			: this.getSpecificBooking(this.state.showBookingDetails, this.state.currentCalendar.activeCollections);
		let calendar = generateCalendar(this.state.currentCalendar.currentMonth, this.state.currentCalendar.currentYear, bookingList, newBooking);
		this.setState({
			bookingList,
			currentCalendar: {
				...this.state.currentCalendar,
				calendar
			}
		});
	}

	returnSelectedDay(newBooking){//save final effect of user select during newBookingMode
		this.setState({newBooking}, () => this.createCalendarView());
	}
	setLanguage(select) {//lang settings
		const headers = chooseLanguage(select);
		this.setState({
			langSettings: {...this.state.langSettings, select},
			headers
	  });
	}	
	toggleNewBookingMode(){//prepare properties and do toggle
		let newBookingMode = this.state.newBooking.newBookingMode;
		newBookingMode = !newBookingMode;
		let roomId = this.state.roomId ? this.state.roomId : 1;
		let array = defaultValues.newBookingArray;
		array[0].roomId = roomId;
		const newBookingModeObject = {
			currentCalendar: {
				...this.state.currentCalendar,
				activeCollections: this.state.newBooking.newBookingMode === false
				? {...defaultValues.calendarCollections.newBookingMode}
				: {...defaultValues.calendarCollections.calendarMode}
			},
			newBooking: {
				...this.state.newBooking,
				array,
				object: defaultValues.newBookingObject,
				newBookingIsCorrect: false,
				newBookingMode,
				selectedDayCount: 0
			},
			roomId,
			showBookingDetails: false,
			bookingList: [],
		}
		this.setState({...newBookingModeObject}, () => this.createCalendarView());
	}	
	componentDidMount(){
		this.setLanguage('pl');
		if(this.props.showBookingDetails)
		{
			this.changeShowBookingDetailsValue(this.props.showBookingDetails);
		}
		else
		{
			this.createCalendarView();
		}
	}
	
	render() {
		let rooms = [];
		if (this.props.rooms)
		{
			rooms = this.props.rooms.map((room) => {return room.roomId});
		}
		return (
			<div className="container-fluid">
				<div className="row justify-content-center">
			    <div className="col-lg-2 col-md-12 col-sm-12 col-12 order-lg-1 order-md-1 order-sm-1 order-1">
					  <div style={{position: 'sticky', top: 70}}>
						{!this.state.newBooking.newBookingMode 
							&& <BookingTypeSelect 
								bookingTypeColors={this.state.bookingTypeColors} //colections colors
								calendarCollections={this.state.currentCalendar.activeCollections} //info about user select collections
								changeCalendarCollection={this.changeCalendarCollection} //function toggle active collection
								header={this.state.headers.collectionHeader} //header
								confirmed={this.state.headers.confirmed}  //header
								unconfirmed={this.state.headers.unconfirmed}  //header
								canceled={this.state.headers.canceled}  //header
							/>}
					    <BookingRoomSelect 
						    selectRoom={this.changeCalendarRoom} //function changing room on user select
						    header={this.state.headers.roomHeader}  //header
						    rooms={rooms} //[] of available rooms
						    roomId={this.state.roomId} //info about room selected by user
						  />						
						<RoomPreview gallery={this.props.rooms[this.state.roomId-1].photos} folderName={this.state.roomId}/>
				  	</div>
				  </div>
					<div className="col-lg-4 col-md-12 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-2 order-2">
					  <Calendar 
					  	addBookingToUnconfirmedCollection={this.addBookingToUnconfirmedCollection} //function save new booking
							bookingTypeColors={this.state.bookingTypeColors} //colors of booking collections
							calendarSkipTo={this.calendarSkipTo} //function for navigation in calendar
							changeShowBookingDetailsValue={this.changeShowBookingDetailsValue} //function to toggle info about specific booking
							currentCalendar={this.state.currentCalendar} //object with all data about calendar
							createBookingList={this.createBookingList}
							headers={this.state.headers}  //header
							newBookingMode={this.state.newBooking.newBookingMode} //state of new booking mode
							newBooking={this.state.newBooking}
							roomId={this.state.roomId}
							returnSelectedDay={this.returnSelectedDay} //fuction add info about new booking
			      />
			    </div>
					<div className="col-lg-3 col-md-12 col-sm-12 col-12 order-lg-3 order-md-3 order-sm-3 order-3" style={{backgroudColor:'#FF0000'}}>
						<BookingsList
						toggleNewBookingMode={this.toggleNewBookingMode} 
						addBookingToUnconfirmedCollection={this.addBookingToUnconfirmedCollection} //function save new booking
						bookingList={ //when newBookingMode is on, in list show info only about new Booking
							this.state.newBooking.newBookingMode 
							? this.state.newBooking.array
							: this.state.bookingList
						} 
						bookingTypeColors={this.state.bookingTypeColors} //colections colors
						changeBookingCollection={this.props.changeBookingCollection} //function for confirm or cancel booking
						createCalendarView={this.createCalendarView}
						header={this.state.headers.collectionHeader} //header
						confirmBookingButtonText={this.state.headers.confirmBookingButton} //header
						cancelBookingButtonText={this.state.headers.cancelBookingButton} //header
						monthHeaders={this.state.headers.monthHeaders}
						newBookingHeader={//header on button, switch between commands during new Booking
							!this.state.newBooking.object.checkin.day && !this.state.newBooking.object.checkout.day && !this.state.newBooking.newBookingMode
							? this.state.headers.newBookingHeader //output: new booking
							: !this.state.newBooking.object.checkin.day && !this.state.newBooking.object.checkout.day && this.state.newBooking.newBookingMode
								? this.state.headers.selectCheckInHeader //output: select checkin
								: this.state.newBooking.object.checkin.day && !this.state.newBooking.object.checkout.day && this.state.newBooking.newBookingMode
									? this.state.headers.selectCheckOutHeader //output: select checkout
									: this.state.headers.addBookingHeader //output: add booking
							}
						newBookingIsCorrect={this.state.newBooking.newBookingIsCorrect}
						newBookingMode={this.state.newBooking.newBookingMode}
						newHeader={this.state.headers.newHeader}
						showBookingDetails={this.state.showBookingDetails}/>
					</div>
				</div>
			</div>
		);
	}
}
BookingsCalendar.propTypes =
{
  addToUnconfirmedCollection:  PropTypes.func,
  bookingCollections: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),
  changeBookingCollection: PropTypes.func,
	rooms: PropTypes.array,
}


export default BookingsCalendar;