import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class RoomOptionsNavigation extends Component {
	constructor() {
		super();
		this.state={
			navigationBar: [
				{name: 'PriceSettings', caption: 'Cennik'},
				{name: 'RoomBasicSettings', caption: 'Podstawowe informacje'},
				{name: 'RoomEquipmentSettings', caption: 'Wyposażenie'},
				{name: 'RoomGallerySettings', caption: 'Galeria pokoju'},
			]
		}
		this.forwardNavigation = this.forwardNavigation.bind(this);
	}
	forwardNavigation(event){
		const navigateDirection = event.target.name;
		this.props.navigationService(navigateDirection);
	}
	render() {
		return (
			<div className='room-settings-navigation'>
				<ul>
					{this.state.navigationBar.map((navigateDirection, idx)=>(
						<li key={idx}>
						<button className={'btn btn-outline-'+this.props.css+' col-2'} name={navigateDirection.name} onClick={this.forwardNavigation}>{navigateDirection.caption}</button>
						</li>
					))}

				</ul>
			</div>
		)
	}
}

export default RoomOptionsNavigation;