import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class UserSettingsNavigation extends Component {
	constructor() {
		super();
		this.state={
			navigationBar: [
				{name: 'UserAccount', caption: 'Konto'},
				{name: 'AccountActivity', caption: 'Aktywności'},
				{name: 'EmailNotificationSettings', caption: 'Powiadomienia'},
				{name: 'Stats', caption: 'Statystyki'},
				{name: 'LCanal', caption: 'Wiadomości serwisowe i kontakt z administracją'},
				{name: 'Bills', caption: 'Opłaty i faktury'},
			]
		}
		this.forwardNavigation = this.forwardNavigation.bind(this);
	}
	forwardNavigation(event){
		const navigateDirection = event.target.name;
		this.props.navigationService(navigateDirection);
	}
	render() {
		return (
			<div className='user-settings-navigation'>
				<ul>
					{this.state.navigationBar.map((navigateDirection, idx)=>(
						<li key={idx}>
						<button className='btn btn-outline-dark btn-block' name={navigateDirection.name} onClick={this.forwardNavigation}>{navigateDirection.caption}</button>
						</li>
					))}

				</ul>
			</div>
		)
	}
}

export default UserSettingsNavigation;