import React, { Component } from 'react';
import Calendar from './Calendar';
import Datepicker from './Datepicker';
import NewBookingConfirmForm from './NewBookingConfirmForm';


class BookingForm extends Component {
	constructor () {
		super();
		this.state = {
		newBooking :{
			startDay: null,
			endDay: null,
			startMonth: null,
			endMonth: null,
			startYear: null,
			endYear: null,
			roomId: null,
			archived: false,
			deleted: false,
			confirmed: true,
			canceled: false,
			guestsQuantity: null,
			guestsChildQuantity: null,
			guestsCar: false,
			contactFirstName: null,
			contactSurname: null,
			contactEmail: null,
			contactPhone: null,
			extraNote: null,
			},
			bookingId: null,
			isCorrect: null,
			datePickerFor: {
  			start: false,
  			end: false,
  		},
      roomButtonStyle: {
      	select: "btn btn-",
      	unselect: "btn btn-outline-",
      }
		}
    this.openDatepicker = this.openDatepicker.bind(this);
    this.closeDatepicker = this.closeDatepicker.bind(this);
    this.returnValueFromDatepicker = this.returnValueFromDatepicker.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.selectRoom = this.selectRoom.bind(this);
		this.saveDataToDB = this.saveDataToDB.bind(this);
		this.confirmBookingId = this.confirmBookingId.bind(this);
		this.createNewBooking = this.createNewBooking.bind(this);
		this.confirmBooking = this.confirmBooking.bind(this);
	}

	createNewBooking (){
		let newBooking = {
			startDay: null,
			endDay: null,
			startMonth: null,
			endMonth: null,
			startYear: null,
			endYear: null,
			roomId: null,
			archived: false,
			deleted: false,
			confirmed: true,
			canceled: false,
			guestsQuantity: null,
			guestsChildQuantity: null,
			guestsCar: false,
			contactFirstName: null,
			contactSurname: null,
			contactEmail: null,
			contactPhone: null,
			extraNote: null,
		};
		this.setState({
			newBooking,
			bookingId: null
		})
	}
	

  selectRoom (event){    
  	const name = event.target.name;
    const value = parseInt(event.target.value);
    this.setState({
    	newBooking: {
      	...this.state.newBooking,
        [name]: value
    	}
    });
  }

  confirmBooking (event)	{
    const value = event.target.checked;

    this.setState({
    	newBooking: {
    		...this.state.newBooking,
      	confirmed: value
    	},   	
    });
  }

  handleInputChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.id;

    this.setState({
     newBooking: {
  			...this.state.newBooking,
  			[name] : value},
    });
  }

  confirmBookingId(value) {	
  	this.setState({
  			bookingId : value,
  	});
  }

  openDatepicker (event){
  	const value = event.target.id;
  	this.setState({
		  newBooking: {
				...this.state.newBooking,
			},
			datePickerFor: {
				...this.state.datePickerFor,
				[value]: true,
			}
  	});
  }

  closeDatepicker (event){
  	const value = event.target.id;
  	const object = {...this.state.datePickerFor,
				[value]: false,}
		console.log(object);
  	this.setState({
			datePickerFor: {
				...this.state.datePickerFor,
				[value]: false,
			}
  	});
  }


  returnValueFromDatepicker (dateArray, type)
  {
  	console.log(dateArray);
  	
  	let newBooking = {
  		...this.state.newBooking,
  		[type+"Day"] : isNaN(dateArray[0]) ? 0 : ('0' + dateArray[0]).slice(-2),
  		[type+"Month"] : isNaN(dateArray[1]) ? 0 : ('0' + dateArray[1]).slice(-2),
  		[type+"Year"] : isNaN(dateArray[2]) ? 0 : dateArray[2],
  	}
  	this.setState({
  		newBooking,
  		datePickerFor: {
				...this.state.datePickerFor,
				[type]: false,
			}
  	}, () => {
		if (this.state.newBooking.startDay && this.state.newBooking.endDay && this.state.newBooking.startMonth && this.state.newBooking.endMonth && this.state.newBooking.startYear && this.state.newBooking.endYear)
		{     
			if (this.state.newBooking.startDay >= this.state.newBooking.endDay && this.state.newBooking.startMonth >= this.state.newBooking.endMonth && this.state.newBooking.startYear >= this.state.newBooking.endYear)
	     {
	     	this.setState({
	     		isCorrect: false
	     	})
	     }
	     else
	     {
	     	this.setState({
	     		isCorrect: true
	     	})
			}
		}
    });
 	}

	saveDataToDB() {
		const object = {
			...this.state.newBooking
		}
		console.log("saveDataToDB");
		console.log(object);
		if (object.startDay !== null)
		{
			if (object.startMonth !== null)
			{
				if (object.startYear !== null)
				{
					if (object.endDay !== null)
					{
						if (object.endMonth !== null)
						{
							if (object.endYear !== null)
							{
								if (object.roomId !== null)
								{
								  fetch('api/newbooking', {
							      method: 'POST',
							      headers: {
							        'Accept': 'application/json',
							        'Content-Type': 'application/json'
							      },
							      body: JSON.stringify(object)
							    }).then((res) => res.json())     
							    .then((json) => this.confirmBookingId(json.bookingId))     
								}
							}
						}
					}
				}
			}
		}
   console.log(object);
   //const data = {login: this.state.formControls.login.value, password: this.state.formControls.password.value, booking: {days: this.state.newBooking.days}}
    //event.preventDefault();    
  }

	render() {
		const endStyle = {
			position: "absolute",
			zIndex: this.state.datePickerFor.end !== true ? 0 : 4,
			minHeight: "100px",
			minWidth: "100px",
			overflow: "hidden",
			display: this.state.datePickerFor.end !== true ? "none": "inline",
		}
		const startStyle = {
			position: "absolute",
			zIndex: this.state.datePickerFor.start !== true ? 0 : 4,
			minHeight: "100px",
			minWidth: "100px",
			overflow: "hidden",
			display: this.state.datePickerFor.start !== true ? "none": "inline",
		}

		const startPlaceholder = (this.state.newBooking.startDay !== null ? this.state.newBooking.startDay: "--") + "/" + (this.state.newBooking.startMonth !== null ? this.state.newBooking.startMonth: "--") + "/" + (this.state.newBooking.startYear !== null ? this.state.newBooking.startYear: "----");
		const endPlaceholder = (this.state.newBooking.endDay !== null ? this.state.newBooking.endDay: "--") + "/" + (this.state.newBooking.endMonth !== null ? this.state.newBooking.endMonth: "--") + "/" + (this.state.newBooking.endYear !== null ? this.state.newBooking.endYear: "----");

		return (
				 <div className="card" style={{zIndex: 1}}>
			    	<div className="card-header" style={{height: "100px"}} id="bookingForm">
			    	<section className="wrappingContainer">
				  		<div id="leftSide">
					  		{!this.state.bookingId &&
								<div className="form-inline">
									<div className="form-group">
										<label htmlFor="start">Check-In:</label>
										<div >						  
										  <input value={startPlaceholder} onFocus={this.openDatepicker} id="start" className={this.state.isCorrect === false ? "form-control is-invalid ": "form-control"} name="start" type="text" pattern="\d{2}/\d{2}/\d{4}" required/>
									  	{this.state.isCorrect === false ? <div className="invalid-feedback">Sprawdź daty</div> : ""}
									  	<div style={startStyle}>
									  		{this.state.datePickerFor.start === true && <Datepicker closeDatepicker={this.closeDatepicker} bookings={this.props.userData.bookings} roomId={this.state.newBooking.roomId} returnValueFromDatepicker={this.returnValueFromDatepicker} type="start"/>}
									  	</div>
									  </div>
										<label htmlFor="end">Check-Out:</label>
										<div>						  
										  <input value={endPlaceholder} id="end" onFocus={this.openDatepicker} className={this.state.isCorrect === false ? "form-control is-invalid ": "form-control"} name="end" type="text" pattern="\d{2}/\d{2}/\d{4}" required/>
									  	{this.state.isCorrect === false ? <div className="invalid-feedback">Sprawdź daty</div> : ""}
									  	<div style={endStyle}>
									  		{this.state.datePickerFor.end === true && <Datepicker closeDatepicker={this.closeDatepicker} bookings={this.props.userData.bookings} roomId={this.state.newBooking.roomId} returnValueFromDatepicker={this.returnValueFromDatepicker} type="end"/>}
									  	</div>
									  </div>					  
								    <div className="btn-group mr-2" role="group" aria-label="Pokój">
								    {this.props.userData.rooms.map((room, index)=>(
							    		<button key={index} type="button" name="roomId" id={room.roomId} value={room.roomId} style={{height: "50px", width: "50px", marginLeft: "5"}} onClick={this.selectRoom} className={this.state.newBooking.roomId === room.roomId ? this.state.roomButtonStyle.select + room.cssColor : this.state.roomButtonStyle.unselect+ room.cssColor}></button>
				          			))}						
								 		</div>
								 		<div style={{position: "relative"}}>
									 		<input style={{position: "absolute", left: "45%"}} type="checkbox" name="confirmed" checked={this.state.newBooking.confirmed} onChange={this.confirmBooking} className="btn btn-dark"/><br/>
									  	<label htmlFor="confirmed">zaliczka</label>
									  </div>
									  <div style={{marginLeft: "20px"}}>
									  	<input type="button" value="Dodaj" onClick={this.saveDataToDB} className="btn btn-dark"/>
								  	</div>
								  </div>
								</div>
							}
							{this.state.bookingId && 
								<div>
								Rezerwacja dodana. Id rezerwacji: {this.state.bookingId}
								<button style={{marginLeft: "30px"}} className="btn btn-outline-dark" onClick={this.createNewBooking}>OK</button>
								</div>
							}
							</div>
				  		{!this.state.bookingId && <div id="rightSide">
					  		<a data-toggle="collapse" href={"#newbooking"} role="button" aria-expanded="false" aria-controls={"newbooking"}>
			  					<p style={{position: "absolute", top: "60px", right: "10px"}}>więcej...</p>
			  				</a>
				  		</div>
				  		}
						</section>				  		
				  	</div>
			    {!this.state.bookingId && 
			    <div className="collapse" id="newbooking">
				    <div className="card-body">
				    	<div className="card-title">
				    		<h5>Dodatkowe informacje</h5>
				    	</div>
				    	<div className="card-text">
				    		<div className="form-group row">
							    <label className="col-sm-4 col-form-label" htmlFor="guestsQuantity">Liczba gości</label>
							    <div className="col-sm-8">
							      <input type="number" className="form-control" max="4" min="1" id="guestsQuantity" placeholder="Goście" onChange={this.handleInputChange}/>
							    </div>
							  </div>
							  <div className="form-group row">
							    <label className="col-sm-4 col-form-label" htmlFor="contactFirstName">Imię</label>
							    <div className="col-sm-8">
							      <input type="text" className="form-control" id="contactFirstName" placeholder="Imię" onChange={this.handleInputChange}/>
							    </div>
							  </div>
							  <div className="form-group row">
							    <label className="col-sm-4 col-form-label" htmlFor="contactSurname">Nazwisko</label>
							    <div className="col-sm-8">
							      <input type="text" className="form-control" id="contactSurname" placeholder="Nazwisko" onChange={this.handleInputChange}/>
							    </div>
							  </div>
							  <div className="form-group row">
							    <label htmlFor="contactEmail" className="col-sm-4 col-form-label">E-mail</label>
							    <div className="col-sm-8">
							      <input type="email" className="form-control" id="contactEmail" placeholder="adres e-mail" onChange={this.handleInputChange}/>
							    </div>
							  </div>
							  <div className="form-group row">
							    <label htmlFor="contactPhone" className="col-sm-4 col-form-label">Telefon</label>
							    <div className="col-sm-8">
							      <input type="email" className="form-control" id="contactPhone" placeholder="nr telefonu" onChange={this.handleInputChange}/>
							    </div>
							  </div>
							  <div className="form-group row">
							    <label htmlFor="extraNote" className="col-sm-4 col-form-label">Dodatkowa notatka</label>
							    <div className="col-sm-8">
							      <textarea className="form-control" id="extraNote" placeholder="Notatka..." onChange={this.handleInputChange}/>
							    </div>
							  </div>
				    	</div>
				    </div>
			    </div>}
			</div>
		)
	}
}


export default BookingForm;