import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {bookingTypeColors} from './booking-calendar-utility/colorsSettings';
import Calendar from './calendar-component/Calendar';
import {chooseLanguage} from './booking-calendar-utility/langSettings';
import {defaultValues} from './booking-calendar-utility/defaultValues';
import {generateCalendar} from './calendar-component/CalendarUtility';


class BookingsCalendar extends Component {
	constructor (props) {
		super(props);
		const today = new Date ();
		this.state = {
			bookingTypeColors, //default colors of 3 booking collections
			headers: chooseLanguage('pl'), //prepare headers default for polish
			today: {
				day: today.getDate(), 
				month: today.getMonth(), 
				year: today.getFullYear()
			},
			currentCalendar: { //all information used in component calendar
				activeCollections: {...defaultValues.calendarCollections.newBookingMode}, //defult settings for collections
				calendar: {}, //this object contain 3 arrays daysCalendar, idsCalendar, typesCalendar all used as main data in DayCalendar
				currentMonth: today.getMonth(), //current viewed month, at begging for this month
				currentYear: today.getFullYear(), //current viewed year, at begging for this month
				selectableYears: [today.getFullYear(), today.getFullYear()+1], //which years are possible to select
			},
			roomId: false, //selected room
			bookingList: [], //list for component BookingList
			newBooking: {//default new booking definition and properties
				array : [{ //new booking as array used in booking list
					bookingId: 'new',
					roomId: this.roomId ? this.roomId : 1,
					bookingType: 'unconfirmed',
					checkin: false,
					checkout: false,
				}],
				object: defaultValues.newBookingObject,	//new booking as object for calendar usage
				newBookingIsCorrect: false,
				newBookingMode: true,
				selectedDayCount: 0,	//coreleted to newBooking, 1st and 2nd click on calendar day while new booking is added
			},
			langSettings: {select: "pl", available: ['de', 'gb', 'no', 'pl']},	
			showBookingDetails: false, //property for bookingList
		}
		this.addBookingToUnconfirmedCollection = this.addBookingToUnconfirmedCollection.bind(this);
		this.calendarSkipTo = this.calendarSkipTo.bind(this)
		this.createCollectionScope = this.createCollectionScope.bind(this);
		this.createBookingList = this.createBookingList.bind(this);
		this.createCalendarView = this.createCalendarView.bind(this);
		this.returnSelectedDay = this.returnSelectedDay.bind(this);
		this.updateState = this.updateState.bind(this);
	}	

	updateState (bookingId)
	{//correct bookingId inform about process is done reset all values to default and show details about new booking
		let object = defaultValues.newBookingObject;
		let array = defaultValues.newBookingArray;
		array[0].roomId = this.roomId ? this.roomId : 1;
		this.setState({
			currentCalendar:				
			{
				...this.state.currentCalendar,
				activeCollections: {...defaultValues.calendarCollections.calendarMode}
			},
			newBooking: {
				array,
				newBookingIsCorrect: false,
				newBookingMode: false,
				object, 
			}
		}, () => this.createCalendarView())
	}

	addBookingToUnconfirmedCollection() {//comunicate with DemoBookingCalendarContainer
		const newBooking = {...this.state.newBooking.object, roomId: this.state.roomId};

		let bookingId = this.state.newBooking.newBookingIsCorrect ? this.props.addToUnconfirmedCollection(newBooking, this.updateState) : undefined; //DemoBookingCalendarContainer after register booking return bookingId
	}
	calendarSkipTo(month, year) {		
		this.setState({
			currentCalendar: {
				...this.state.currentCalendar,
				currentYear : year,
	    	currentMonth : month
			},
	    showBookingDetails : false
	  }, () => this.createCalendarView() );
	}

	createCollectionScope(activeCollections)
	{//define scope for bookingList
		if (activeCollections && this.props.bookingCollections)
		{
			let collectionScope = [];
			for (let [key, value] of Object.entries(activeCollections)) {
			  if (value)
			  	collectionScope = collectionScope.concat(this.props.bookingCollections[key]);
			}
			return collectionScope;
		}
	}
	createBookingList(roomId, month, year, activeCollections){
	//definition of BookingList
		let collectionScope = this.createCollectionScope(activeCollections);
		let bookings;
		if (collectionScope && roomId)
		{//filter all values from collectionScope to restricted values
			bookings = collectionScope.filter((booking)=>{
				return booking.roomId === roomId 
				&& ((new Date(booking.checkin).getMonth() <= month && new Date(booking.checkout).getMonth() >= month && new Date(booking.checkin).getFullYear() === year && new Date(booking.checkout).getFullYear() === year) 
				|| ((new Date(booking.checkin).getMonth() === month && new Date(booking.checkin).getFullYear() === year) || (new Date(booking.checkout).getMonth() === month && new Date(booking.checkout).getFullYear() === year) )
				|| (new Date(booking.checkin).getFullYear() < year && new Date(booking.checkout).getMonth() >= month && new Date(booking.checkout).getFullYear() === year)
				|| (new Date(booking.checkin).getMonth() < month && new Date(booking.checkin).getFullYear() === year && new Date(booking.checkout).getFullYear() > year))
			}); 	
		}
		else
		{//empty bookingList
			bookings = [];
		}
		return bookings;
	}
	createCalendarView(){//prepare all view settins for user
		let newBooking = this.state.newBooking.object.checkin.day !== false ? this.state.newBooking.object : false ;
		let bookingList = this.createBookingList(this.props.rooms[0], this.state.currentCalendar.currentMonth, this.state.currentCalendar.currentYear, this.state.currentCalendar.activeCollections);
		let calendar = generateCalendar(this.state.currentCalendar.currentMonth, this.state.currentCalendar.currentYear, bookingList, newBooking);
		if (this.props.getCurrentMonth)
		{
			this.props.getCurrentMonth(this.state.currentCalendar.currentMonth);
		}
		this.setState({
			bookingList,
			currentCalendar: {
				...this.state.currentCalendar,
				calendar
			}
		});
	}

	returnSelectedDay(newBooking){//save final effect of user select during newBookingMode
		this.setState({newBooking}, () => this.createCalendarView());
	}


	componentDidMount(){
		if (this.props.newBooking)
		{
			this.returnSelectedDay(this.props.newBooking);
		}
		else
		{	
		this.createCalendarView();
		}
  }
	
	render() {

		const steps = [
			{
				checkin: 'wybierz datę przyjazdu',
				checkout: 'wybierz datę wyjazdu',
				letsBook: 'rezerwuj'
			},
			{
				accept: 'przeczytaj regulamin i akceptuj aby kontynuować'
			},
			{
				fillForm: 'Uzupełnij formularz'
			}]
		const stepHeader = this.state.newBooking.object.checkin.day === false ? 'Krok 1 - '+ steps[0].checkin : 
			this.state.newBooking.object.checkout.day === false ? 'Krok 1 - '+ steps[0].checkout :
			'Krok 1 - '+ steps[0].letsBook;
		return (
		  <Calendar 
		  	getCalendarSelectedValues={this.props.getCalendarSelectedValues}//function save new booking
				bookButton={this.props.bookButton}
				bookingTypeColors={this.state.bookingTypeColors} //colors of booking collections
				calendarSkipTo={this.calendarSkipTo} //function for navigation in calendar
				changeShowBookingDetailsValue={this.changeShowBookingDetailsValue} //function to toggle info about specific booking
				currentCalendar={this.state.currentCalendar} //object with all data about calendar
				createBookingList={this.createBookingList}
				headers={this.state.headers}  //header
				newBookingMode={this.state.newBooking.newBookingMode} //state of new booking mode
				newBooking={this.state.newBooking}
				roomId={this.props.rooms[0]}
				returnSelectedDay={this.returnSelectedDay} //fuction add info about new booking
      	stepHeader={stepHeader}
      />
		);
	}
}
BookingsCalendar.propTypes =
{
	newBooking: PropTypes.object,
  getCalendarSelectedValues:  PropTypes.func,
  bookingCollections: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.object)),

}


export default BookingsCalendar;