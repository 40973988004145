import React, { Component } from 'react';
import GuestsFields from './GuestsFields';
import DateFields from './DateFields';
import ContactFields from './../contact_page/ContactFields';
import DatePicker from './../../universal/datepicker/DatePicker';
import './../../../stylesheets/contact_page.css';

class BookingForm extends Component {
		constructor (props) {
		super(props);
		this.state = {
  		contactFirstName: null,
			contactSurname: null,
			contactEmail: null,
			contactPhone: null,
			contactTopic: 1,
			contactMessageContent: null,
			contactRodoLicense: null,
			contactBooking: {
				roomId: null,
				checkin: {
					day: false,
					month: false,
					year: false,
				},
				checkout: {
					day: false,
					month: false,
					year: false,					
				},
				guestsQuantity: null,
				guestsChildQuantity: null,
				guestsCar: false,
			},

		/*	roomButtonStyle: {
      	select: "btn btn-",
      	unselect: "btn btn-outline-",
      }*/

		}
		//this.selectRoom = this.selectRoom.bind(this);
    this.saveValueToState = this.saveValueToState.bind(this);
    this.saveContactValueToState = this.saveContactValueToState.bind(this);
    this.sendBookingForm = this.sendBookingForm.bind(this);
	}

  saveValueToState(object) {
    this.setState({
     contactBooking: {
			...this.state.contactBooking,
			...object},
    });
  }
  saveContactValueToState(object) {
    this.setState({...object});
  }
  handleSubmit(event){

  }

 /* selectRoom (event){    
  	const name = event.target.name;
    const value = parseInt(event.target.value);
    this.setState({
    	contactBooking: {
      	...this.state.contactBooking,
        [name]: value,
        startMonth: null,
				startDay: null,
				startYear: null,
				endMonth: null, 
				endDay: null, 
				endYear: null,
  			},
				datesSetCorrect: false,
				datePickerFor: {
	  			start: false,
	  			end: false,
    		}
    });
  }*/
	sendBookingForm() {
   //const data = {login: this.state.formControls.login.value, password: this.state.formControls.password.value, booking: {days: this.state.contactBooking.days}}
    //event.preventDefault();
    const data = {...this.state};
    //console.log(data);
    fetch('https://164.132.113.224/api/scf', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
    .then((res) => res.json())      
    .then((json) => this.props.handleMessageId(json.messageId))
    .then(this.props.setBookingFormConfirmView())    
  }


 	componentDidMount(){
 		if (this.props.roomId)
 		{
 			this.setState({
 				contactBooking:
 				{
	 				...this.state.contactBooking,
	 				roomId: this.props.roomId,
	 				...this.props.newBooking,
 				}
 			})
 		}
 	}

	render() {


		const confirmButton = <input type="submit" disabled={!this.state.contactRodoLicense} className={this.props.rooms ? 'btn col-6 btn-'+ this.props.rooms[0].cssColor : 'btn col-6 btn-secondary'} id="confirmButton" value="Wyślij"/>;
	  const rooms = <div className="form-group row">
			<label className="col-sm-4 col-form-label" htmlFor="rooms">Pokój</label>
	    <div className="col-sm-8" role="group" id="rooms">
		    {this.props.rooms && this.props.rooms.map((room, index)=>(
	    		<button key={index} type="button" name="roomId" id={room.roomId} value={room.roomId} style={{height: "50px", width: "50px", marginLeft: "5"}} className={'btn btn-' + room.cssColor}></button>
      			))}						
	 		</div>
 		</div>;
 		const secondStep = <div>
	 		<DateFields 
	 			roomId={this.state.contactBooking.roomId} 
	 			bookings={this.props.bookings}
	 			contactBooking={this.state.contactBooking}
	 			saveValueToState={this.saveValueToState}  />
	 		<GuestsFields 
	 			guestsQuantity={this.state.contactBooking.guestsQuantity} 
	 			guestsChildQuantity={this.state.contactBooking.guestsChildQuantity} 
	 			saveValueToState={this.saveValueToState} 
	 			capacity={this.props.rooms[0].capacity}/>
 		</div>;
		return (
			<form className='form bg-white needs-validation' noValidate onSubmit={this.sendBookingForm}>
				<p>Krok 3 - Formularz rezerwacji</p><button id={this.props.type} className="btn btn-outline-danger" style={{position: 'absolute', right: '25px', top: '5px'}} onClick={this.props.newBookingModeSwitch}>X</button>
				<fieldset className="border p-2">
							{rooms}
							{this.state.contactBooking.roomId && secondStep}
							{this.state.contactBooking.guestsQuantity && <ContactFields saveContactValueToState={this.saveContactValueToState} contactTopic={this.state.contactTopic}/>}
					 		{confirmButton}
				</fieldset>
		</form>)}
}
export default BookingForm;