import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Gallery from './../../../universal/gallery/Gallery';

class RoomImages extends Component {
	render() {
		return (
			<Gallery 
				photos={this.props.photos} 
				title={this.props.title}
				folderName={this.props.roomId}
			/>
		);
	}
}

RoomImages.propTypes ={
	photos: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	roomId: PropTypes.number
}

export default RoomImages;

