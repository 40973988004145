//strona opcji pokoju
//zawiera Opcje Cennika, Galerii, Opisu, Wyposażenia

import React, { Component } from 'react';
import RoomSelector from './../navigation/RoomSelector';
import RoomOptions from './RoomOptions';
import RoomPreview from './../navigation/RoomPreview';
import 'bootstrap/dist/css/bootstrap.css';


class RoomSettings extends Component {
    constructor(){
        super();
        this.state = {
            selected: 1,
        }
        this.selectRoom = this.selectRoom.bind(this);
    }
    selectRoom(roomId){
        this.setState({
            selected: roomId
        })
    }
	render() {
    let rooms = this.props.rooms ? this.props.rooms.map((room) => {return room.roomId}) : [];
		return (
			<div className='row'>
                <div className='col-2 offset-1'>
                    <RoomSelector selectRoom={this.selectRoom} //function changing room on user select
                        rooms={rooms} //[] of available rooms
                        selected={this.state.selected} //info about room selected by user
                    />
                    <RoomPreview gallery={this.props.rooms[this.state.selected-1].photos} folderName={this.state.selected}/>
                </div>
                <div className='col-8'>
                    {this.state.selected && <RoomOptions changePrice={this.props.changePrice} room={this.props.rooms[this.state.selected-1]} />}
                
                </div>
            </div>
		)
	}
}

export default RoomSettings;