import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './../../../stylesheets/Admin.css';

class AccountActivity extends Component {
    constructor() {
      super();
      this.state = {
        headers: ["IP", "Data Zalogowania"],
      }

    };

    localizeDateStr(date_to_convert_str) 
    {
      var date_to_convert = new Date(date_to_convert_str);
      var dateArray = [''+date_to_convert.getDate(), date_to_convert.getMonth()+1+'', ''+date_to_convert.getFullYear(), ''+date_to_convert.getHours(), ''+date_to_convert.getMinutes(), ''+date_to_convert.getSeconds()];
      dateArray = dateArray.map((dateElement)=>{
        if(dateElement.length<2)
        {
          return '0'+dateElement;
        }
        else
        {
          return dateElement;
        }
      });
      var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2] + " " + dateArray[3] + ":" + dateArray[4] + ":" +dateArray[5];
      return dateString; 	
    }
	

	render() {
		function filterOnlyFromContactForm(message)
		{
			return message.contactTopic === 1;
		}
    const activities = this.props.activity ? this.props.activity : [];
    
		return (
        <div className='activity-wrapper'>
          <div className='activity-header'>
            <div>
              <p>{this.state.headers[0]}</p>
            </div>
            <div>
              <p>{this.state.headers[1]}</p>
            </div>
          </div>
          {activities.map((activity, index)=>{return (
              <div className='activity' key={index}>
                <div>
                  <p>{activity.ip}</p>
                </div>
                <div>
                  <p>{this.localizeDateStr(activity.time)}</p>
                </div>
              </div>
            )}
          )}
        </div>
		)
	}
}

export default AccountActivity;