import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';

class Messages extends Component {
	constructor() {
    super();
    this.state = {
    	topics: ["Zapytanie o wstępną rezerwację", "Wynajem długoterminowy", "Oferta reklamowa lub współpracy"],
    }
    this.changeDateToLocalDateTime = this.changeDateToLocalDateTime.bind(this);
  };

	componentDidMount(){
    this.props.downloadMessagesFromDB();
  };

  changeDateToLocalDateTime(value){
  	var date = new Date(value);
  	console.log("value " + value);
  	console.log("Data przed zmianą: " + date);
  	var localTime = date.toLocaleDateTimeString();
  	console.log("Data po zmianach: " + localTime);
		return localTime;
  }

  localizeDateStr(date_to_convert_str) 
  {
   var date_to_convert = new Date(date_to_convert_str);
   var local_date = new Date();
   date_to_convert.setHours(date_to_convert.getHours()+local_date.getTimezoneOffset());
   var dateArray = [date_to_convert.getDate(), date_to_convert.getMonth(), date_to_convert.getFullYear(), date_to_convert.getHours(), date_to_convert.getMinutes(), date_to_convert.getSeconds()];
   var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2] + " " + dateArray[3] + ":" + dateArray[4] + ":" +dateArray[5];
   return dateString; 

 	}

	render() {
		function filterOnlyFromContactForm(message)
		{
			return message.contactTopic = '2';
		}
		const messages = this.props.messages ? this.props.messages.filter(filterOnlyFromContactForm) : [];
		return (
			<div>
				<h3>Wiadomości z formularza kontaktowego</h3>
				{this.props.messages && messages.map((message, index)=>(
			    <div className="card" key={index}>
			    	<a 
			    	data-toggle="collapse" 
			    	href={"#messagesFullMessage"+message.contactMessageId} 
			    	role="button" 
			    	onClick={this.props.setMessageAsRead} 
			    	aria-expanded="false" aria-controls={"messagesFullMessage"+message.contactMessageId}>
					  	<div className="card-header" id={message.contactMessageId}  style={{ fontWeight: message.contactUnread === true ? '700': '400'}}>
					  		{message.contactFirstName}{message.contactSurname ? " "+message.contactSurname+" - " : " - "}{this.state.topics[message.contactTopic-1]}{" - "}{this.localizeDateStr(message.contactDate)}
					  	</div>
				  	</a>
				    <div className="collapse" id={"messagesFullMessage"+message.contactMessageId}>
					    <div className="card-body">
					    	<div className="card-title">
					    		<h5>{"Temat: "+ this.state.topics[message.contactTopic-1]}</h5>
					    	</div>
					    	<div className="card-text">
					    		{"Wiadomość: "}<br/>{message.contactMessageContent}
					    		<br/><br/>
					    		{message.contactFirstName}{message.contactSurname ? " "+ message.contactSurname : " "}
					    	</div>
					    	<button className="btn btn-primary" onClick={this.props.setContactViewToMain}>Odpowiedz</button>
					    	{"   "}<button className="btn btn-success" onClick={this.props.setContactViewToMain}>Rezerwuj</button>
					    	{"   "}<button className="btn btn-danger" onClick={this.props.setContactViewToMain}>Usuń</button>
					    </div>
				    </div>
				  </div>
		    ))}
		  </div>
		)
	}
}

export default Messages;