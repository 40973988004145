import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../../../stylesheets/customer_relation_page.css';

class CookiesPolicy extends Component {
	render() {
		return (
			<div className="cookies-policy">	
	    		<p>
	    			Pliki cookies stanowią dane informatyczne, w postaci tekstowej, które przechowywane są w urządzeniu
	    			końcowym Użytkownika Witryny i przeznaczone są do korzystania ze strony kwatery-laskowscy.pl
	    		</p>
	    		<p>
				Serwis nie zbiera w sposób automatyczny informacji, z wyjątkiem informacji zawartych w plikach cookies.
				Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Witryny pliki cookies oraz uzyskującym do nich dostęp jest Dariusz Laskowski z siedzibą pod adresem Gryfa Pomorskiego 14/2, Międzyzdroje.
				</p>
				<p>
				Cookies zawierają unikalny numer, nazwę strony internetowej, z której pochodzą oraz czas przechowywania ich na urządzeniu końcowym.
				Pliki cookies wykorzystywane są w celu:
				<ul>
					<li>utrzymania sesji Użytkownika;</li>
					<li>dostosowania zawartości Witryny do preferencji Użytkownika;</li>
					<li>optymalizacji korzystania ze stron internetowych; </li>
					<li>tworzenia statystyk, wykorzystywanych do ulepszanie struktury i zawartości witryny internetowej;</li>
				</ul>
				</p>
				<p>
				W kwatery-laskowscy.pl stosowane są dwa zasadnicze rodzaje plików cookies:
					<ul>
						<li><strong>session cookies</strong> - pliki tymczasowe, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia przeglądarki internetowej.</li>
						<li><strong>persistent cookies</strong> - pliki przechowywane w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.</li>
					</ul>
				</p>
				<p>
					W Kwatery-Laskowscy.PL stosowane są następujące rodzaje plików cookies:
					<ul>
						<li> pliki cookies umożliwiające korzystanie z usług Witryny kwatery-laskowscy.pl , np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w obrębie Witryny.</li>
						<li> pliki cookies umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu.</li>
		 				<li>pliki cookies umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub wyglądu strony internetowej itp.</li>
	 				</ul>
	 			</p>
	 			<p>
				Pliki cookies zamieszczane w urządzeniu końcowym Użytkownika Witryny i mogą być również wykorzystywane przez współpracujących reklamodawców oraz partnerów.
				W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy kwatery-laskowscy.pl mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu.
				</p>
				<p>
				Informujemy, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronie kwatery-laskowscy.pl.
				</p>
				<p>
				Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w pomocy przeglądarki internetowej <strong>(klawisz F1)</strong>.
				</p>
			</div>
		)
	}
}
CookiesPolicy.propTypes = {

}

export default CookiesPolicy;