import React, { Component } from 'react';
import AboutPage from './about_page/AboutPage';
import RoomContainer from './room_page/RoomContainer';


class OfferPage extends Component {
	render() {
		return (
			<div>
				<AboutPage/>
				<RoomContainer showSingle={false}/>
			</div>

			
		)
	}
}

export default OfferPage;