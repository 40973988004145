import React, { Component } from 'react';
import './../../../../stylesheets/about_page.css';
import './../../../../stylesheets/style.css';

class AboutDescription extends Component {
	render() {
		return (
			<div className="aboutSectionDescription">
				<div>
					<p>Wynajem kwater w centrum Międzyzdrojów</p>
					<ul>
			  		<li>Na plażę 100m<img src="/ico/sea.svg" style={{position: 'absolute', left: '150px', marginRight: '30px', width: '75px', height: '75px'}}/></li>
			  		<li>na molo 300m</li>
			  		<li>sklepy 10m</li>
			  	</ul>
			  </div>
			  <div>	
			  	<p>Pokoje dla 2, 3 i 4 osób w ofercie przez cały rok.</p>
			  	<p>Idealne na wczasy dla</p>
			  	<ul>
			  		<li> par,</li>
			  		<li> rodziców z dziećmi,</li>
			  		<li> dziadków z wnukami,</li>
			  		<li> grup przyjaciół.</li>
			  	</ul>
				</div>
			  <div>	
			  	<p>Wypoczynek bez kontaktu z pozostałymi gośćmi.</p>					
				  <p>Każdy pokój posiada </p>
				  <ul>
			  		<li> bezpośrednie wejście z zewnątrz,</li>
			  		<li> własny prysznic i toaletę.</li>
			  	</ul>
				</div>
				<div>
				  <p>Strefa bezpiecznego wypoczynku z dziećmi.</p>
				  <ul>
			  		<li>Drewniana altana i grill zlokalizowane w zielonym ogrodzie.</li>
			  		<li>Pokoje i ogród oddalone od ruchu ulicznego.</li>
			  	</ul><p></p>
					<p></p></div>
					<div>
				  	<p>Beztroski wypoczynek.</p>
						<ul>
				  		<li>Monitorowane miejsce na postój samochodu.</li>
			  	</ul>
					</div>
				</div>

			);
	}
}

export default AboutDescription;