//Lista wyszczególniająca wszystkie rezerwacje wszystkich kategorii

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class BookingListItem extends Component {


    constructor(){
        super();
        this.state = {

        }
        this.bookingsToCalendar = this.bookingsToCalendar.bind(this);
    }

    bookingsToCalendar(event){
        let bookingId = event.target.id;
        this.props.showDetailsOfBooking(bookingId);
    }
    

    render() {
        function listValues(booking){
            let arrayOfValues = [];
            for (const [key, value] of Object.entries(booking)) {
               arrayOfValues.concat(<p>{key}</p>)
            }
            return arrayOfValues;
        };
        const array = listValues(this.props.booking);
        const bookingColors = {
            confirmed: "success",
            unconfirmed: "info",
            canceled: "danger"
        };
        
        function localizeDateStr(date_to_convert_str) 
        {
            var date_to_convert = new Date(date_to_convert_str);
            var dateArray = [''+date_to_convert.getDate(), date_to_convert.getMonth()+1+'', ''+date_to_convert.getFullYear()];
            dateArray = dateArray.map((dateElement)=>{
                if(dateElement.length<2)
                {
                    return '0'+dateElement;
                }
                else
                {
                    return dateElement;
                }

            });
            var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2];
            return dateString; 	
        }
        const roomColors = ['success', 'primary', 'warning'];

		return (
			<div key={this.props.index} className={'booking-list-item'}>
                    {Object.entries(this.props.booking).map((value, idx)=>{
                            if(value[0]==='checkin' || value[0]==='checkout')
                            {
                                const date = new Date(value[1]);
                                return (
                                <div style={{width: '8%'}}>
                                    <p key={idx}>{localizeDateStr(date)}</p>
                                </div>)
                            }
                            else if (value[0] === 'bookingId')
                            {
                                return (<div style={{width: '11%'}} className={'bg-' + bookingColors[this.props.booking.bookingType]} >
                                    <p key={idx} id={value[1]} style={{cursor: 'pointer'}} onClick={this.bookingsToCalendar}>{value[1]}</p>
                                </div>)
                            }
                            else if (value[0] === 'roomId')
                            {
                                return (<div style={{width: '4%'}} className={'bg-' + roomColors[this.props.booking.roomId-1]} >
                                    <p key={idx}>{value[1]}</p>
                                </div>)
                            }
                            else if (value[0] === 'bookingType' || value[0] === 'accepted')
                            {
                                return null;
                            }
                            else if (value[0] === 'guestsQuantity')
                            {
                                return (<div>
                                    <p key={idx}>{value[1] ? value[1]+' dorosłych' : ' dorosłych'}</p>
                                </div>)
                            }
                            else if (value[0] === 'guestsChildQuantity')
                            {
                                return (<div>
                                    <p key={idx}>{value[1] ? value[1]+' dzieci' : 'bez dzieci'}</p>
                                </div>)
                            }
                            else if (value[0] === 'guestsCar')
                            {
                                return (<div>
                                    <p key={idx}>{value[1] ? 'samochód' : 'bez samochodu'}</p>
                                </div>)
                            }
                            else
                            {
                                return (<div>
                                    <p key={idx}>{value[1]}</p>
                                </div>)
                            }
                    }
                    )}
            </div>
		)
	}
}

export default BookingListItem;