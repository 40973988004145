import React, { Component } from 'react';


class PriceSettings extends Component {
constructor (props) {
		super(props);
		this.state = {
            editMode: false,
            editedValue: {roomId: false, month: false, editedValue: {value: false, multiplier: false}},
			months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
		}
        this.editModeToggle = this.editModeToggle.bind(this);
        this.copyNewValue = this.copyNewValue.bind(this);
        this.selectMultiplier = this.selectMultiplier.bind(this);        
    }
    
    copyNewValue(event){
        const value = event.target.value;
        this.setState({
            editedValue: {...this.state.editedValue,
                editedValue: {...this.state.editedValue.editedValue,
                value}}
        })
    }

	editModeToggle(event) {
        const month = event.target.id,
            value = event.target.name;
        const editedValue = {value: value, multiplier: this.props.room.pricesPer[month]};
        if (!this.state.editMode)
        {   
            this.setState({
                editMode: month,
                editedValue: {roomId: this.props.room.roomId ,month, editedValue}
            })
        }
        else
        {
            const editedValue = {...this.state.editedValue.editedValue};
            let pricesTable = this.props.room.pricesTable,
                pricesPer = this.props.room.pricesPer;
            pricesPer[month] = editedValue.multiplier;
            pricesTable[month] = editedValue.value;
            this.props.changePrice(pricesTable, pricesPer, this.state.editedValue.roomId);
            this.setState({
                editMode: false,
                editedValue: {roomId: false, month: false, editedValue: {value: false, multiplier: false}},
            })
        }
            
    }
    
    selectMultiplier(event){
        const multiplier = event.target.value;
        this.setState({
            editedValue: {...this.state.editedValue,
                editedValue: {...this.state.editedValue.editedValue,
                multiplier}}
        })
    }
	render (){
        let room = this.props.room ? this.props.room : {};
        const selected = this.props.selected ? this.props.selected : 1;
		return (
            <div className='price-settings'>
                <div className="row justify-content-center" style={{margin: '70px 0px'}}>
                    {this.state.months.map((month, index)=>(
                        <div className="col-4">
                            <div className={"card"} key={index}>
                                <div className={"card-header"}>
                                    <p style={{textAlign: 'center'}}>{month}</p>
                                </div>
                                <div className="card-title">

                                </div>
                                <div className={"card-text"} style={{textAlign: 'center'}}>
                                    {!this.state.editMode && <p style={{textAlign: 'center'}}>{room.pricesTable[index]} {" zł / "}{room.pricesPer[index] === "person" ? "osobę" : "pokój"}</p>}
                                    {this.state.editMode === ""+index && <div style={{textAlign: 'center'}}>
                                        <input type="number" defaultValue={room.pricesTable[index]} onChange={this.copyNewValue} ></input>
                                        <input type="radio" checked={this.state.editedValue.editedValue.multiplier === 'person' ? true : null} id='person' name="multiplier" value='person' onChange={this.selectMultiplier}>
                                        </input>
                                        <label htmlFor='person'>
                                                za osobę
                                        </label>                                               
                                        <input type="radio" id='room' name="multiplier" checked={this.state.editedValue.editedValue.multiplier === 'room' ? true : null} value='room' onChange={this.selectMultiplier}>
                                        </input>
                                        <label htmlFor='room'>
                                                za pokój
                                        </label>  
                                    </div>
                                    }
                                    <input type="button" 
                                        id={index}
                                        value={this.state.editMode === ""+index ? "zapisz" : "edytuj"} 
                                        name={room.pricesTable[index]}
                                        onClick={this.editModeToggle}
                                        style= {{
                                            height:"40px", 
                                            width: "85px",
                                            display: this.state.editMode === ""+index ? "inline-block" : this.state.editMode === false ? "inline-block" : "none"
                                        }}
                                        className={this.state.editMode === ""+index ? "btn btn-"+room.cssColor :"btn btn-outline-"+room.cssColor} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
		);
	}
}

export default PriceSettings;						