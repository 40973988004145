import React, { Component } from 'react';
import Lightbox from 'react-images';
import PropTypes from 'prop-types';
import './../../../stylesheets/image_gallery.css'

class Gallery extends Component {
    constructor(props){
    super(props);
    this.state = { currentImage: 0, lightboxIsOpen: false}; 
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
      this.nextImage = this.nextImage.bind(this, this.state.currentImage);
      this.previousImage = this.previousImage.bind(this);
    }

  openLightbox(event) {
    let id = parseInt(event.target.id);
    this.setState({
      currentImage: id,
      lightboxIsOpen: true,
    });     
  }

  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });    
  }

  previousImage() {
    this.setState({
      currentImage: --this.state.currentImage,    
    });     
  }

  nextImage() {
    this.setState({
      currentImage: ++this.state.currentImage  
    }); 
  }

	render() {
		const caption = this.props.title;
    const folderName = this.props.folderName;
    const imageArray = this.props.photos;
    const imageArrayRepresentation = imageArray.map((imageFileName, imageIndex)=>(
      <img onClick={this.openLightbox} 
      className="img-thumbnail miniature" 
      id={imageIndex} 
      src={'./'+folderName+'/'+imageFileName}
      key={imageIndex} 
      alt={this.props.title}/>
    ))
    const imageArrayRepresentation2 = imageArray.map((miniature, imageIndex)=>(
      <div
      onClick={this.openLightbox}
      className="img-thumbnail miniature"
      id={imageIndex}
      style={{
      	backgroundImage: 'url(./'+folderName+'/'+miniature.file+')',
      	backgroundSize: miniature.fill +'%',
      	backgroundPositionX: miniature.x +'%',
      	backgroundPositionY: miniature.y +'%'}}
      key={imageIndex}
      alt={this.props.title}/>
    ))

		return (
			<div className="gallery">
				<div className="thumbnail">
				  {imageArrayRepresentation2}		
				</div>
        <Lightbox
          images={this.props.photos.map(function(miniature) {
		        return {src: folderName+'/'+miniature.file, caption: caption}
		      })}
	        currentImage ={this.state.currentImage}
	        isOpen={this.state.lightboxIsOpen}
	        onClickPrev={this.previousImage}
	        onClickNext={this.nextImage}
	        onClose={this.closeLightbox}
	        imageCountSeparator=" z "
	        rightArrowTitle="Następne"
      		leftArrowTitle="Poprzednie"
      		closeButtonTitle="Zamknij"
      		backdropClosesModal				
		    />
			</div>
			);
	}
}

Gallery.propTypes ={
  photos: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  folderName: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string ]),
}

export default Gallery;