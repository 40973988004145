import React, { Component } from 'react';


class PriceTable extends Component {
constructor (props) {
		super(props);
		this.state = {
			editMode: false,
			months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],
		}
		this.editModeToggle = this.editModeToggle.bind(this);
	}

	editModeToggle(event) {
		this.setState({
			editMode: !this.state.editMode
		})
	}
	render (){
		return (
				<div>
					<div className="row" style={{marginBottom: '200px'}}>
						<div className="col-11">
							<table class="table">
							  <thead>
							    <tr>
							      <th scope="col">Miesiąc</th>
							      {this.props.userData.rooms.map((room, idx)=>(
							      <th key={idx} className={"table-"+room.cssColor}>{room.title}</th>
								    ))}
							    </tr>
							  </thead>
							  <tbody>
							  {this.state.months.map((month, index)=>(
									<tr>
							      <th scope="row">{month}</th>
							      {this.props.userData.rooms.map((room, idx)=>(
								      <td id={idx} name={index} key={idx} className={"table-"+room.cssColor}>{room.pricesTable[index]} {" zł / "}{room.pricesPer[index] === "person" ? "osobę" : "pokój"} </td>
								    ))}
	            		</tr>
	            		))
								}
							   
							  </tbody>
							</table>
						</div>
						<div className="col-1">
						<input type="button" 
      				 id="editModeToggle" 
      				 value={this.state.editMode === false ? "edytuj" : "zapisz"} 
      				 name="editModeToggle"
      				 onClick={this.editModeToggle}
      				 style= {{height:"40px", width: "85px", display: "inline-block"}}
      				 className={this.state.editMode === false ? "btn btn-outline-secondary" : "btn btn-success"} />
				          
						</div>
					</div>
				</div>
		);
	}
}

export default PriceTable;						