import React, { Component } from 'react';
import './../../../stylesheets/localization_page.css';


class LocalizationDescription extends Component {
	render() {
		return (
			<div className='localization-description'>
				<a href="https://goo.gl/maps/JE4eC2qbAs621FJ3A" target="_blank" rel="noopener noreferrer"><div></div></a><h1>Gryfa Pomorskiego 14/2, Międzyzdroje</h1>
				<h2><img src="/ico/sea.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Do plaży 100 m !</h2>
				<h2><img src="/ico/molo.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Do molo 300 m !</h2>
				<h3>Po drugiej stronie ulicy Pasaż pod Gryfem, a w nim: </h3>
				<ul>
					<li><img src="/ico/arbuz.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Biedronka i inne sklepy spożywcze</li>
					<li><img src="/ico/lody.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Lokale gastronomiczne</li>
					<li><img src="/ico/kolo.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Sklepy z pamiątkami</li>
					<li><img src="/ico/apteka.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Apteka, klinika stomatologiczna</li>
					<li><img src="/ico/banknoty.svg" style={{marginRight: '30px', width: '50px', height: '50px'}}/>Banki i kantor</li>
				</ul>

				</div>
		)
	}
}

export default LocalizationDescription;