import React, { Component } from 'react';
import RoomPage from './RoomPage';
import LoadingComponent from './../../../universal/load/LoadingComponent';


class RoomContainer extends Component {
		constructor (props) {
		super(props);
		this.state = {
			rooms: null,
			bookings: null
		}
		this.roomsData = this.roomsData.bind(this);
	}

	roomsData() {
    fetch('https://laskowskicreative.no/api/getroomsdata', {
      method: 'GET',
    }).then((res) => res.json())      
    .then((responseData) => {
    	//console.log(responseData);
      this.setState({rooms: responseData.rooms, bookings: responseData.bookings})
    })
  }

  componentDidMount() {
    fetch('https://laskowskicreative.no/api/getroomsdata', {
      method: 'GET', mode: 'cors'
    }).then((res) => res.json())      
    .then((responseData) => {
    	//console.log(responseData);
      this.setState({rooms: responseData.rooms, bookings: responseData.bookings})
    })
  }

	render() {
		let rooms = this.state.rooms;
		return (
			<div>
			{rooms === null && <LoadingComponent/>}
			{rooms !== null && <RoomPage {...this.state} showSingle={this.props.showSingle} />}
			</div>
		)
	}
}

export default RoomContainer;