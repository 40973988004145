import React, { Component } from 'react';
import PropTypes from 'prop-types';

class WeekHeaders extends Component {
	render() {
		return (
		<thead>
			<tr>			                
				{this.props.weekDayHeaders.map((weekDayName, index)=>
				<th key={index}>{weekDayName}</th>)}
			</tr>
		</thead>
		)
	}
}

WeekHeaders.propTypes ={
	weekDayHeaders: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default WeekHeaders;