import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class MessagesNavigation extends Component {
	constructor() {
		super();
		this.state={
			navigationBar: [
				{name: 'inbox', caption: 'Odebrane'},
				{name: 'archived', caption: 'Archiwalne'},
				{name: 'trash', caption: 'Usunięte'},
			]
		}
		this.forwardNavigation = this.forwardNavigation.bind(this);
	}
	forwardNavigation(event){
		const navigateDirection = event.target.name;
		this.props.navigationService(navigateDirection);
	}
	render() {
		return (
			<div className='user-settings-navigation'>
				<p>Skrzynka</p>
				<ul>
					{this.state.navigationBar.map((navigateDirection, idx)=>(
						<li key={idx}>
						<button className={this.props.navigateDirection === navigateDirection.name? 'btn btn-dark btn-block' : 'btn btn-outline-dark btn-block'} name={navigateDirection.name} onClick={this.forwardNavigation}>{navigateDirection.caption}<span style={{position: 'relative', left: '3px', bottom: '3px'}} style={{position: 'relative', marginLeft: '3px', bottom: '3px', display: navigateDirection.name ==='inbox' && this.props.counters.messages ? 'initial' : 'none'}} class="badge badge-pill badge-light">{this.props.counters.messages}</span></button>
						</li>
					))}

				</ul>
			</div>
		)
	}
}

export default MessagesNavigation;