import React, { Component } from 'react';
import PropTypes from 'prop-types';
import WeekCalendar from './WeekCalendar';

class MonthCalendar extends Component {
	render() {
		return (
			<tbody 
			id="calendar-body">
			    {this.props.currentCalendar.calendar.daysCalendar 
			    	&& this.props.currentCalendar.calendar.daysCalendar.map((week, index)=>(
							<WeekCalendar 
								getCalendarSelectedValues={this.props.getCalendarSelectedValues}
								bookingTypeHeaders={this.props.bookingTypeHeaders}
								bookingTypeColors={this.props.bookingTypeColors}
								changeShowBookingDetailsValue={this.props.changeShowBookingDetailsValue}
								currentMonth={this.props.currentCalendar.currentMonth} 
								currentYear={this.props.currentCalendar.currentYear}  
								createBookingList={this.props.createBookingList}
								key={index} 
								newBooking={this.props.newBooking}
								returnSelectedDay={this.props.returnSelectedDay}
								roomId={this.props.roomId}
								week={week} 
								weekBookingIds={this.props.currentCalendar.calendar.idsCalendar[index]} 
								weekBookingTypes={this.props.currentCalendar.calendar.typesCalendar[index]}
							/>
			    ))}		            
			</tbody>
		)
	}
}

MonthCalendar.propTypes ={
	addBookingToUnconfirmedCollection: PropTypes.func,
  currentCalendar: PropTypes.object.isRequired,
  bookingTypeColors: PropTypes.objectOf(PropTypes.string).isRequired,
  bookingTypeHeaders: PropTypes.objectOf(PropTypes.string).isRequired,
  changeShowBookingDetailsValue: PropTypes.func,
  confirmedHeaders: PropTypes.string.isRequired,
  createBookingList: PropTypes.func.isRequired,
  canceledHeaders: PropTypes.string.isRequired,
  newBooking: PropTypes.object.isRequired,
  roomId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool ]).isRequired,
  returnSelectedDay: PropTypes.func.isRequired,
  unconfirmedHeaders: PropTypes.string.isRequired
}

export default MonthCalendar;