import React, { Component } from 'react';


class BookingsListElement extends Component {
	constructor(props) {
    super(props);
    this.state = {
    	rooms: ["pokój zielony", "pokój niebieski", "apartament żółty"],
    	cssColors: [["#70AE98","#5E96AE","#FED797"],["#ADDDCE","#D9EFFC","#FCF7DE"]],
    }
  };

	render() {

	const checkInString = "Check-In: " + ('0' + this.props.booking.startDay).slice(-2) + "/" + ('0' + this.props.booking.startMonth).slice(-2) + "/" + this.props.booking.startYear;
	const checkOutString = "Check-Out: " + ('0' + this.props.booking.endDay).slice(-2) + "/" + ('0' + this.props.booking.endMonth).slice(-2) + "/" + this.props.booking.endYear;
		return (
			<div className="card" key={this.props.index} style={{zIndex: 1}}>
	    	<div className="card-header" id={this.props.booking.bookingId} style={{backgroundColor: this.state.cssColors[0][this.props.booking.roomId-1]}}>
		  		<section className="wrappingContainer">
			  		<div id="leftSide"><span>{checkInString}</span><span style={{marginLeft: "30px"}}>{checkOutString}</span></div>
			  		<div id="rightSide">				  		
				  		<div style={{position: "absolute", right: "13px", top: "13px"}}> 
					  		{this.props.bookingListType ==="unconfirmed" && <button id={this.props.booking.bookingId} onClick={this.props.setBookingConfirmed} style={{marginLeft: "30px"}} className="btn btn-success">Zaliczka</button>}
					  		{(this.props.bookingListType === "unconfirmed" || this.props.bookingListType === "confirmed") && <button style={{marginLeft: "30px"}} className="btn btn-info">Edytuj</button>}
				    		{this.props.bookingListType !== "deleted" && <button id={this.props.booking.bookingId} onClick={this.props.setBookingDeleted} style={{marginLeft: "30px"}} className="btn btn-danger">Usuń</button>}
				    		{this.props.bookingListType === "confirmed" && <button id={this.props.booking.bookingId} onClick={this.props.setBookingCanceled} style={{marginLeft: "30px"}} className="btn btn-warning">Anuluj</button>}
				    		{this.props.bookingListType ==="confirmed" && <button id={this.props.booking.bookingId} onClick={this.props.setBookingArchived} style={{marginLeft: "30px"}} className="btn btn-outline-light">Archiwizuj</button>}
				    		{this.props.bookingListType === "deleted" && <button id={this.props.booking.bookingId} onClick={this.props.setRestoreBooking} style={{marginLeft: "30px"}} className="btn btn-secondary">Przywróć</button>}
			
				    		<a style={{marginLeft: "30px"}} data-toggle="collapse" href={"#booking"+this.props.booking.bookingId} role="button" type="button" className="btn btn-outline-dark" aria-expanded="false" aria-controls={"booking"+this.props.booking.bookingId}>Szczegóły</a>
		    			</div>
		    		</div>		  		
		  		</section>
		  	</div>
	    <div className="collapse" id={"booking"+this.props.booking.bookingId}>
		    <div className="card-body" style={{backgroundColor: this.state.cssColors[1][this.props.booking.roomId-1]}}>
		    	<div className="card-title">
		    		<h6>{"id: " + this.props.booking.bookingId}</h6>
		    	</div>
		    	<div className="card-text">

						<p>{this.props.booking.contactFirstName ? "Imię: " + this.props.booking.contactFirstName : ""}</p>
						<p>{this.props.booking.contactSurname ? "Nazwisko: " + this.props.booking.contactSurname : ""}</p>
						<p>{this.props.booking.contactPhone ? "Telefon: " + this.props.booking.contactPhone : ""}</p>
						<p>{this.props.booking.contactEmail ? "E-mail: " + this.props.booking.contactEmail : ""}</p>
						<p>{this.props.booking.guestsQuantity ? "Liczba dorosłych gości: " + this.props.booking.guestsQuantity : ""}</p>
						<p>{this.props.booking.guestsChildQuantity ? "Liczba dzieci: " + this.props.booking.guestsChildQuantity : ""}</p>
						<p>{this.props.booking.extraNote ? "Dodatkowa notatka: " + this.props.booking.extraNote : ""}</p>

		    		<br/><br/>
		    		{"Check-In: " + this.props.booking.startDay + "/" + this.props.booking.startMonth + "/" + this.props.booking.startYear+ " 14:00"}
		    		<br/><br/>
		    		{"Check-Out: " + this.props.booking.endDay + "/" + this.props.booking.endMonth + "/" + this.props.booking.endYear + " 11:00"}
		    		<br/><br/>			    		
		 
		    	
		    	</div>
		    </div>
	    </div>
	  </div>
		)
	}
}

export default BookingsListElement;