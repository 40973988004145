import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import BookingListWrapper from './BookingListWrapper';
import SearchBox from './SearchBox';
import RoomSelector from './../navigation/RoomSelector';
import RoomPreview from './../navigation/RoomPreview';



class BookingList extends Component {
	constructor(){
		super();
		this.state = {
			selected: 0,
		}
		this.selectRoom = this.selectRoom.bind(this);
		this.searchFor = this.searchFor.bind(this);
		this.filterOnlySelectedBookings = this.filterOnlySelectedBookings.bind(this);
	}
	selectRoom(roomId){
		this.setState({
			selected: roomId
		})
	}

	searchFor(filter, value)
	{


	}

	filterOnlySelectedBookings(booking)
	{
		return booking.roomId === this.state.selected;
	}
	
	render() {
		let rooms = [0]
		rooms = rooms.concat(this.props.rooms ? this.props.rooms.map((room) => {return room.roomId}) : []);
		let bookings = this.props.bookings ? this.state.selected !== 0 ? this.props.bookings.filter(this.filterOnlySelectedBookings) : this.props.bookings : [];
		return (
			<div className="row">
				<div className='col-2'>
					<SearchBox/>
					<RoomSelector rooms={rooms} selectRoom={this.selectRoom} selected={this.state.selected}/>
					<RoomPreview gallery={this.state.selected === 0 ? [] : this.props.rooms[this.state.selected-1].photos} folderName={this.state.selected}/>
				</div>
				<div className='col-10 '>
					<BookingListWrapper showDetailsOfBooking={this.props.showDetailsOfBooking} bookings={bookings}/>
				</div>
			</div>
		)
	}
}

export default BookingList;