import React, { Component } from 'react';
import BookingFormMessagesPage from './booking_messages_page/BookingFormMessagesPage';
import ContactFormMessagesPage from './messages_page/ContactFormMessagesPage';
import BookingCalendarPage from './booking_calendar/DemoBookingsCalendarContainer';
import RoomSettings from './room_settings_page/RoomSettings';
import UserSettingsContainer from './user_settings_page/UserSettingsContainer';
import BookingList from './booking_list_page/BookingList';
import Header from './../universal/header/HeaderAdmin';
import Footer from './../universal/footer/Footer';
import Login from './login_page/Login.js';
import Cookies from 'universal-cookie';
//import UsersPage from './users_page/UsersPage';
//import SettingsPage from './settings_page/SettingsPage';


import 'bootstrap';
import 'popper.js/dist/popper.js';
import 'jquery/dist/jquery.js';
import './../../stylesheets/App.css';
import './../../stylesheets/Admin.css';
import e from 'cors';


class AdminHome extends Component {
	constructor () {
		super();
		this.state = {
		views: {
			BookingFormMessagesPage: false,
			ContactFormMessagesPage: false,
			BookingCalendarPage: false,
			BookingListPage: true,
			RoomSettings: false,
			UserSettings: false,
		},
		loggedUser: {accessAllowed: false, uid: false, sessionid: false},
		wrongPass: false,
		}
		this.checkSessionId = this.checkSessionId.bind(this);
		this.handleLogin = this.handleLogin.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleViewChange = this.handleViewChange.bind(this);
		this.setMessageAsRead = this.setMessageAsRead.bind(this);
		this.downloadMessagesFromDB = this.downloadMessagesFromDB.bind(this);
		this.downloadDataFromDB = this.downloadDataFromDB.bind(this);
		this.deleteMessage = this.deleteMessage.bind(this);
		this.archiveMessage = this.archiveMessage.bind(this);
		this.sendFormAnswer = this.sendFormAnswer.bind(this);
		this.changePrice = this.changePrice.bind(this);		
		this.setCookie = this.setCookie.bind(this);
		this.deleteSessionCookies = this.deleteSessionCookies.bind(this);
		this.showDetailsOfBooking = this.showDetailsOfBooking.bind(this);
	};

	changePrice(pricesTable, pricesPer, roomId){
		const data = {pricesTable, pricesPer, roomId, ...this.state.loggedUser};
		fetch('https://laskowskicreative.no/api/cp', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
		}).then((res) => res.json())      
		.then((json) => { 
			//console.log(json);
			if (json.pricesChanged)
				this.downloadDataFromDB();
			else
				this.downloadDataFromDB();
		})
	}

	setCookie(uid, sessionid) {
		const cookies = new Cookies();
		let expires = new Date();
		expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
		cookies.set('sessionid', sessionid, expires.toUTCString(), {path: '/'});
		cookies.set('uid', uid, expires.toUTCString(), {path: '/'});
	}

	deleteSessionCookies() {
		const cookies = new Cookies();
		cookies.remove('sessionid', {path: '/'});
		cookies.remove('uid', {path: '/'});
	}
	
	handleLogin(event) {
		const data = {login: document.getElementById('login').value, password: document.getElementById('password').value}
		//console.log(data);
		event.preventDefault();
		fetch('https://laskowskicreative.no/lc/login', {
			method: 'POST',
			headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
			},
			body: JSON.stringify(data)
		})
		.then((res) => res.json())      
		.then((json) => {
			if (json.passwordMatch===true){
				this.setCookie({uid: json.uid, sessionid: json.sessionid});
				this.setState({ wrongPass: false, loggedUser: {sessionid: json.sessionid, uid: json.uid, accessAllowed: true, messages: json.messages, bookings: json.bookings, rooms: json.rooms} });
			}
			else{
				this.setState({wrongPass: true})
			}
			
		})
	}

	handleLogout() {
		this.deleteSessionCookies();
		this.setState({ wrongPass: false, loggedUser: {sessionid: false, uid: false, accessAllowed: false} });
	}

	handleViewChange(activatedView) {
    let views = {
			BookingFormMessagesPage: false,
			ContactFormMessagesPage: false,
			BookingCalendarPage: false,
			UsersPage: false,
			SettingsPage: false,
		};
		views = {...views, ...activatedView};
	    this.setState({
		  views,
		  showBookingDetails: false,
	    });
    }
	checkSessionId(uid, sessionid) {
		const data = {uid, sessionid};
		fetch('https://laskowskicreative.no/lc/checksession', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify(data)
		}).then((res) => res.json())      
		.then((json) => { 
			//console.log(json);
			const accessAllowed = json.accessAllowed;
			const uid = json.uid;
			this.setState({
				loggedUser: {
					...this.state.loggedUser,
					uid,
					accessAllowed
				}
			}, this.downloadDataFromDB())
	    })
	}

	downloadDataFromDB() {
		const cookies = new Cookies();
		const uid = cookies.get('uid') ? cookies.get('uid') : false;
		const data = {...uid};
		fetch('https://laskowskicreative.no/api/dd', {
		method: 'POST',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		  },
		  body: JSON.stringify(data)
		}).then((res) => res.json())      
		.then((json) => {
			this.setState({...json,
				loggedUser: {
					...uid
				}
			}, )
		})
	}

	setMessageAsRead(event) {
	  const value = event.target.id;
	  console.log("Event.target.id: " + event.target.id);
	  const data = {...this.state.loggedUser, messageId: value};
	  //console.log(data);
	  fetch('https://laskowskicreative.no/api/smr', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => this.downloadMessagesFromDB())
  }

  deleteMessage(messageId) {
	const data = {...this.state.loggedUser, messageId};
	fetch('https://laskowskicreative.no/api/smd', {
	  method: 'POST',
	  headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	  },
	  body: JSON.stringify(data)
	})
  .then((res) => this.downloadMessagesFromDB())
}

archiveMessage(messageId) {
	const data = {...this.state.loggedUser, messageId};
	fetch('https://laskowskicreative.no/api/sma', {
	  method: 'POST',
	  headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	  },
	  body: JSON.stringify(data)
	})
  .then((res) => this.downloadMessagesFromDB())
}

sendFormAnswer(messageId, messageContent) {
	const data = {...this.state.loggedUser, messageId, messageContent};
	fetch('https://laskowskicreative.no/api/sma', {
	  method: 'POST',
	  headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	  },
	  body: JSON.stringify(data)
	})
  .then((res) => this.downloadMessagesFromDB())
}




  downloadMessagesFromDB() {
	const data = {...this.state.loggedUser};
   
    fetch('https://laskowskicreative.no/api/dm', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => res.json())      
    .then((json) => {
    	//console.log(json);
      this.setState({ messages: json.messages })
    })
  }
 	componentWillMount(){
		const cookies = new Cookies();
		const uid = cookies.get('uid') ? cookies.get('uid') : false;
		this.checkSessionId(uid.uid, uid.sessionid)
	//this.downloadDataFromDB();

	
  }

  showDetailsOfBooking(bookingId)
  {
	this.setState({
		showBookingDetails: bookingId,
	}, this.handleViewChange({'BookingCalendarPage': true}))
  }

	render() {
		let rooms = [];
		if (this.state.rooms)
		{
			rooms = this.state.rooms.map((room) => {return room.roomId});
		}
		var Counters = {
			messages: 0,
			booking_room_1: 0,
			booking_room_2: 0,
			booking_room_3: 0,
		};
		if (this.state.messages)
		{
			let unreadCounters = [{
				messages: 0,
				booking_room_1: 0,
				booking_room_2: 0,
				booking_room_3: 0,
			}];
			console.log(unreadCounters);
			unreadCounters[0] = this.state.messages.map((message) => {
				if (message.contactTopic === 2 && message.contactUnread)
				{
					let newCounters = {
						...unreadCounters[0],
						messages: ++unreadCounters[0].messages
					};
					return newCounters;
				}
				else if (message.contactTopic === 1 && message.contactUnread)
				{
					let counterName = 'booking_room_'+message.contactBooking.roomId;
					let newCounters = {
						...unreadCounters[0],
						[counterName]: ++unreadCounters[0][counterName],

					};
					return newCounters;
				}
				else
				{
					return unreadCounters[0];
				}
			})
			Counters = unreadCounters[unreadCounters.length-1];
			//rooms = this.state.rooms.map((room) => {return room.roomId});
		}
		
		return (
			<div id="container" className="container-fluid content-bg" >
				{(!this.state.loggedUser.accessAllowed || !this.state.loggedUser.sessionid || !this.state.loggedUser.uid) && <div className="row">
					<div className="col-12">
						<Login handleLogin={this.handleLogin}/>
					</div>
				</div>}

				{this.state.loggedUser.accessAllowed && this.state.loggedUser.sessionid && this.state.loggedUser.uid && <> <div className="row" style={{	
									position: "sticky",
									top: 0, 
									opacity: 1, 
									zIndex: 2, 
									}}
								>
								 	<div className="col-12" >		
										<Header
										counters={Counters[Counters.length-1]}
										views={this.state.views}
										handleViewChange={this.handleViewChange}
										handleLogout={this.handleLogout}/>
								 	</div>
								</div>
								<div id="mainContent">
									<div className="row" >
										<div className="col-12" id="breakLine"></div>
									</div>
									{this.state.views.BookingFormMessagesPage && <BookingFormMessagesPage
										counters={Counters[Counters.length-1]} 
										bookings={this.state.bookings} 
										downloadMessagesFromDB={this.downloadMessagesFromDB} 
										messages={this.state.messages} 
										rooms={this.state.rooms} 
										setMessageAsRead={this.setMessageAsRead}
										user={this.state.loggedUser}
										/>}
									{this.state.views.ContactFormMessagesPage && <ContactFormMessagesPage
										archiveMessage={this.archiveMessage}
										counters={Counters[Counters.length-1]}
										deleteMessage={this.deleteMessage}
									  	downloadMessagesFromDB={this.downloadMessagesFromDB} 
									  	messages={this.state.messages} 
										sendFormAnswer={this.sendFormAnswer} 
									  	setMessageAsRead={this.setMessageAsRead}
										user={this.state.loggedUser}
										/>}
							    	{this.state.views.BookingCalendarPage && this.state.bookings && <BookingCalendarPage 
							      		bookings={this.state.bookings} 
										rooms={this.state.rooms} 
										showBookingDetails={this.state.showBookingDetails ? this.state.showBookingDetails : false}
										user={this.state.loggedUser}
									/>}
									{this.state.views.BookingList && <BookingList 
										showDetailsOfBooking={this.showDetailsOfBooking} 
										rooms={this.state.rooms} 
										bookings={this.state.bookings}
										/>}	  
									{this.state.views.RoomSettings && <RoomSettings 
										changePrice={this.changePrice} 
										rooms={this.state.rooms}
										user={this.state.loggedUser}
										/>}
									{this.state.views.UserSettings && <UserSettingsContainer 
										user={this.state.loggedUser}/>}
								</div>
								<div className="col-12" >		
										<Footer/>
								 	</div></>}
			</div>
		);
	}
}

export default AdminHome;