import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Gallery from './../../../universal/gallery/Gallery';

class AboutImages extends Component {

	render() {
		return (
			<Gallery 
				photos={this.props.photos} 
				title={this.props.title}
				folderName={this.props.folderName}
			/>
			);
	}
}

AboutImages.propTypes ={
	photos: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string,
	folderName: PropTypes.string
}

export default AboutImages;
