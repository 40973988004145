import React, { Component } from "react";
import './snowflakes.css';

class GodJulPopup extends Component {
  constructor () {
    super();
	  this.state = {
        popupClass: "god-jul-popup"
    }
  } 

  onClose = () => {
    this.setState({
        popupClass: 'god-jul-popup-disappear'
    })
}          


 render ()
  {
    const {popupClass} = this.state;
    return (
    <div onClick={this.onClose} className={popupClass} aria-hidden="true">
        <div className="god-jul">
            <div className="god-jul-popup-close-button" onClick={this.onClose}>X</div>
        </div>
    </div>
    );
  }
}

export default GodJulPopup;
