import React, { Component } from 'react';
import ContactPage from './ContactPage';

class ContactContainer extends Component {
		constructor (props) {
		super(props);
		this.state = {
			rooms: null,
			bookings: null
		}
		this.roomsData = this.roomsData.bind(this);
	}

	roomsData() {
    fetch('https://164.132.113.224/api/getroomsdata', {
      method: 'GET',
    }).then((res) => res.json())      
    .then((responseData) => {
    	//console.log(responseData);
      this.setState({rooms: responseData.rooms, bookings: responseData.bookings})
    })
  }

  componentDidMount() {
    fetch('https://164.132.113.224/api/getroomsdata', {
      method: 'GET',
    }).then((res) => res.json())      
    .then((responseData) => {
    	//console.log(responseData);
      this.setState({rooms: responseData.rooms, bookings: responseData.bookings})
    })
  }

	render() {
		return (
			<ContactPage {...this.state} />
		)
	}
}

export default ContactContainer;