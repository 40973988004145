const defaultValues = {
	calendarCollections: {
		calendarMode:
		{
			confirmed: true,
			unconfirmed: true,
			canceled: false
		},
		newBookingMode:
		{
			confirmed: true,
			unconfirmed: true
		}
	},
	newBookingObject: {
		checkin: {
			day: false,
			month: false,
			year: false
		},
		checkout: {
			day: false,
			month: false,
			year: false
		}
	},
	newBookingArray : [{
		bookingId: 'new',
		bookingType: 'unconfirmed',
		checkin: false,
		checkout: false,
	}],
	resetAfterWrongDayInput: {
		newBookingIsCorrect: false,
		newBookingMode: true,
		selectedDayCount: 0
	}
}

export {defaultValues};