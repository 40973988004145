import React, { Component } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './components/client_main_page/Home';
import AdminPage from './admin/AdminPage';
import AdminHome from './components/admin_main_page/AdminHome';

//prawdopodobnie do usunięcia
import 'bootstrap';
import 'popper.js/dist/popper.js';
import 'jquery/dist/jquery.js';
import './stylesheets/App.css';


class App extends Component {
	render() {
		return (
			<Router>
        <Switch>
          <Route path="/cookies">
            <Home CookiesPolicy={true} />
          </Route>
          <Route path="/kontakt">
            <Home ContactPage={true} />
          </Route>
          <Route path="/zielony">
            <Home room={'1'} />
          </Route>
          <Route path="/niebieski">
            <Home room={'2'} />
          </Route>
          <Route path="/zolty">
            <Home room={'3'} />
          </Route>         
          <Route path="/lokalizacja">
            <Home LocalizationPage={true} />
          </Route>
          <Route path="/oferta">
            <Home OfferPage={true} />
          </Route>
          <Route path="/lc-control">
            <AdminHome />
          </Route>
          <Route path="/">
            <Home OfferPage={true} />
          </Route>
        </Switch>
    </Router>
		);
	}
}

export default App;