import React, { Component } from 'react';
import './../../../stylesheets/contact_page.css';
import 'bootstrap/dist/css/bootstrap.css';


class ContactInfo extends Component {
	render() {
		return (
			<div className='ContactInfo'>
				<img src="./dziewczynka_logo.png" height="250px"/>
				<h2>Kwatery Laskowscy - Międzyzdroje</h2>
				<a href='tel:+48913281618'><h3 className='numbertext'>+48 91 328 16 18</h3></a>
				<a href='tel:+48695335936'><h3 className='numbertext'>+48 695 335 936</h3></a>
			</div>
		)
	}
}

export default ContactInfo;