const	bookingTypeColors =
{
	confirmed: "success",
	unconfirmed: "info",
	canceled: "danger"
}
const dayButtonClass = {
	free: 'btn btn-outline-secondary',
	select: 'btn btn-light',
}
const roomButtonClass = {
	select: "btn btn-secondary",
	unselect: "btn btn-outline-secondary",
}
const	navigationButtonClass = "btn btn-outline-dark col-6";

const newBookingButtonClass = {
	cancel: 'btn btn-danger col-6',
	correct: 'btn btn-info col-6',
	add: 'btn btn-dark col-6'
}

export {
	bookingTypeColors, 
	dayButtonClass,
	roomButtonClass, 
	navigationButtonClass, 
	newBookingButtonClass};