import React, { Component } from 'react';
import EquipmentCategory from './/EquipmentCategory'
import PropTypes from 'prop-types';
import './../../../../stylesheets/style.css';


class RoomEquipment extends Component {
	render() {
		let equipment = {
			furnishings: [],
			bathroom: [],
			kitchen: [],
			multimedia: [],
			sun: []
		};
		for (let [key, value] of Object.entries(equipment)) {
		  equipment[key] = this.props.equipment.filter((equipmentElement, index)=> 
		  	{if (equipmentElement.category === key)
		  		return equipmentElement;
		  	})
		}
		return (
			<div className='EquipmentWrapper'>
				<div className='EquipmentCategory'>
					<p>Meble</p>
					<EquipmentCategory category={equipment.furnishings}/>
				</div>
				<div className='EquipmentCategory'>
					<p>Multimedia</p>
					<EquipmentCategory category={equipment.multimedia}/>
				</div>
				<div className='EquipmentCategory'>
					<p>Kuchnia</p>
					<EquipmentCategory category={equipment.kitchen}/>
				</div>
				<div className='EquipmentCategory'>
					<p>Łazienka</p>
					<EquipmentCategory category={equipment.bathroom}/>
				</div>
				<div className='EquipmentCategory'>
					<p>Dodatki</p>
					<EquipmentCategory category={equipment.sun}/>
				</div>
			</div>
		);
	}
}

RoomEquipment.propTypes ={
	  equipment: PropTypes.arrayOf(PropTypes.object)
}

export default RoomEquipment;