import React, { Component } from 'react';



class Login extends Component {
render (){
		return (
			<div id="container" className="container-fluid" >
					<div className="row justify-content-center">
						<div className="col-2" align='center'>
								<img src={'logo.png'} className="App-logo" alt="logo" />
						</div>
					</div>
					<div className="row justify-content-center">
						<div className="col-2" align='center'>
							<form onSubmit={this.props.handleLogin}>
							<legend>Panel logowania</legend>
								<input type="text"
                  name="login" placeholder="Login" id="login"/><br/>
								<input type="password"
                  name="password" placeholder="Hasło" id="password" /><br/>
								<button type="submit" className="btn btn-primary">Zaloguj</button>
							</form>
						</div>
					</div>
			</div>
		);
	}
}

export default Login;