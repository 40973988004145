import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class BookingFormConfirmation extends Component {
	render() {
		return (
			<div>					
			  <div className="card">
			  	<div className="card-header"><p>Formularz wysłano poprawnie.</p></div>
			    <div className="card-body">
			    	<div className="card-title"><p>Twój indywidualny identyfikator rezerwacji: {this.props.contactMessageId}</p></div>
			    	<div className="card-text">
			    		
							<p>Dziękujemy za zainteresowanie ofertą Kwatery Laskowscy.</p>
							<p> W ciągu 24h na podany adres e-mail otrzymasz odpowiedź od naszej obsługi.
							Zastrzegamy sobie prawo do akceptacji wybranych rezerwacji.</p>	    	

			    		</div>
			    	<button className="btn btn-secondary" onClick={this.props.newBookingModeSwitch}>Ok</button>
			    </div>
			  </div>
			</div>
		)
	}
}

export default BookingFormConfirmation;