import React, { Component } from 'react';
import UserSettingsNavigation from './../navigation/UserSettingsNavigation';
import AccountActivity from './AccountActivity';
import EmailNotificationSettings from './EmailNotificationSettings';
import UserAccount from './UserAccount';
import Stats from './Stats';
import LCanal from './LCanal';
import Bills from './Bills';
import 'bootstrap/dist/css/bootstrap.css';


class UserSettings extends Component {
	constructor(){
		super();
		this.state = {
			activeView: {
				UserAccount: false,
				AccountActivity: false,
				EmailNotificationSettings: false,
				Stats: false,
				LCanal: false,
				Bills: false,
			},
			defaultView: {
				UserAccount: false,
				AccountActivity: false,
				EmailNotificationSettings: false,
				Stats: false,
				LCanal: false,
				Bills: false,
			}
		}
		this.navigationService = this.navigationService.bind(this);
	}

	navigationService (navigateDirection){
		this.setState({
			activeView: {
				...this.state.defaultView,
				[navigateDirection]: true
			}
		})
	}
	render() {
		const activeView = {...this.state.activeView};
		return (
			<div className='row'>
				<div className='col-2 offset-1'>{<UserSettingsNavigation navigationService={this.navigationService}/>}</div>
				<div className='col-8'>
				{activeView.UserAccount && <UserAccount user={this.props.user}/>}
				{activeView.AccountActivity && <AccountActivity activity={this.props.user.activity}/>}
				{activeView.EmailNotificationSettings && <EmailNotificationSettings/>}
				{activeView.Stats && <Stats/>}
				{activeView.LCanal && <LCanal/>}
				{activeView.Bills && <Bills/>}</div>
			</div>
		)
	}
}

export default UserSettings;