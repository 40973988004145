import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DayCalendar from './DayCalendar';
import {dayButtonClass} from './../booking-calendar-utility/colorsSettings';

class WeekCalendar extends Component {
	render() {
		return (
			<tr>
				{this.props.week.map((day, index)=>(
					<DayCalendar 
						addBookingToUnconfirmedCollection={this.props.addBookingToUnconfirmedCollection}
						bookingId={this.props.weekBookingIds[index]} 
						bookingType={this.props.weekBookingTypes[index]} 
						bookingTypeHeaders={this.props.bookingTypeHeaders}
						currentMonth={this.props.currentMonth} 
						currentYear={this.props.currentYear}   
						changeShowBookingDetailsValue={this.props.changeShowBookingDetailsValue}
						createBookingList={this.props.createBookingList}
						day={day} 
						dayCellColor={this.props.weekBookingTypes[index] === 0 
							? dayButtonClass.free
							: this.props.bookingTypeColors[this.props.weekBookingTypes[index]] !== undefined 
								? "btn btn-"+this.props.bookingTypeColors[this.props.weekBookingTypes[index]]
								: "btn btn-secondary" //in case sth wrong with color
						}
						key={index} 
						newBooking={this.props.newBooking}
						roomId={this.props.roomId}
						returnSelectedDay={this.props.returnSelectedDay}
					/>
				))}
			</tr>
		)
	}
}

WeekCalendar.propTypes ={
	addBookingToUnconfirmedCollection: PropTypes.func.isRequired,
  bookingTypeColors: PropTypes.objectOf(PropTypes.string).isRequired,
  bookingTypeHeaders: PropTypes.objectOf(PropTypes.string).isRequired,
  changeShowBookingDetailsValue: PropTypes.func.isRequired,
  currentMonth: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  createBookingList: PropTypes.func.isRequired,
  newBooking: PropTypes.object.isRequired,
  returnSelectedDay: PropTypes.func.isRequired,
  roomId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool ]).isRequired,
	week: PropTypes.array.isRequired,
	weekBookingsIds: PropTypes.array,
	weekBookingsTypes: PropTypes.array,
}

export default WeekCalendar;