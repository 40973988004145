import React, { Component } from 'react';
import RoomOptionsNavigation from './../navigation/RoomOptionsNavigation';
import PriceSettings from './PriceSettings';
import RoomBasicSettings from './RoomBasicSettings';
import RoomGallerySettings from './RoomGallerySettings';
import RoomEquipmentSettings from './RoomEquipmentSettings';
import 'bootstrap/dist/css/bootstrap.css';


class RoomOptions extends Component {
	constructor(){
		super();
		this.state = {
			activeView: {
				PriceSettings: false,
				RoomBasicSettings: false,
				RoomEquipmentSettings: false,
				RoomGallerySettings: false,
			},
			defaultView: {
				PriceSettings: false,
				RoomBasicSettings: false,
				RoomEquipmentSettings: false,
				RoomGallerySettings: false,
			}
		}
		this.navigationService = this.navigationService.bind(this);
	}

	navigationService (navigateDirection){
		this.setState({
			activeView: {
				...this.state.defaultView,
				[navigateDirection]: true
			}
		})
    }

    render() {
        const activeView = {...this.state.activeView};
        const room = {...this.props.room};
		return (
			<div>
                <RoomOptionsNavigation css={room.cssColor} navigationService={this.navigationService}/>
                {activeView.PriceSettings && <PriceSettings changePrice={this.props.changePrice} room={room} selected={this.props.selected} />}
                {activeView.RoomBasicSettings && <RoomBasicSettings caption={room.title} capacity={room.capacity}/>}
                {activeView.RoomEquipmentSettings && <RoomEquipmentSettings equipment={room.equipment}/>}
                {activeView.RoomGallerySettings && <RoomGallerySettings/>}
            </div>
		)
	}
}

export default RoomOptions;