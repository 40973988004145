import React, { Component } from 'react';
import './../../../stylesheets/footer.css'

class Footer extends Component {
	constructor () {
		super();
		this.state = {

		}
	}


	render() {
		return (
			<div style={{textAlign: "center", backgroundColor: "#FFF", width: "100%", height: "22px", margin: "0", padding: "0", position: "fixed", left: 0, bottom: 0}}>
			<p><strong>	&copy; 2022 </strong><a href='https://laskowski.no'><img src="./lc.png" alt="Strony i sklepy internetowe; Projekty graficzne, fotografia i reklama online" height="18px"/></a></p>
			</div>
		)
	}
}

export default Footer;