import React, { Component } from 'react';

class Footer extends Component {
	render() {
		return (
			<div className='row justify-content-center' style={{position: 'sticky', bottom: 0}}>
				<div className='col-auto'>
					<h6 style={{marginTop: '50px', backgroundColor: '#fff'}}>
						{'Damian Dariusz Laskowski '} 
						<a 
							href='https://github.com/ddlaskowski' 
							target="_blank" 
							rel="noopener noreferrer nofollow">
							ddlaskowski
						</a>
					</h6>
				</div>
			</div>
		)
	}
}
export default Footer;