import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BookingCalendarLanguageSettings extends Component {
	constructor (props) {
		super(props);
		this.state = {
			languageGroupOpen: false,
		}
		this.chooseLanguage = this.chooseLanguage.bind(this);
		this.toggleLanguageGroup = this.toggleLanguageGroup.bind(this);
	}
	chooseLanguage(event) {
		const lang = event.target.id;
		this.props.setLanguage(lang);
		this.toggleLanguageGroup();
	}	
	toggleLanguageGroup() {
		let languageGroupOpen = this.state.languageGroupOpen;
		languageGroupOpen = !languageGroupOpen;
		this.setState({languageGroupOpen});
	}		
	render() {
		const languageButtons = this.props.langSettings.available.map((lang, index) => {
			return <input key={index} type="button" className={this.props.langSettings.select === lang ? "btn btn-secondary" : "btn btn-outline-secondary"} onClick={this.chooseLanguage} value={lang} id={lang}/>
		})
		const selectLanguage = <input type="button" className="btn btn-secondary" onClick={this.toggleLanguageGroup} value={this.props.langSettings.select} id='select' />;
		const outputButton = <div>
			<h5>
				{this.props.header + '\t'}
				{this.state.languageGroupOpen ? languageButtons : selectLanguage}
			</h5>
			
		</div>
		return outputButton;
	}
}
BookingCalendarLanguageSettings.propTypes ={
	header: PropTypes.string,
	langSettings: PropTypes.object.isRequired,
	setLanguage: PropTypes.func.isRequired,

}
export default BookingCalendarLanguageSettings;