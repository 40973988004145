import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import BookingContainer from './booking_page/BookingContainer';
import ContactContainer from './contact_page/ContactContainer';
import Header from './../universal/header/Header';
import Footer from './../universal/footer/Footer';
import LoadingComponent from './../universal/load/LoadingComponent';
import LocalizationPage from './localization_page/LocalizationPage';
import OfferPage from './offer_page/OfferPage';
import RoomContainer from './offer_page/room_page/RoomContainer';
import BookingCalendarPage from './../universal/booking_calendar/DemoBookingsCalendarContainer';
import CookiesWarning from './customer_relation_page/CookiesWarning';
import CookiesPolicy from './customer_relation_page/CookiesPolicy';
import LCFooter from './lc-footer/Footer';
import SnowFlakes from './GodJul/SnowFlakes';
import GodJulPopup from './GodJul/GodJulPopup';

import 'bootstrap';
import 'popper.js/dist/popper.js';
import 'jquery/dist/jquery.js';
import './../../stylesheets/App.css';
import './../../stylesheets/contact_page.css';
import CallPopup from './call_popup/CallPopup';


class Home extends Component {
	constructor () {
		super();
		this.state = {
		activeView: {
			BookingCalendarPage: false,
			ContactPage: false,
			LocalizationPage: false,
			CityInfoPage: false,
			OfferPage: false,
			BookingPage: false,
			CookiesPolicy: false,
		},
		bookings: []
		}
		this.handleViewChange = this.handleViewChange.bind(this);
	}

		

	handleViewChange(event) {
    const target = event.target;
    const name = target.name;
    let activeView = {
    	BookingPage: false,
    	ContactPage: false,
			LocalizationPage: false,
			CityInfoPage: false,
			OfferPage: false,
			room: false,
    }
    window.scrollTo(0,0);
    if (name === 'room')
    {
    	activeView = {
	    	...activeView,
	    	[name] : target.id
	    }
	    this.setState({
	    	...this.state,
	      activeView
	    });
    }
    else if (name === 'BookingPage')
    {
    	activeView = {
	    	...activeView,
	    	[name] : target.id
	    }
	    this.setState({
	    	...this.state,
	      activeView
	    });
    }
    else
    {
    	activeView = {
	    	...activeView,
	    	[name] : true
	    }
	    this.setState({
	    	...this.state,
	      activeView
	    });
    }

  }

	downloadDataFromDB() {
		const cookies = new Cookies();
		const cookiesAccepted = cookies.get('cookies-accepted') ? cookies.get('cookies-accepted') : false;
    fetch('https://laskowskicreative.no/api/rd', {
      method: 'GET',
    }).then((res) => res.json())      
    .then((json) => {
      this.setState({activeView: {...this.state.activeView, ...this.props}, cookiesAccepted ,rooms: json.rooms })
    })
  }

 	componentWillMount(){
    this.downloadDataFromDB();
  }

	render() {
		return (
			<div id="container" className="container-fluid content-bg" >

				<CallPopup/>
					<div className="row" style={{	
					position: "sticky",
					top: 0, 
					opacity: 1, 
					zIndex: 2, 
					}}
				>
				 	<div className="col-12" >
				 		
						<Header rooms={this.state.rooms} activeView={this.state.activeView} handleViewChange={this.handleViewChange}/>
				 	</div>
				</div>
				{!this.state.cookiesAccepted && <CookiesWarning/>}	
				<div id="mainContent">
					<div className="row" >
						<div className="col-12" id="breakLine"></div>
					</div>
				  {this.state.activeView.BookingPage && <BookingContainer/>}
				  {this.state.activeView.OfferPage && <OfferPage handleViewChange={this.handleViewChange}/>}
			      {this.state.activeView.ContactPage && <ContactContainer/>}
			      {this.state.activeView.LocalizationPage && <LocalizationPage/>}
			      {this.state.activeView.room === "2"  && <RoomContainer showSingle={this.state.activeView.room} />}
			      {this.state.activeView.room === "1"  && <RoomContainer showSingle={this.state.activeView.room} />}
			      {this.state.activeView.room === "3"  && <RoomContainer showSingle={this.state.activeView.room} />}
				  {this.state.activeView.CookiesPolicy && <CookiesPolicy />}
				</div>		
			</div>
		);
	}
}

export default Home;