import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import PropTypes from 'prop-types';
import './../../../stylesheets/customer_relation_page.css';

class CookiesWarning extends Component {
	constructor (props) {
		super(props);
		this.state = {
			mainClass: "cookies-warning row"
		};
    	this.setCookie = this.setCookie.bind(this);
	};

	setCookie() {
		const cookies = new Cookies();
		let expires = new Date();
  	expires.setTime(expires.getTime() + (365 * 24 * 60 * 60 * 1000));
		cookies.set('cookies-accepted', true, expires.toUTCString(), {path: '/'});
		this.setState({
			mainClass: "cookies-warning-disappear row"
		})
	}
	componentDidMount() {
	}

	render() {
		return (
			<div className={this.state.mainClass}>		
	    	<div className="col-12 col-lg-10">
	    		Strona używa plików cookie w celu realizacji usług zgodnie z <a href='/cookies'>Polityką Cookies</a>. Możesz samodzielnie określić warunki przechowywania lub dostępu plików cookie w Twojej przeglądarce. 
	    	</div>	
	    	<div className='col-12 col-lg-2'>
	    		<input type="button" className='btn btn-outline-dark' onClick={this.setCookie} value='Nie pokazuj więcej'/>
	    	</div>
			</div>
		);
	}
}
CookiesWarning.propTypes = {
	acceptBookingRulesButton: PropTypes.object
}

export default CookiesWarning;
