import React, { Component } from 'react';
import PropTypes from 'prop-types';

class BookingTypeSelect extends Component {
	constructor(props){
		super(props);
		this.changeCollection = this.changeCollection.bind(this);
	}
	changeCollection(event){
		const name = event.target.name;
		let checked = this.props.calendarCollections[name];
		checked = !checked;
		const outputObject = {[name]: checked};
		this.props.changeCalendarCollection(outputObject);
	}
	render() {
		const createCollectionButtons = (calendarCollections, bookingTypeColors, index) => {
			let collectionNames = [];
			let collectionButtons = [];
			for (let [collectionName, collectionState] of Object.entries(calendarCollections)) {
				const color = bookingTypeColors[collectionName];
				collectionNames = collectionNames.concat(collectionName);
				collectionButtons = collectionButtons.concat(
					<div key={collectionName}>
						<button type="button" 
						name={collectionName} 
						onClick={this.changeCollection}
						style={{height: '60px'}} 
						className={collectionState ? "btn btn-"+color +' btn-block': "btn btn-outline-"+color+' btn-block'}>
							{this.props[collectionName]}
						</button>
				</div>);
			}
			return {collectionNames, collectionButtons}
		};
		const collections = createCollectionButtons(this.props.calendarCollections, this.props.bookingTypeColors);
		return (
			<div id="form-control">
				{collections.collectionNames.map((collection, index) =>
					collections.collectionButtons[index]
				)}
			</div>
		);
	}
}

BookingTypeSelect.propTypes ={
	calendarCollections: PropTypes.objectOf(PropTypes.bool).isRequired,
	bookingTypeColors: PropTypes.objectOf(PropTypes.string).isRequired,
	changeCalendarCollection: PropTypes.func.isRequired,
	header: PropTypes.string.isRequired, //header
	canceled: PropTypes.string.isRequired, //header
	confirmed: PropTypes.string.isRequired, //header
	unconfirmed: PropTypes.string.isRequired, //header
}

export default BookingTypeSelect;

