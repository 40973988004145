import React, { Component } from 'react';
import {generateCalendar} from './functions_calendar.js';


class Calendar extends Component {
	constructor (props) {
		super(props);
		let today = new Date ();
		this.state = {
			calendar: [],
			currentMonth: today.getMonth(),
			currentYear: today.getFullYear(),
			monthHeaders: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],	
			weekDayHeaders: ['Pn.', 'Wt.', 'Śr.', 'Cz.', 'Pt.', 'Sb.', 'Nd.'],
			selectedDays: [null, null],
			selectedMonths: [null, null],
			selectedYears: [null, null],
			selectedDayCount: 0,	
			freeDayButtonClass: 'btn btn-outline-secondary',		
			get selectableYears() {return [this.currentYear, this.currentYear+1]}
		}
		this.next = this.next.bind(this);
		this.previous = this.previous.bind(this);
		this.jump = this.jump.bind(this);
		this.sendArray = this.sendArray.bind(this);
		this.selectDay = this.selectDay.bind(this);
		this.createMonthView = this.createMonthView.bind(this);
	}	


	next() {		
		let year = (this.state.currentMonth === 11) ? this.state.currentYear + 1 : this.state.currentYear;
		let month = (this.state.currentMonth + 1) % 12;
		if (this.props.roomCalendar)
		{this.props.getCurrentMonth(month)}

		let bookings = [];
		if (this.props.bookings)
		{
			bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === month+1 && book.startYear === year) || (book.endMonth === month+1 && book.endYear === year))})	
		}	
		
		let calendar = generateCalendar(month, year, bookings);
		//console.log(year + '  ' + month + '  ' + calendar)
		//console.log(bookings);
		this.setState({
	    currentYear : year,
	    currentMonth : month,
	    calendar
	  });
	}

	previous() {		
		let year = (this.state.currentMonth === 0) ? this.state.currentYear - 1 : this.state.currentYear;
		let month = (this.state.currentMonth === 0) ? 11 : this.state.currentMonth - 1;
		if (this.props.roomCalendar)
		{this.props.getCurrentMonth(month)}

		let bookings = [];
		if (this.props.bookings)
		{
			bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === month+1 && book.startYear === year) || (book.endMonth === month+1 && book.endYear === year))})	
		}	
		
		let calendar = generateCalendar(month, year, bookings);
		//console.log(year + '  ' + month + '  ' + calendar)
		//console.log(bookings);
		this.setState({
	    currentYear :year,
	    currentMonth : month,
	    calendar
	  });
	}


	jump() {		
		let year = parseInt(document.getElementById(this.props.type+'Year').value);
		let month = parseInt(document.getElementById(this.props.type+'Month').value);
		if (this.props.roomCalendar)
		{this.props.getCurrentMonth(month)}

		let bookings = [];
		if (this.props.bookings)
		{
			bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === month+1 && book.startYear === year) || (book.endMonth === month+1 && book.endYear === year))})	
		}	
		
		let calendar = generateCalendar(month, year, bookings);
		//console.log(year + '  ' + month + '  ' + calendar)
		//console.log(bookings);
		this.setState({
	    currentYear :year,
	    currentMonth : month,
	    calendar
	  });
	}

	selectDay(event) {
		let value = parseInt(event.target.value);
		let name = parseInt(event.target.name);
		if (this.state.selectedDayCount === 0 && !isNaN(parseInt(value)) && this.props.roomCalendar )
		{
			var daysArray = [value, null];			
			var monthArray = [name, null];
			var selectedDayCount = ++this.state.selectedDayCount;
			let bookings = []
			if (this.props.bookings)
			{
				bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))})
			}
			let calendar = generateCalendar(this.state.currentMonth, this.state.currentYear, bookings, daysArray, monthArray);

			this.setState({
			selectedDays: daysArray,
			selectedMonths: monthArray,
			selectedDayCount,
			calendar
			});
		}
		else if (this.state.selectedDayCount === 1 && !isNaN(parseInt(value)) && this.props.roomCalendar )
		{
			//console.log(this.state.selectedDays[0]);
			var daysArray = [this.state.selectedDays[0], value];
			var monthArray = [this.state.selectedMonths[0], name];
			this.props.getCalendarSelectedValues({
				startDay: isNaN(daysArray[0]) ? '01' : ('0' + daysArray[0]).slice(-2),
				endDay: isNaN(daysArray[1]) ? '01' : ('0' + daysArray[1]).slice(-2),
				startMonth: isNaN(monthArray[0]) ? '01' : ('0' + (monthArray[0]+1)).slice(-2),
				endMonth: isNaN(monthArray[1]) ? '01' : ('0' + (monthArray[1]+1)).slice(-2),
				startYear: this.state.currentYear,
				endYear: this.state.currentYear,
			})
			let checkInDate = new Date (2020, monthArray[0], daysArray[0]);
  		let checkOutDate = new Date (2020, monthArray[1], daysArray[1]);
  		let stayLength = (checkOutDate - checkInDate) / (1000*60*60*24);

			if (stayLength > 2)
			{
				for (let j=1; j<=stayLength; j++)
		  		{
		  			let stayDay = new Date(checkInDate);
		  			stayDay.setDate(checkInDate.getDate() + j);
		  			//console.log("stayDay " + stayDay.getMonth() + ", month: " + this.state.currentMonth);
						 let month = stayDay.getMonth();
						 let day = stayDay.getDate();
						 //console.log("wewn: " + day);
						 monthArray[j] = month;
						 daysArray[j] = day;
			
						//console.log("zewn: " + day)
		  			
		  		}
			  	//console.log("booked for this month: " + days);
			}
			else
			{
				daysArray = [null, null];
				monthArray = [null, null];
				this.setState({
					selectedDays: daysArray,
					selectedDayCount: 0,
					selectedMonths: monthArray,
					freeDayButtonClass: 'btn btn-danger'
					})

				setTimeout(() => {
				  	this.setState({
							freeDayButtonClass: 'btn btn-outline-secondary'
						})
					},
					500)
			}
			//console.log(daysArray);
			var selectedDayCount = --this.state.selectedDayCount;
			let bookings = []
			if (this.props.bookings)
			{
				bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))})
			}
			let calendar = generateCalendar(this.state.currentMonth, this.state.currentYear, bookings, daysArray);

			this.setState({
			selectedDays: daysArray,
			selectedMonths: monthArray,
			selectedDayCount,
			calendar
			});
		}
		else if (this.state.selectedDayCount === 2 && !isNaN(parseInt(value)) && this.props.roomCalendar )
		{	
			var daysArray = [value, null];
			var monthArray = [name, null];
			var selectedDayCount = --this.state.selectedDayCount;
			let bookings = [];
			if (this.props.bookings)
			{
				bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))})
			}
			let calendar = generateCalendar(this.state.currentMonth, this.state.currentYear, bookings);

			this.setState({
			selectedDays: daysArray,
			selectedMonths: monthArray,
			selectedDayCount,
			calendar
			});

		}	
		else if(isNaN(value) === true && this.props.roomCalendar)	
		{
			var daysArray = [null, null];
			var selectedDayCount = 0;
			let bookings = [];
			if (this.props.bookings)
			{
				bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))})
			}
			let calendar = generateCalendar(this.state.currentMonth, this.state.currentYear, bookings);

			this.setState(
			{
				selectedDays: daysArray,
				freeDayButtonClass: 'btn btn-danger',
				selectedDayCount,
				calendar
			}) 
				
			setTimeout(() => {
			  	this.setState({
						freeDayButtonClass: 'btn btn-outline-secondary'
					})
				},
				250)
		}
	}

	sendArray(){
		let dateArray = [
		parseInt(this.state[this.props.type+'Day']),
		parseInt(document.getElementById(this.props.type+'Month').value),
		parseInt(document.getElementById(this.props.type+'Year').value)
		];
		dateArray[1]++;
		//console.log(dateArray);
		this.props.returnValueFromDatepicker(dateArray, this.props.type);
	}

	createMonthView(){
		if (this.props.roomCalendar)
		{this.props.getCurrentMonth(this.state.currentMonth)}

		let bookings = []
		if (this.props.bookings)
		{
			bookings = this.props.bookings.filter((book)=>{return ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))})
		}
		let calendar = generateCalendar(this.state.currentMonth, this.state.currentYear, bookings);
		
		this.setState({
    	calendar 
    }) 
	}


	componentDidMount(){
			this.createMonthView();
  }
	
	render() {
		let freeDayButtonClass = this.state.freeDayButtonClass;
		let selectedDayButtonClass = 'btn btn-secondary';
		let unselectedDayButtonClass = 'btn btn-outline-light';
		let bookedDayButtonClass = 'btn btn-light';
		let navigationButtonClass = 'btn btn-outline-' + this.props.color + ' col-6';
		const bookingsCalendar = this.props.usage === 'bookingsCalendar' ? true : false;
		const selectMonth = 
        <select 
        className='form-control col-6'
        value={this.state.currentMonth} 
        name={this.props.type+'Month'} 
        id={this.props.type+'Month'} 
        onChange={this.jump}>
      		{this.state.monthHeaders.map((month, index)=>
      			<option 
      			value={index} 
      			key={index}>
      				{month}
      			</option>
      		)}
        </select>;

	  const selectYear = 
      <select 
      className='form-control col-6' 
      name={this.props.type+'Year'} 
      id={this.props.type+'Year'} 
      onChange={this.jump}>
        <option
        value={this.state.selectableYears[0]}>
        	{this.state.selectableYears[0]}
        </option>
        <option 
        value={this.state.selectableYears[1]}>
        	{this.state.selectableYears[1]}
        </option>
      </select>;			  
		const datePickerControlButtons = <div id='buttonsGroup' style={{position: 'absolute', right: '0'}}>
    	<button className='btn btn-outline-success'  onClick={this.sendArray} style={{position: 'absolute', right: 60}}>OK</button>
    	<button id={this.props.type} className='btn btn-outline-danger' onClick={this.props.closeDatepicker}>X</button>
		</div>;
		const datePickerHeader =<div className='form-inline'>{selectMonth}{selectYear}{datePickerControlButtons}</div>
		const weekDayTableHeader = <thead>
		<tr>
			{this.state.weekDayHeaders.map((weekDayHeader, index)=>(			                
		  <th style={{textAlign: 'center'}} key={index}>{weekDayHeader}</th>
		  ))}
		</tr>
		</thead>;


		const calendarBody = <tbody id='calendar-body' >
		{this.state.calendar.map((week, index)=>(
  		<tr key={index}>
  			{week.map((day, idx)=>
  			(
  				(day===0)
  				?<td key={idx}>{''}</td>
  				:<td key={idx} style={{textAlign: 'center', margin:0, padding: 0}}>
      				<input type='button' 
      				 value={day==='X' ? '' : (day ==='O' ? '' : day)} 
      				 name={this.state.currentMonth}
      				 id={day==='X' ? 'booked' : this.state.currentYear + '/' + this.state.currentMonth+ '/' + day}
      				 style={{height:"100%", width: "100%", fontSize: '20px'}}
      				 
      				 className={(day==='X' ? bookedDayButtonClass : (day ==='O' && this.props.roomId) ? selectedDayButtonClass : freeDayButtonClass) }
      				 onClick={(this.props.roomCalendar && this.selectDay) || ((this.props.datePicker && this.props.returnValueFromDatepicker))} />      				 
      				 </td>
  			))}
  			</tr>
      ))}		                   
		</tbody>;
		const calendarNavigation = <div className='form-inline'>
		    <button className={navigationButtonClass} id='previous' onClick={this.previous}>Poprzedni</button>
		    <button className={navigationButtonClass} id='next' onClick={this.next}>Następny</button>
		</div>;
		const roomCalendarHeader = <div>
			<div className='form-inline'>
			{selectMonth}{selectYear}
			</div><br/>
			{calendarNavigation}
		</div>;		        			        
      

		return (
			<div className="calendar">
			  <div className='card'>
			  	<div className='card-header'>
     				{this.props.datePicker && datePickerHeader}
						{this.props.roomCalendar && roomCalendarHeader}
     			</div>		     			        
	        <div className='card-body'>
	        	<table className='table'
	        	id='calendar'>
	        		{weekDayTableHeader}
	            {calendarBody}
	        	</table>
	        	{this.props.bookButton}	               	
	      	</div>
	    	</div>
			</div>
		);
	}
}



export default Calendar;