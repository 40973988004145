import React, { Component } from 'react';
import LocalizationDescription from './LocalizationDescription';
import LocalizationDirection from './LocalizationDirection';
import './../../../stylesheets/localization_page.css';
import './../../../stylesheets/contact_page.css';

class LocalizationPage extends Component {
	render() {
		return (
			<div className="localizationPage">
				<div className='row'>
					<div className='col-12 localizationHeader'></div>
					</div>
				<div className='row'>
					<div className='col-sm-6 col-12'><LocalizationDescription/></div>
					<div className='col-sm-6 col-12'><LocalizationDirection/></div>
				</div>
			</div>
		)
	}
}

export default LocalizationPage;