import React, { Component } from 'react';
import BookingFormConfirmation from './BookingFormConfirmation';
import BookingForm from './BookingForm';


class BookingPage extends Component {
	constructor () {
		super();
		this.state = {
			bookingFormView: true,
			bookingFormConfirmView: false,
			contactMessageId: null,
			contactMessageTopic: 1,
		}
		this.setBookingFormView = this.setBookingFormView.bind(this);
		this.setBookingFormConfirmView = this.setBookingFormConfirmView.bind(this);
		this.handleMessageId = this.handleMessageId.bind(this);
	}

	setBookingFormView() {
		if (this.state.bookingFormConfirmView === false)
		{
			this.setState({
	  		bookingFormView: true,
	  		bookingFormConfirmView: false
	  	});
		}
		else
		{
	  	this.setState({
	  		bookingFormView: false,
	  		bookingFormConfirmView: false
	  	});
	  }
  }

	setBookingFormConfirmView() {
  	this.setState({
  		bookingFormView: false,
  		bookingFormConfirmView: true
  	})
  }

  handleMessageId(value) {
  	this.setState({
  		contactMessageId: value
  	});
  }
	render() {
		return (
			<div>
			{this.state.bookingFormView && 
				<BookingForm {...this.props}
					setBookingFormConfirmView={this.setBookingFormConfirmView} 
					handleMessageId={this.handleMessageId}  />}
				{this.state.bookingFormConfirmView &&
					<div>
						<BookingFormConfirmation
						newBookingModeSwitch={this.props.newBookingModeSwitch} 
						contactMessageId={this.state.contactMessageId}/>
					</div>}
			</div>
		)
	}
}

export default BookingPage;