import React, { Component } from 'react';
import Gallery from './../../universal/gallery/Gallery';
import './../../../stylesheets/localization_page.css';


class LocalizationDirection extends Component {
		constructor () {
		super();
		this.state = {
				title: 'Kwatery Laskowscy Międzyzdroje',
				photos: [
				{file: 'kwatery-laskowscy-21.jpg', fill: 100, x: 0, y:10},
				{file: 'kwatery-laskowscy-22.jpg', fill: 100, x: 0, y:10},
				{file: 'pasazpodgryfem.jpg', fill: 190, x: 100, y:10},	
				{file: 'kwatery-laskowscy-222.jpg', fill: 150, x: 50, y:10}, 
				{file: 'kwatery-laskowscy-101.jpg', fill: 180, x: 50, y:90}, 
				{file: 'kwatery-laskowscy-403.jpg', fill: 180, x: 50, y:10}, 
				{file: 'kwatery-laskowscy-303.jpg', fill: 180, x: 10, y:10},
				{file: 'kwatery-laskowscy-103.jpg', fill: 180, x: 10, y:10},
				{file: 'kwatery-laskowscy-221.jpg', fill: 150, x: 50, y:10},
				{file: 'kwatery-laskowscy-100.jpg', fill: 180, x: 10, y:10},
				{file: 'kwatery-laskowscy-220.jpg', fill: 150, x: 50, y:10},
				{file: 'kwatery-laskowscy-203.jpg', fill: 180, x: 50, y:10},
			]
		}
	}
	render() {
		return (
			<div className='row localization-direction'>
				<div className='col-12'>
					<Gallery 
						photos={this.state.photos} 
						title={this.state.title}
						folderName='localization'
					/>
				</div>
			</div>
		)
	}
}

export default LocalizationDirection;