import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';


class RoomOptions extends Component {
	render() {
		return (
			<div><b>RoomOptions</b></div>
		)
	}
}

export default RoomOptions;