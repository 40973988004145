import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AboutDescription from './AboutDescription'
import AboutImages from './AboutImages'

import './../../../../stylesheets/about_page.css';

class AboutPage extends Component {
		constructor () {
		super();
		this.state = {
				garden: {
				photos: [
				{file: 'kwatery-laskowscy-15.jpg', fill: 150, x: 10, y:50},
				{file: 'kwatery-laskowscy-16.jpg', fill: 100, x: 10, y:80}, 
				{file: 'kwatery-laskowscy-2.jpg', fill: 150, x: 10, y:65},
				{file: 'kwatery-laskowscy-22.jpg', fill: 150, x: 85, y:80},
				{file: 'kwatery-laskowscy-24.jpg', fill: 150, x: 85, y:30},
				{file: 'kwatery-laskowscy-23.jpg', fill: 150, x: 50, y:30},
				{file: 'kwatery-laskowscy-44.jpg', fill: 100, x: 10, y:10},
				{file: 'kwatery-laskowscy-35.jpg', fill: 150, x: 10, y:80},
				{file: 'kwatery-laskowscy-37.jpg', fill: 150, x: 10, y:10},
				{file: 'kwatery-laskowscy-20.jpg', fill: 120, x: 100, y:60},
				]
			},
		}
		
	}
	render() {
		return (
			<div className="row AboutPage">
				<div className="AboutImages col-lg-4 col-12">
					<AboutImages photos={this.state.garden.photos} title="Ogród" folderName="about"/></div>
				<div className="col-lg-8 col-12">
					<AboutDescription/>
					</div>
			</div>
		)
	}
}

export default AboutPage;


					