import React, { Component } from 'react';
//import MenuItem from './MenuItem';
import 'bootstrap/dist/css/bootstrap.css';
import './../../../stylesheets/header.css';
class HeaderAdmin extends Component {
		constructor () {
		super();
		this.state = {
		views: {
			BookingFormMessagesPage: false,
			ContactFormMessagesPage: false,
			BookingCalendarPage: false,
			UsersPage: true,
			SettingsPage: false,
		},
		}
		this.activateView = this.activateView.bind(this);
	}
	activateView (event)
	{
		const activatedView = {[event.target.name]: true};
		this.props.handleViewChange(activatedView);
	}

	render() {
		return (
			<nav className="navbar navbar-expand-lg navbar-light" style={{backgroundColor: "#FFF", width: "100%", height: "auto", margin: "0", padding: "0", position: "absolute", left: "0"}}>
			 	<div className='phonenumber'>
				 	<a className="navbar-brand" href="/lc-control"><img 
			 		src="logo.png" 
			 		height="45px" 
			 		width="45px"
			 		alt="Logo Kwatery-Laskowscy"/></a>
		 			<h2 className='lccontrol'>Kwatery Laskowscy - LC-CONTROL</h2>
		 		</div>
			  <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
			    <span className="navbar-toggler-icon"></span>
			  </button>

			  <div className="collapse navbar-collapse" id="navbarSupportedContent">
			    <ul className="navbar-nav mr-auto">
			      <li className="nav-item">
			        <a className={`nav-link ${this.props.views.ContactFormMessagesPage ? "active" : ""}`} style={{cursor: 'pointer'}} role="button" data-toggle="collapse" data-target=".navbar-collapse.show" name="ContactFormMessagesPage" onClick={this.activateView} >Wiadomości<span style={{position: 'relative', left: '3px', bottom: '3px'}} class="badge badge-pill badge-dark">{this.props.counters ? this.props.counters.messages : '' }</span></a>			      </li>
			      <li className='nav-item'>
			        <a className={`nav-link ${this.props.views.BookingFormMessagesPage ? "active" : ""}`} style={{cursor: 'pointer'}} role="button" data-toggle="collapse" data-target=".navbar-collapse.show" name="BookingFormMessagesPage" onClick={this.activateView} >Zapytania o rezerwacje<span style={{position: 'relative', left: '3px', bottom: '3px'}} class="badge badge-pill badge-dark">{this.props.counters ? this.props.counters.booking_room_1+this.props.counters.booking_room_2+this.props.counters.booking_room_3 : ''}</span></a>
			      </li>
				  <li className="nav-item">
			        <a className={`nav-link ${this.props.views.BookingCalendarPage ? "active" : ""}`}  style={{cursor: 'pointer'}}data-toggle="collapse" data-target=".navbar-collapse.show" name="BookingCalendarPage"  onClick={this.activateView}>Kalendarz</a>
			      </li>
			      <li className="nav-item">
			        <a className={`nav-link ${this.props.views.BookingListPage ? "active" : ""}`}  style={{cursor: 'pointer'}}data-toggle="collapse" data-target=".navbar-collapse.show" name="BookingList"  onClick={this.activateView}>Lista rezerwacji</a>
			      </li>
			      <li className="nav-item">
			        <a className={`nav-link ${this.props.views.RoomSettings ? "active" : ""}`} style={{cursor: 'pointer'}} role="button" data-toggle="collapse" data-target=".navbar-collapse.show" name="RoomSettings" onClick={this.activateView} >Ustawienia oferty</a>
			      </li>
			    	<li className="nav-item">
			        <a className={`nav-link ${this.props.views.UserSettings ? "active" : ""}`}  style={{cursor: 'pointer'}} data-toggle="collapse" data-target=".navbar-collapse.show" name="UserSettings" onClick={this.activateView}>Ustawienia Użytkownika</a>
			      </li>
				  </ul>
			  </div>
			  <button type="button" className='btn btn-secondary col-1' onClick={this.props.handleLogout}>
			    Wyloguj
			  </button>
			</nav>
		) 
/*
		this.props.rooms.map(room=>(
			<MenuItem 
				key={room.id} 
				room={room} 
				selected={this.props.selected}  
				menuHover= {this.props.menuHover} 
				menuHoverOff = {this.props.menuHoverOff} 
				menuHoverOn = {this.props.menuHoverOn}
				promoHover= {this.props.promoHover} 
				promoHoverOff = {this.props.promoHoverOff} 
				promoHoverOn = {this.props.promoHoverOn}  
				showRoomDetails = {this.props.showRoomDetails} />
		));*/
	}
}

export default HeaderAdmin;