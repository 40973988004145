import React, { Component } from 'react';
import UserSettings from './UserSettings';


class UserSettingsContainer extends Component {
    constructor(){
        super();
        this.state = {
            user: false
        }
        this.downloadUserData = this.downloadUserData.bind(this);
    }
    downloadUserData(){
        const data = {...this.props.user};
        fetch('https://164.132.113.224/api/dud', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        }).then((res) => res.json())      
        .then((json) => { 
            this.setState({
                user: {
                    ...json
                }
            })
        })
    }
    componentDidMount(){
        this.downloadUserData();
    }
    render() {
        const user = this.state.user ? this.state.user : {};
		return (
			<UserSettings user={user}/>
		)
	}
}

export default UserSettingsContainer;