import React, { Component } from 'react';



function ShowSaveButton(props) {
	return (
		<div className="card-text">
			<button onClick={props.handleSaveToDB}>Zapisz rezerwację</button>
			<button onClick={props.correctBooking}>Popraw rezerwację</button>
		</div>
	)
}

function ShowWarningButton(props) {
	return (
		<div className="card-text">
			<p>UWAGA: Dzień zakończenia rezerwacji jest wcześniej niż dzień rozpoczęcia rezerwacji. Sprawdź daty.</p>
			<button onClick={props.correctBooking}>Popraw rezerwację</button>
		</div>
	)
}

function ToggleButton(props){
	if (((props.newBooking.endDay <= props.newBooking.startDay) && 
		(props.newBooking.endMonth <= props.newBooking.startMonth) && 
		(props.newBooking.endYear <= props.newBooking.startYear)) ||
		 ((props.newBooking.endMonth < props.newBooking.startMonth) &&
		 (props.newBooking.endYear <= props.newBooking.startYear)))
	{
		return <ShowWarningButton correctBooking={props.correctBooking} />
	}
	else
	{
		return <ShowSaveButton handleSaveToDB={props.handleSaveToDB} correctBooking={props.correctBooking} newBooking={props.newBooking}/>
	}
}

class NewBookingConfirmForm extends Component {
	handleSaveToDB = () => {
    this.props.saveDataToDB(this.props.newBooking);
  }
render (){
		return (
			<div>
				<div className="card">
					<h3 className="card-header">Nowa rezerwacja: </h3>
					<div className="card-body">
						<p>Dzień rozpoczęcia: {this.props.newBooking.startDay}/{this.props.newBooking.startMonth}/{this.props.newBooking.startYear}</p>
						<p>Dzień zakończenia: {this.props.newBooking.endDay}/{this.props.newBooking.endMonth}/{this.props.newBooking.endYear}</p>
						<p>Rezerwowany pokój: {this.props.newBooking.roomId}</p>
					</div>
					<ToggleButton handleSaveToDB={this.handleSaveToDB} correctBooking={this.props.correctBooking} newBooking={this.props.newBooking} />
				</div>
			</div>	
		);
	}
}

export default NewBookingConfirmForm;						