import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import BookingSearchBox from './BookingSearchBox';
import BookingsList from './BookingsList';
import BookingForm from './BookingForm';
import Calendar from './Calendar';

class BookingsAdminPage extends Component {

	constructor (props) {
		super(props);
		this.state = {
		}
		this.setSearchFilter = this.setSearchFilter.bind(this);
		this.filterList = this.filterList.bind(this);
		this.setBookingConfirmed = this.setBookingConfirmed.bind(this);
		this.setBookingArchived = this.setBookingArchived.bind(this);
		this.setBookingCanceled = this.setBookingCanceled.bind(this);
		this.setBookingDeleted = this.setBookingDeleted.bind(this);
		this.setRestoreBooking = this.setRestoreBooking.bind(this);
	}

	setBookingConfirmed(event) {
	  const value = event.target.id;

	  console.log("Event.target.id: " + event.target.id);
	  const data = {...this.props.loggedUser, bookingId: value};
	  console.log(data);
	  event.preventDefault();
	  fetch('/api/sbc', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => res.json())      
    .then((json) => {
    	console.log(json);
    	let confirmedBooking = this.state.unconfirmed.filter( function (booking)
	    {
	    	if(booking.bookingId === json.bookingId)
	    	{
	    		return booking;
	    	}	    	
	    });
	    let unconfirmedBookings = this.state.unconfirmed.filter( function (booking)
	    {
	    	if(booking.bookingId !== json.bookingId)
	    	{	    	
	    		return booking;
	    	}	    	
	    });

    	if (confirmedBooking && unconfirmedBookings)
    	{  		
	    	confirmedBooking[0].confirmed = true;
    		var confirmed = this.state.confirmed;

    		confirmed.push(confirmedBooking[0]);
    		var unconfirmed = unconfirmedBookings;
    		this.setState({
    			...this.state,
    			confirmed,
    			unconfirmed,
    		})
    	}
    })
  }

 	setBookingCanceled(event) {
	  const value = event.target.id;
	  //console.log("Event.target.id: " + event.target.id);
	  const data = {...this.props.loggedUser, bookingId: value};
	  //console.log(data);
	  event.preventDefault();
	  fetch('/api/sbcancel', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => res.json())      
    .then((json) => {
    	console.log(json);
    	let canceledBooking = this.state.confirmed.filter( function (booking)
	    {
	    	if(booking.bookingId === json.bookingId)
	    	{
	    		return booking;
	    	}	    	
	    });
	    let confirmedBookings = this.state.confirmed.filter( function (booking)
	    {
	    	if(booking.bookingId !== json.bookingId)
	    	{	    	
	    		return booking;
	    	}	    	
	    });

    	if (canceledBooking && confirmedBookings)
    	{  		
	    	canceledBooking[0].canceled = true;
    		var canceled = this.state.canceled;

    		canceled.push(canceledBooking[0]);
    		var confirmed = confirmedBookings;
    		this.setState({
    			...this.state,
    			canceled,
    			confirmed,
    		})
    	}
    })
  }

 	setBookingArchived(event) {
	  const value = event.target.id;
	  //console.log("Event.target.id: " + event.target.id);
	  const data = {...this.props.loggedUser, bookingId: value};
	  //console.log(data);
	  event.preventDefault();
	  fetch('/api/sba', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => res.json())      
    .then((json) => {
    	console.log(json);
    	let archivedBooking = this.state.confirmed.filter( function (booking)
	    {
	    	if(booking.bookingId === json.bookingId)
	    	{
	    		return booking;
	    	}	    	
	    });
	    let confirmedBookings = this.state.confirmed.filter( function (booking)
	    {
	    	if(booking.bookingId !== json.bookingId)
	    	{	    	
	    		return booking;
	    	}	    	
	    });

    	if (archivedBooking && confirmedBookings)
    	{  		
	    	archivedBooking[0].archived = true;
    		var archived = this.state.archived;

    		archived.push(archivedBooking[0]);
    		var confirmed = confirmedBookings;
    		this.setState({
    			...this.state,
    			archived,
    			confirmed,
    		})
    	}
    })
  }

  setBookingDeleted(event) {
	  const value = event.target.id;
	  //console.log("Event.target.id: " + event.target.id);
	  const data = {...this.props.loggedUser, bookingId: value};
	  //console.log(data);
	  event.preventDefault();
	  fetch('/api/sbd', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => res.json())      
    .then((json) => {
    	console.log(json);
    	if (json.confirmed === true)
    	{
    		if (json.archived === true)
    		{
    			let deletedBooking = this.state.archived.filter( function (booking)
			    {
			    	if(booking.bookingId === json.bookingId)
			    	{
			    		return booking;
			    	}	    	
			    });
			    let archivedBookings = this.state.archived.filter( function (booking)
			    {
			    	if(booking.bookingId !== json.bookingId)
			    	{	    	
			    		return booking;
			    	}	    	
			    });
			    if (deletedBooking && archivedBookings)
		    	{  		
			    	deletedBooking[0].deleted = true;
		    		var deleted = this.state.deleted;

		    		deleted.push(deletedBooking[0]);
		    		var archived = archivedBookings;
		    		this.setState({
		    			...this.state,
		    			archived,
		    			deleted,
		    		})
		    	}
    		}
    		if (json.canceled === true)
    		{
    			let deletedBooking = this.state.canceled.filter( function (booking)
			    {
			    	if(booking.bookingId === json.bookingId)
			    	{
			    		return booking;
			    	}	    	
			    });
			    let canceledBookings = this.state.canceled.filter( function (booking)
			    {
			    	if(booking.bookingId !== json.bookingId)
			    	{	    	
			    		return booking;
			    	}	    	
			    });
			    if (deletedBooking && canceledBookings)
		    	{  		
			    	deletedBooking[0].deleted = true;
		    		var deleted = this.state.deleted;

		    		deleted.push(deletedBooking[0]);
		    		var canceled = canceledBookings;
		    		this.setState({
		    			...this.state,
		    			canceled,
		    			deleted,
		    		})
		    	}
    		}
    		if (json.archived === false && json.canceled === false)
    		{
    			let deletedBooking = this.state.confirmed.filter( function (booking)
			    {
			    	if(booking.bookingId === json.bookingId)
			    	{
			    		return booking;
			    	}	    	
			    });
			    let confirmedBookings = this.state.confirmed.filter( function (booking)
			    {
			    	if(booking.bookingId !== json.bookingId)
			    	{	    	
			    		return booking;
			    	}	    	
			    });

		    	if (deletedBooking && confirmedBookings)
		    	{  		
			    	deletedBooking[0].deleted = true;
		    		var deleted = this.state.deleted;

		    		deleted.push(deletedBooking[0]);
		    		var confirmed = confirmedBookings;
		    		this.setState({
		    			...this.state,
		    			deleted,
		    			confirmed,
		    		})
		    	}
    		}
    	}
    	else
    	{
    		if (json.confirmed === false)
    		{
    			let deletedBooking = this.state.unconfirmed.filter( function (booking)
			    {
			    	if(booking.bookingId === json.bookingId)
			    	{
			    		return booking;
			    	}	    	
			    });
			    let unconfirmedBookings = this.state.unconfirmed.filter( function (booking)
			    {
			    	if(booking.bookingId !== json.bookingId)
			    	{	    	
			    		return booking;
			    	}	    	
			    });

		    	if (deletedBooking && unconfirmedBookings)
		    	{  		
			    	deletedBooking[0].deleted = true;
		    		var deleted = this.state.deleted;

		    		deleted.push(deletedBooking[0]);
		    		var unconfirmed = unconfirmedBookings;
		    		this.setState({
		    			...this.state,
		    			deleted,
		    			unconfirmed,
		    		})
		    	}
    		}
    	}
    	
    })
  }

  setRestoreBooking(event) {
	  const value = event.target.id;
	  //console.log("Event.target.id: " + event.target.id);
	  const data = {...this.props.loggedUser, bookingId: value};
	  //console.log(data);
	  event.preventDefault();
	  fetch('/api/srb', {
	    method: 'POST',
	    headers: {
	      'Accept': 'application/json',
	      'Content-Type': 'application/json'
	    },
    	body: JSON.stringify(data)
  	})
    .then((res) => console.log(res))
  }


	setSearchFilter(searchObject, collectionName)
	{
		let filters = {};
		if (searchObject)
		{
			if (searchObject.startDay !== null && searchObject.startDay !== "0")
			{
				filters.startDay = searchObject.startDay;
			}
			if (searchObject.startMonth !== null)
			{
				filters.startMonth = searchObject.startMonth;
			}
			if (searchObject.startYear !== null)
			{
				filters.startYear = searchObject.startYear;
			}
			if (searchObject.endDay !== null && searchObject.endDay !== "0")
			{
				filters.endDay = searchObject.endDay;
			}
			if (searchObject.endMonth !== null)
			{
				filters.endMonth = searchObject.endMonth;
			}
			if (searchObject.endYear !== null)
			{
				filters.endYear = searchObject.endYear;
			}
			if (searchObject.roomId !== null && searchObject.roomId !== "all")
			{
				filters.roomId = searchObject.roomId;
			}
			if (searchObject.roomId === "all")
			{
				delete filters.roomId;
			}
			this.filterList(filters, collectionName);
		}
		else
		{
			this.filterList(filters, collectionName);
		}	
	}

	filterList(filtersObject, collectionName){
  	let collections = {
	  	unconfirmed: {confirmed: false, archived: false, canceled: false, deleted: false},
	  	confirmed: {confirmed:true, archived: false, deleted: false, canceled: false},
	  	canceled: {canceled: true, archived: false, deleted: false},
	  	archived: {archived: true, deleted: false},
	  	deleted: {deleted: true}
  	}
  	
  	let filters = {};
  	if (collectionName)
  	{
  		collections = collections[collectionName];
  		filters = {
  			...filters,
  			...collections
  		}
  	}
  	console.log(filters);
  	
  	for (let [key, value] of Object.entries(filtersObject)) {
  		if (value !== null)
  		{
  			filters = {
  				...filters,
  				[key] : value,
  			}
  			console.log(filters);
  		}
		}

		let filteredBookings;
			filteredBookings = this.props.userData.bookings.filter(function(book) {
		  	for (var key in filters) {
			    if (book[key] != filters[key])
			    {
			    	return false;
			    }
			  }
			  return true;
			});				
	    this.setState({
	    	[collectionName]: filteredBookings});	
  }

  componentDidMount(){

  	//this.setSearchFilter();
  	let filters = [{},{},{},{},{}];
  	let collection = ["unconfirmed","confirmed", "canceled", "archived", "deleted"];
  	filters.map((filter, index)=>
  		{
  			this.filterList(filter, collection[index]);
  		});
  }


	render() {
		return (
			<div>
				<Calendar rooms={this.props.userData.rooms} confirmed={this.state.confirmed} unconfirmed={this.state.unconfirmed}  archived={this.state.archived}/>
				<br/>
				<BookingForm 
				userData={this.props.userData}/>
				<br/>							
				{this.state.unconfirmed &&
				 <BookingsList loggedUser={this.props.loggedUser} bookingListType="unconfirmed" setBookingConfirmed={this.setBookingConfirmed} setBookingDeleted={this.setBookingDeleted} setSearchFilter={this.setSearchFilter} bookings={this.state.unconfirmed}/>
    		}
    		{this.state.confirmed &&
				 <BookingsList loggedUser={this.props.loggedUser} bookingListType="confirmed" setBookingArchived={this.setBookingArchived} setBookingCanceled={this.setBookingCanceled} setBookingDeleted={this.setBookingDeleted} setSearchFilter={this.setSearchFilter} bookings={this.state.confirmed}/>
    		}
    		{this.state.canceled &&
				 <BookingsList loggedUser={this.props.loggedUser} bookingListType="canceled" setBookingArchived={this.setBookingArchived} setBookingDeleted={this.setBookingDeleted} setSearchFilter={this.setSearchFilter} bookings={this.state.canceled}/>
    		}
    		{this.state.archived &&
				 <BookingsList loggedUser={this.props.loggedUser} bookingListType="archived" setBookingDeleted={this.setBookingDeleted} setSearchFilter={this.setSearchFilter} bookings={this.state.archived}/>
    		}
 				{this.state.deleted &&
				 <BookingsList loggedUser={this.props.loggedUser} bookingListType="deleted" setRestoreBooking={this.setRestoreBooking} setSearchFilter={this.setSearchFilter} bookings={this.state.deleted}/>
    		}

							
			</div>
		)
	}
}

export default BookingsAdminPage;