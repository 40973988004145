//Lista wyszczególniająca wszystkie rezerwacje wszystkich kategorii

import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import BookingListItem from './BookingListItem';



class BookingListWrapper extends Component {
	render() {
		return (
			<div> 
				{this.props.bookings.map((booking, index)=>(<BookingListItem showDetailsOfBooking={this.props.showDetailsOfBooking} booking={booking} index={index}/>))}
			</div>
		)
	}
}

export default BookingListWrapper;