import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './../../../../stylesheets/pricelist.css';

class RoomPriceTag extends Component {
		constructor (props) {
		super(props);
		this.state = {
		}
	}
	render() {
		const shapeColor = "offer offer-" + this.props.color;
    return (
				<div>
					<div className={shapeColor}>
						<div className="shape_lt">
						</div>
						<div className="shape_rt">
						</div>
						<hr className={shapeColor}/>
						<div 
						className="offer-content">
						<div className="row">
							<div className="col-4">
							<h2 
							className="lead" >
								Cena: 
							</h2>
							</div>
							<div className="col-8">
							<p>
								{this.props.pricesTable[this.props.currentMonth]}{this.props.pricesPer[this.props.currentMonth] === "person" ?  " zł / osoba" : " zł / pokój"}
							</p>
							{<p>
								{this.props.pricesPer[this.props.currentMonth] === "person" ? parseInt(this.props.pricesTable[this.props.currentMonth])/2 + " zł dziecko do lat 7" : ""}
							</p>}
							</div>
						</div>
						</div>
						<div className="shape_lb">
						</div>
						<div className="shape_rb">
						</div>
					</div>
				</div>
		)
	}
}

RoomPriceTag.propTypes ={
  pricesTable: PropTypes.arrayOf(PropTypes.string),
  pricesPer: PropTypes.arrayOf(PropTypes.string),
  color: PropTypes.string,
  currentMonth: PropTypes.number
}

export default RoomPriceTag;