import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Calendar from './../../universal/booking_calendar/DemoBookingsCalendarContainer';


class BookingFormMessagesList extends Component {
	constructor() {
		super();
		this.state = {
		}
	  
		this.acceptBooking = this.acceptBooking.bind(this);
		this.refuseBooking = this.refuseBooking.bind(this);
		this.setBookingAsRead = this.setBookingAsRead.bind(this);
	}
	localizeDateStr(date_to_convert_str) 
	{
		var date_to_convert = new Date(date_to_convert_str);
		var dateArray = [''+date_to_convert.getDate(), date_to_convert.getMonth()+1+'', ''+date_to_convert.getFullYear(), ''+date_to_convert.getHours(), ''+date_to_convert.getMinutes(), ''+date_to_convert.getSeconds()];
		dateArray = dateArray.map((dateElement)=>{
			if(dateElement.length<2)
			{
			return '0'+dateElement;
			}
			else
			{
			return dateElement;
			}
		});
		var dateString = dateArray[0] + "/" + dateArray[1] + "/" + dateArray[2] + " " + dateArray[3] + ":" + dateArray[4] + ":" +dateArray[5];
		return dateString; 	
	}
 	
 	setBookingAsRead(event){
 		this.props.setMessageAsRead(event);
 	}

 	acceptBooking(event) {
    const messageId = event.target.name;
	const data = {...this.props.user, messageId: messageId};
	console.log(data);
    fetch('https://164.132.113.224/api/ab', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => res.json())      
    .then((json) => {
		let newEvent = {event: {target: {id: messageId}}};
		this.setBookingAsRead(...newEvent);
    	//console.log(json);
    })
  }

  refuseBooking(event) {
    const messageId = event.target.name;
	const data = {...this.props.user, messageId: messageId};
    fetch('https://164.132.113.224/api/rb', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => res.json())      
    .then((json) => {
    	//console.log(json);
    })
  }

 	componentWillMount(){
		const bookings = this.props.bookings ? this.props.bookings : [];
		const messages = this.props.messages ? this.props.messages : [];
		this.setState({
			bookings: bookings,
			messages: messages
		})
 	}

	render() {
		const months=['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];
		const bookingColor = (booking)=>{
			//console.log(booking);
			if(booking.length > 0)
			{
				switch (booking[0].bookingType) {
					case 'canceled':
						return '#CC151A'
						break;
					case 'confirmed':
						return '#00FF00'
						break;
					case 'unconfirmed':
						return '#0000FF'
						break;
					default:
						return '#000'
						break;
				}
			}
			else
			{
				return '#000';
			}
		}
		const daysBetween = function(date1, date2){	
			if (date1 && date2)
			{
			  //Get 1 day in milliseconds
			  var one_day=1000*60*60*24;
		
			  // Convert both dates to milliseconds
			  var date1_ms = date1.getTime();
			  var date2_ms = date2.getTime();
		
			  // Calculate the difference in milliseconds
			  var difference_ms = date2_ms - date1_ms;
				
			  // Convert back to days and return
			  return Math.round(difference_ms/one_day); 
			}
			else
			{
				return false;
			}
		}
		return (
			<div className={"col-9"}>
				<div className={'row'}>	
					<div className={"col-4 room"+this.props.room.roomId +'-main-bg'}>
						{this.props.messages && this.props.messages.map((message, index)=>(
						<div className={this.props.rooms ? "card bg-"+this.props.rooms[message.contactBooking.roomId-1].cssColor : "card"} key={index}>
							<a 
							id={message.contactMessageId}
							data-toggle="collapse" 
							href={"#messagesFullMessage"+message.contactMessageId} 
							role="button" 
							onClick={this.props.setMessageAsRead} 
							aria-expanded="false" aria-controls={"messagesFullMessage"+message.contactMessageId}>
								<div className="card-header" id={message.contactMessageId}  style={{ fontWeight: message.contactUnread === true ? '700': '400', color: bookingColor(this.props.bookings.filter((booking) => {return booking.bookingId === message.bookingId }))  , display: message.accepted[0] === false ?'none': ''}}>
									{!message.bookingId && <p>{message.contactBooking.checkin.day + " " + months[message.contactBooking.checkin.month] + " " + message.contactBooking.checkin.year}{" - "} {message.contactBooking.checkout.day + " " + months[message.contactBooking.checkout.month] + " " + message.contactBooking.checkout.year}</p>}
									{message.bookingId && <p>{'bookingId: ' +message.bookingId}</p>}
								</div>
							</a>
							<div className=" col-auto collapse" id={"messagesFullMessage"+message.contactMessageId}>
								<div className="card-body">
									<div className="card-title" style={{marginBottom: '50px'}}>
										<p>{"Liczba gości: "}{message.contactBooking.guestsQuantity}{message.contactBooking.guestsChildQuantity && " / dzieci: "+ message.contactBooking.guestsChildQuantity}{message.contactBooking.guestsCar && " + samochód"}</p>
										<p>{daysBetween(new Date(message.contactBooking.checkin.year, message.contactBooking.checkin.month, message.contactBooking.checkin.day), new Date(message.contactBooking.checkout.year, message.contactBooking.checkout.month, message.contactBooking.checkout.day)) + ' dni pobytu'}</p>
										<p>{"check-IN: "}{message.contactBooking.checkin.day + " " + months[message.contactBooking.checkin.month] + " " + message.contactBooking.checkin.year}</p>
										<p>{"check-OUT: "}{message.contactBooking.checkout.day + " " + months[message.contactBooking.checkout.month] + " " + message.contactBooking.checkout.year}</p>
									</div>
									<div className="card-text">
										<p>{message.contactFirstName}{message.contactSurname ? " "+ message.contactSurname : " "}</p>
										<p style={{marginBottom: '50px'}}>{'e-mail: ' + message.contactEmail}<br/>{'tel: ' + message.contactPhone}</p>
										{message.contactMessageContent && <p>{"Wiadomość: "}{message.contactMessageContent}</p>}
										<p>{"Dnia: "}{this.localizeDateStr(message.contactDate)}</p>
										{message.accepted[0] === true && <p style={{marginTop: '50px'}}>{"Zaakceptowano: "}{this.localizeDateStr(message.accepted[1])}<br/>{ "z IP: " + message.accepted[2]}</p>}
										{message.bookingId && <p>{'bookingId: ' +message.bookingId}</p>}
										<br/>
										
									</div>
									<div>
										{!message.bookingId && <p><button name={message.contactMessageId} className="btn btn-success" style={{marginRight:"50px"}} onClick={this.acceptBooking}>Rezerwuj</button><button className="btn btn-danger" name={message.contactMessageId} onClick={this.refuseBooking}>Nie rezerwuj</button></p>}
									</div>
								</div>
							</div>
						</div>
						))}
					</div>
					<div className={"col-5 room"+this.props.room.roomId +'-main-bg'}>
						<Calendar bookings={this.props.bookings} rooms={[this.props.room.roomId]} />
					</div>
				</div>
			</div>
		)
	}
}

export default BookingFormMessagesList;