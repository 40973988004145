import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {dayButtonClass} from './../booking-calendar-utility/colorsSettings';
import {defaultValues} from './../booking-calendar-utility/defaultValues';

class DayCalendar extends Component {
	constructor (props) {
		super(props);
		this.selectDay = this.selectDay.bind(this);
		this.showDetails = this.showDetails.bind(this);
	}
	selectDay(event){
		const today = new Date();
		const name = event.target.name;
		const date = event.target.id;
		if(name==="free")
		{
			const inputDate = {
					day: parseInt(date.slice(0, date.indexOf('/'))),
					month: parseInt(date.slice(date.indexOf('/')+1, date.lastIndexOf('/'))),
					year: parseInt(date.slice(date.lastIndexOf('/')+1)),
			}
				let newBooking;
					//New booking can't happen before today
				if(new Date(inputDate.year, inputDate.month, inputDate.day).getTime() >= today.getTime())
				{//operate on user input
					if(this.props.newBooking.selectedDayCount === 0)
					{//when it's first input set as checkin
						newBooking = {
							...this.props.newBooking,
							newBookingIsCorrect: false,
							object: {
								...defaultValues.newBookingObject,
								checkin: {...inputDate}
							},
							selectedDayCount: 1
						};
					}
					else if (this.props.newBooking.selectedDayCount === 1)
					{//compare values other value should be after first
						if( new Date(inputDate.year, inputDate.month, inputDate.day).getTime() > this.props.newBooking.array[0].checkin.getTime())
						{
							let bookingYears = inputDate.year - this.props.newBooking.object.checkin.year;
							let bookingMonths = inputDate.month - this.props.newBooking.object.checkin.month;
							let count = bookingYears*12 + bookingMonths;
							let bookingArrayToCompare = [];
							for (let i=0; i<=count; i++)
							{
								let year = this.props.newBooking.object.checkin.year;
								let month = this.props.newBooking.object.checkin.month;
								if(month+i > 11)
								{
									month = month + i -11;
									year++;
								}
								else
								{
									month = month + i;
								}
								bookingArrayToCompare = bookingArrayToCompare.concat(this.props.createBookingList(this.props.roomId, month, year, defaultValues.calendarCollections.newBookingMode) );
							}
							//ask if there's no conflicts 
							let conflictValues = bookingArrayToCompare.filter((potencialConflictBooking)=>{
									return new Date(potencialConflictBooking.checkout).getTime() <= new Date(inputDate.year, inputDate.month, inputDate.day).getTime()
								&& new Date(potencialConflictBooking.checkin).getTime() >= new Date(this.props.newBooking.object.checkin.year, this.props.newBooking.object.checkin.month, this.props.newBooking.object.checkin.day).getTime() 
								})
							if(conflictValues.length === 0){
								newBooking = {
									...this.props.newBooking,
									object: {
										...this.props.newBooking.object,
										checkout: {...inputDate}
									},
									newBookingIsCorrect: true,
									selectedDayCount: 0
								}
							}
							else
							{
								newBooking = {
									...defaultValues.resetAfterWrongDayInput,
									object: {...defaultValues.newBookingObject}
								};
							}
						}
						else
						{
							newBooking = {
								...defaultValues.resetAfterWrongDayInput,
								object: {...defaultValues.newBookingObject}
							};
						}
					}
				}
				else
				{
					newBooking = {
						...defaultValues.resetAfterWrongDayInput,
						object: {...defaultValues.newBookingObject}
					};
				}
				let array = [{
					bookingId: 'new',
					roomId: this.props.roomId,
					bookingType: 'unconfirmed',
					checkin: newBooking.object.checkin.day !== false ? new Date(newBooking.object.checkin.year, newBooking.object.checkin.month, newBooking.object.checkin.day) : false,
					checkout: newBooking.object.checkout.day !== false ? new Date(newBooking.object.checkout.year, newBooking.object.checkout.month, newBooking.object.checkout.day ) : false,
				}];
				//prepare final object and return
				newBooking = {...newBooking, array}
				this.props.returnSelectedDay(newBooking);
		}
	}
	showDetails(event) {
		const id = event.target.id;
		if (id.indexOf('/') < 0)
			this.props.changeShowBookingDetailsValue(id);
		else
			this.props.changeShowBookingDetailsValue(false);
	}

	render() {//generate empty cell for days before 1st of the month or cell with button
		const freeDayButtonClass = dayButtonClass.free;
		const selectedDayButtonClass = dayButtonClass.select;
		const bookedDayButtonClass = this.props.dayCellColor;
  	const dayButton = 	
  	<button
			data-toggle='tooltip' 
			title={this.props.bookingId !== 0 ? 'id: ' + this.props.bookingId + '\n' + this.props.bookingTypeHeaders[this.props.bookingType] : ''}
			type='button' 
			id={this.props.bookingId !== 0 ? this.props.bookingId : this.props.day+'/'+this.props.currentMonth+'/'+this.props.currentYear} 
			value={(this.props.day === 'X' || this.props.day === 'O') ? '' : this.props.day} 
			name={this.props.bookingId !== 0 ? 'details' : 'free'}
			onClick={
				this.props.newBooking.newBookingMode === false
				?  this.showDetails 
				: this.props.newBooking.newBookingIsCorrect && this.props.day==='O'
					? this.props.addBookingToUnconfirmedCollection
				 	: this.selectDay
			}
			style= {{width: '100%', position: 'absolute', top:'0', right: '0', left: '0', bottom: '0'}}
			className={this.props.day === 'X' ? bookedDayButtonClass : (this.props.day ==='O' ? selectedDayButtonClass : freeDayButtonClass) }>
			{(this.props.day === 'X' || this.props.day === 'O') ? '' : String(this.props.day)} 
		</button>;
		const dayCell = <td 
			className='content'
			style={{margin:0, padding: 0}}>
  			{dayButton}
  		</td>;
  	const emptyCell = <td className='content'>{''}</td>;
  	
  	if (this.props.day === 0) 
		{
			return emptyCell;
		}
		else
		{
			return dayCell;
		}
	}
}

DayCalendar.propTypes ={
	addBookingToUnconfirmedCollection: PropTypes.func.isRequired,
	bookingId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),
	bookingType: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]),
	day: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number]).isRequired,
  bookingTypeHeaders: PropTypes.objectOf(PropTypes.string).isRequired,
  currentMonth: PropTypes.number.isRequired,
  currentYear: PropTypes.number.isRequired,
  changeShowBookingDetailsValue: PropTypes.func.isRequired,
  createBookingList: PropTypes.func.isRequired,
  dayCellColor: PropTypes.string.isRequired,
  newBooking: PropTypes.object.isRequired,
	returnSelectedDay: PropTypes.func.isRequired,
  roomId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool ]).isRequired,
}

export default DayCalendar;

					