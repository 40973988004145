import React, { Component } from 'react';


class Menu extends Component {
render (){
		return (
			<div>
				<nav className="navbar navbar-light bg-light">
					<a className="navbar-brand" href="#messages" name="messages" onClick={this.props.handleModuleMenuSelect}>Wiadomości</a>
				</nav>
				<nav className="navbar navbar-light bg-light">
					<a className="navbar-brand" href="#bookingsadminpage" name="bookingsAdminPage" onClick={this.props.handleModuleMenuSelect}>Rezerwacje</a>
				</nav>
				<nav className="navbar navbar-light bg-light">
					<a className="navbar-brand" href="#pricetable" name="priceTable" onClick={this.props.handleModuleMenuSelect}>Cennik</a>
				</nav>
				<nav className="navbar navbar-light bg-light">
					<a className="navbar-brand" href="#roomoptions" name="roomOptions" onClick={this.props.handleModuleMenuSelect}>Pokoje</a>
				</nav>

			</div>
		);
	}
}

export default Menu;						