import React, { Component } from 'react';
import BookingFormMessagesListsWrapper from './BookingFormMessagesListsWrapper';
import 'bootstrap/dist/css/bootstrap.css';


class BookingFormMessagesPage extends Component {
	constructor() {
    super();
    this.state = {
    }
  };

	componentDidMount(){
    this.props.downloadMessagesFromDB();
  };



	render() {
		function filterOnlyFromBookingForm(message)
		{
			return message.contactTopic === 1;
		}
		const messages = this.props.messages ? this.props.messages.filter(filterOnlyFromBookingForm) : [];
		return (
			<BookingFormMessagesListsWrapper 
			counters={this.props.counters} 
			setMessageAsRead={this.props.setMessageAsRead}
			bookings={this.props.bookings} 
			messages={messages} 
			rooms={this.props.rooms}
			user={this.props.user}
			 />
		)
	}
}

export default BookingFormMessagesPage;