import React, { Component } from 'react';


class Datepicker extends Component {
	constructor (props) {
		super(props);
		let today = new Date ();
		this.state = {
			//today: new Date(),
			calendar: [],
			currentMonth: today.getMonth(),
			currentYear: today.getFullYear(),
			selectYear: document.getElementById("Year"),
			selectMonth: document.getElementById("Month"),
			monthAndYear: document.getElementById("monthAndYear"),
			months: ["Styczeń", "Luty", "Marzec", "Kwiecień", "Maj", "Czerwiec", "Lipiec", "Sierpień", "Wrzesień", "Październik", "Listopad", "Grudzień"],	
			get selectableYears() {return [this.currentYear, this.currentYear+1]}
		}
		this.jump = this.jump.bind(this);
		this.sendArray = this.sendArray.bind(this);
		this.selectDay = this.selectDay.bind(this);
	}	

	jump(event) {		
		let value = event.target.value;
		let name = event.target.name;
		console.log(value + "    " + name);
		this.setState({
	    currentYear : parseInt(document.getElementById(this.props.type+"Year").value),
	    currentMonth : parseInt(document.getElementById(this.props.type+"Month").value)
	  }, () => {this.generateCalendar(this.state.currentMonth, this.state.currentYear)} );
		this.props.returnValueFromDatepicker({[name]:value});
	}

	selectDay(event) {
		let value = event.target.value;
		let name = event.target.name;

		this.setState({
			[name]:value
		});
	}

	sendArray(){
		let dateArray = [
		parseInt(this.state[this.props.type+"Day"]),
		parseInt(document.getElementById(this.props.type+"Month").value),
		parseInt(document.getElementById(this.props.type+"Year").value)
		];
		dateArray[1]++;
		console.log(dateArray);
		this.props.returnValueFromDatepicker(dateArray, this.props.type);
	}
	generateCalendar(month, year) {
		let firstDay = (new Date(year, month)).getDay();
		let days = [];
		console.log("Przeglądany miesiąc : " + this.state.currentMonth);
		console.log("przeglądany id: " + this.props.roomId);
		let bookedForThisMonth;
		if (this.props.bookings)
		{
			bookedForThisMonth = this.props.bookings.filter((book)=>{return book.roomId === this.props.roomId && ((book.startMonth === this.state.currentMonth+1 && book.startYear === this.state.currentYear) || (book.endMonth === this.state.currentMonth+1 && book.endYear === this.state.currentYear))});
    	console.log(bookedForThisMonth);
		}

    
	  if (bookedForThisMonth !== undefined)
	  {
	  	days = [];
	  	for (let i=0; i<bookedForThisMonth.length; i++)
	  	{ 
	  		let checkInDate = new Date (bookedForThisMonth[i].startYear, bookedForThisMonth[i].startMonth-1, bookedForThisMonth[i].startDay);
	  		let checkOutDate = new Date (bookedForThisMonth[i].endYear, bookedForThisMonth[i].endMonth-1, bookedForThisMonth[i].endDay);
	  		console.log("CheckIn : " + checkInDate);
	  		console.log("CheckOut : " + checkOutDate);
	  		let guestStay = (checkOutDate - checkInDate) / (1000*60*60*24);
	  		console.log("Liczba dni pobytu: " + guestStay);
	  		
	  		for (let j=0; j<=guestStay; j++)
	  		{
	  			let stayDay = new Date (checkInDate);
	  			stayDay.setDate(checkInDate.getDate() + j);
	  			let day;
	  			console.log("stayDay " + stayDay.getMonth() + ", currentMonth " + this.state.currentMonth);
					if (stayDay.getMonth() === this.state.currentMonth)
					{
						 day = stayDay.getDate();
						 //console.log("wewn: " + day);
						 days = days.concat(day);
					}					
					//console.log("zewn: " + day)
	  			
	  		}
		  	//console.log("booked for this month: " + days);
		  }
		}

		firstDay = (firstDay===0)?firstDay=7:firstDay=firstDay;
    let daysInMonth = 32 - new Date(year, month, 32).getDate();
		let newCalendar = [];
		let date = 1;
    for (let i = 0; i < 6; i++) 
    {
        //create array with row of calendar
        let row = [];

        //fill row with days
        for (let j = 1; j <= 7; j++) 
        {
          if (i === 0 && j < firstDay) 
          {
            let cell = 0;
            row.push(cell);
          }
          else if (date > daysInMonth) 
          {
            break;
          }
          else 
          {
	          let cell;
	          //console.log(date);  
			      if (days.includes(date))
		      		{
		      			//console.log('znalazl');
		      			cell = "X";
		      			row.push(cell);
		      		}
		      		else
		      		{
		      			cell = date;
		      			row.push(cell);
		          }
			      date++;
          }
        }
        newCalendar.push(row);
      }
		this.setState({
			calendar: newCalendar
		})
	}

	/*generateCalendar(month, year) {
		let firstDay = (new Date(year, month)).getDay();
		firstDay = (firstDay===0) ? firstDay=7: firstDay=firstDay;
		//how many days are in given month
    let daysInMonth = 32 - new Date(year, month, 32).getDate();
		let newCalendar = [];
		let date = 1;
    for (let i = 0; i < 6; i++) 
    {
      //create week row Array for newCalendar Array
      let row = [];
      //fill row Array with dates
      for (let j = 1; j <= 7; j++) 
      {
      	//when it's not first day of a month = 0 mean empty cell
        if (i === 0 && j < firstDay) 
        {          	
          let cell = 0;
          row.push(cell);
        }
        //stop when there is no more dates in month
        else if (date > daysInMonth) 
        {
          break;
        }
        //push day to row Array
        else 
        {
          let cell;
    			cell = date;
    			row.push(cell);
		      date++;
        }
      }
      //push row Array into newCalendar Array
      newCalendar.push(row);
    }
		this.setState({
			calendar: newCalendar
		})
	}*/

	componentDidMount(){
    this.generateCalendar(this.state.currentMonth, this.state.currentYear);
  }
	
	render() {
		return (
			<div>
			  <div 
			  className="card">
			  	<div className="card-header">
				  	<div 
				  	className="form-inline">
	            <label 
	            className="lead mr-2 ml-2" 
	            htmlFor="Month">
	            {this.props.description}
	            </label>
	            <div id="buttonsGroup" style={{position: "absolute", right: "0"}}>
	            	<button className="btn btn-outline-success"  onClick={this.sendArray} style={{position: "absolute", right: 60}}>OK</button>
	            	<button id={this.props.type} className="btn btn-outline-danger" onClick={this.props.closeDatepicker}>X</button>
							</div>
	            <select 
	            className="form-control col-4" 
	            value={this.state.currentMonth} 
	            name={this.props.type+"Month"} 
	            id={this.props.type+"Month"} 
	            onChange={this.jump}>
            		{this.state.months.map((month, index)=>
            			<option 
            			value={index} 
            			key={index}>
            				{month}
            			</option>
            		)}
	            </select>
		          <select 
		          className="form-control col-4" 
		          name={this.props.type+"Year"} 
		          id={this.props.type+"Year"} 
		          onChange={this.jump}>
		            <option
		            value={this.state.selectableYears[0]}>
		            	{this.state.selectableYears[0]}
		            </option>
		            <option 
		            value={this.state.selectableYears[1]}>
		            	{this.state.selectableYears[1]}
		            </option>
			        </select>
			      </div>				       
			    </div>		     			        
	        <div 
	        className="card-body">
	        	<table 
	        	className="table table-bordered table-responsive-sm" 
	        	id="calendar">
	            <thead>
	            <tr>			                
                <th>Pn.</th>
                <th>Wt.</th>
                <th>Śr.</th>
                <th>Cz.</th>
                <th>Pt.</th>
                <th>Sb.</th>
                <th>Nd.</th>
	            </tr>
	            </thead>
	            <tbody 
	            id="calendar-body">
		            {this.state.calendar.map((week, index)=>(
	          			<tr key={index}>
	          			{week.map((day, idx)=>
	          			(
	          				(day===0)
	          				?<td key={idx}>{""}</td>
	          				:<td key={idx} style={{margin:0, padding: 0, width: "50px", height: "50px"}}>
			          				 <input type="button" 
			          				 id={this.props.type+day} 
			          				 value={day} 
			          				 name={this.props.type+"Day"}
			          				 style={day==="X" ? {display: "none"} : {height:"50px", width: "50px", display: "inline-block"}}
			          				 className={(!this.state[this.props.type+"Day"] || this.state[this.props.type+"Day"] == day) ? "btn btn-outline-dark" : "btn btn-outline-light"}
			          				 onClick={this.selectDay}/>
		          			 </td>
	          			))}
	          			</tr>
		            ))}		            
	            </tbody>
	        	</table>	        	
	      	</div>
	    	</div>
			</div>
		);
	}
}

export default Datepicker;