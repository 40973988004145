import React, { Component } from 'react';
import './../../../stylesheets/loadcomponent.css';

class LoadingComponent extends Component {
	render() {
		return (
		<div className="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
		)}
}

export default LoadingComponent;